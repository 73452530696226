// @ts-nocheck
import { onError } from '@apollo/client/link/error'
import { store } from '../index'
import { sessionExpired } from 'store/actions/other'

export const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    store.dispatch(sessionExpired(true))
  }
})
