// @ts-nocheck
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'

import { analyticsMiddleware } from './analyticsMiddleWare'
import { authLink } from './authLink'
import { errorLink } from './errorLink'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL
})

const PublicClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink, analyticsMiddleware]),
  cache: new InMemoryCache()
})

export default PublicClient
