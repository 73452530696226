import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Layout from 'components/layouts/Layout'
import 'normalize.css'
import 'App.scss'
import 'typeface-roboto'
import Main from 'components/Main'
import SharePasswordPage from 'components/SharePasswordPage'
import PrivateRoute from './components/auth/PrivateRoute'
import Login from './components/Login'
import PasswordReset from './components/PasswordReset'

const App: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])},heap.load("${process.env.REACT_APP_HEAP_ID}");`
    document.head.appendChild(script)
  }, [])

  let routes
  if (process.env.REACT_APP_IS_LOCAL === 'true') {
    routes = (
      <Switch>
        <Route
          exact
          path="/share/:code/:id"
          render={() => <SharePasswordPage />}
        />
        <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/reset-password" render={() => <PasswordReset />} />
        <PrivateRoute exact path="/" component={Main} />
        <PrivateRoute exact path="/:id" component={Main} />
      </Switch>
    )
  } else {
    if (window.location.hostname === process.env.REACT_APP_STUDIO_DOMAIN) {
      routes = (
        <Switch>
          <Route exact path="/login" render={() => <Login />} />
          <Route
            exact
            path="/reset-password"
            render={() => <PasswordReset />}
          />
          <PrivateRoute exact path="/" component={Main} />
          <PrivateRoute exact path="/:id" component={Main} />
        </Switch>
      )
    } else {
      routes = (
        <Switch>
          <Route exact path="/:code/:id" render={() => <SharePasswordPage />} />
        </Switch>
      )
    }
  }

  return (
    <Router>
      <Layout>{routes}</Layout>
    </Router>
  )
}

export default App
