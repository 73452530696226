// @ts-nocheck
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from '@apollo/client'
import { authLink } from './authLink'
import { analyticsMiddleware } from './analyticsMiddleWare'
import { errorLink } from './errorLink'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL_SERVICE
})

const Client = new ApolloClient({
  link: from([authLink, errorLink, httpLink, analyticsMiddleware]),
  cache: new InMemoryCache()
})

export default Client
