import produce from 'immer'
import {
  CHANGE_OTHER,
  CHANGE_CANVAS_POSITION,
  PASSWORD_RESET_DONE,
  SESSION_EXPIRED
} from 'store/actions/other'
import { OtherActionTypes, OtherState } from 'types/types'

const initialState: OtherState = {
  saveConcept: false,
  settings: {
    detailsExpand: false,
    detailsClientHelperText: '',
    detailsServiceHelperText: ''
  },
  canvas: {
    scale: 1,
    positionX: 0,
    positionY: 80
  },
  password: '',
  passwordResetDone: false,
  servicesLoading: false,
  moveScreens: false,
  sessionExpired: false
}

const other = (state = initialState, action: OtherActionTypes): OtherState => {
  switch (action.type) {
    case CHANGE_OTHER: {
      const { payload } = action
      return produce(state, (draft) => {
        if (payload.stateAdditionalKey) {
          // @ts-ignore
          draft[payload.stateKey][payload.stateAdditionalKey] = payload.value
        } else {
          // @ts-ignore
          draft[payload.stateKey] = payload.value
        }
      })
    }

    case CHANGE_CANVAS_POSITION: {
      const { payload } = action
      return produce(state, (draft) => {
        const updateCanvas = JSON.parse(JSON.stringify(state.canvas))
        updateCanvas.positionX = payload.positionX
        updateCanvas.positionY = payload.positionY
        draft.canvas = updateCanvas
      })
    }

    case PASSWORD_RESET_DONE: {
      const { payload } = action
      return produce(state, (draft) => {
        draft.passwordResetDone = payload.passwordResetDone
      })
    }

    case SESSION_EXPIRED: {
      const { payload } = action
      return produce(state, (draft) => {
        draft.sessionExpired = payload.sessionExpired
      })
    }

    default:
      return state
  }
}

export default other
