// @ts-check
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

// import { analyticsMiddleware } from './analyticsMiddleWare'
// import { shareLink } from './shareLink'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL
})

const ShareClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default ShareClient
