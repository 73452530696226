// @ts-nocheck
import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

const BoxStyled = styled(Box)`
  -webkit-tap-highlight-color: transparent;
`

function BoxWithPadding({ children, className, style }) {
  return (
    <BoxStyled
      style={style}
      className={className}
      paddingLeft={{ xs: 0, md: 1 }}
      paddingRight={{ xs: 0, md: 1 }}
    >
      {children}
    </BoxStyled>
  )
}

BoxWithPadding.defaultProps = {
  className: null,
  children: null,
  style: null
}

export default BoxWithPadding
