import {
  OtherActionTypes,
  OtherStateKey,
  OtherAdditionalStateKey,
  AppActions
} from 'types/types'
import { Dispatch } from 'redux'

export const CHANGE_OTHER = 'CHANGE_OTHER'
export const CHANGE_CANVAS_POSITION = 'CHANGE_CANVAS_POSITION'
export const PASSWORD_RESET_DONE = 'PASSWORD_RESET_DONE'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'

export const changeOther = (
  value: string | boolean | number | undefined,
  stateKey: OtherStateKey,
  stateAdditionalKey?: OtherAdditionalStateKey
) => (dispatch: Dispatch<AppActions>): OtherActionTypes => {
  return dispatch({
    type: CHANGE_OTHER,
    payload: { value, stateKey, stateAdditionalKey }
  })
}

export const changeCanvasPosition = (
  positionX: number | undefined,
  positionY: number | undefined
) => (dispatch: Dispatch<AppActions>): OtherActionTypes => {
  return dispatch({
    type: CHANGE_CANVAS_POSITION,
    payload: { positionX, positionY }
  })
}

export const passwordResetDone = (passwordResetDone: boolean) => (
  dispatch: Dispatch<AppActions>
): OtherActionTypes => {
  return dispatch({
    type: PASSWORD_RESET_DONE,
    payload: { passwordResetDone: passwordResetDone }
  })
}

export const sessionExpired = (sessionExpired: boolean) => (
  dispatch: Dispatch<AppActions>
): OtherActionTypes => {
  return dispatch({
    type: SESSION_EXPIRED,
    payload: { sessionExpired: sessionExpired }
  })
}
