import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import StepList from 'components/common/StepList'
import Input from 'components/common/Input'
import { AppState, Content, ActionItem, ActionItemKey } from 'types/types'
import {
  editContentTableValue,
  removeContentTableValue
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  sectionSeparator: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

interface Props {
  content: Content
  itemId: string
  setView: Dispatch<SetStateAction<string>>
}

const TypeGroupsSettings: React.FC<Props> = ({ content, itemId, setView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [item, setItem] = useState<ActionItem | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)

  const [icon, setIcon] = useState<string | undefined>('')
  const [label, setLabel] = useState<string | undefined>('')
  const [metaText, setMetaText] = useState<string | undefined>('')
  const [firstLine, setFirstLine] = useState<string | undefined>('')
  const [secondLine, setSecondLine] = useState<string | undefined>('')
  const [thirdLine, setThirdLine] = useState<string | undefined>('')

  useEffect(() => {
    const findItem = content.actionItems?.find((c) => c.id === itemId)
    if (findItem) {
      setItem(content.actionItems?.find((c) => c.id === itemId))
      setIcon(findItem.icon)
      setLabel(findItem.label)
      setMetaText(findItem.metaText)
      setFirstLine(findItem.firstLine)
      setSecondLine(findItem.secondLine)
      setThirdLine(findItem.thirdLine)
    }
  }, [itemId, content])

  if (!item) {
    return null
  }

  const handleChangeInput = (
    value: string,
    type: ActionItemKey,
    id: string
  ) => {
    if (type === 'icon') setIcon(value)
    if (type === 'label') setLabel(value)
    if (type === 'metaText') setMetaText(value)
    if (type === 'firstLine') setFirstLine(value)
    if (type === 'secondLine') setSecondLine(value)
    if (type === 'thirdLine') setThirdLine(value)
    dispatch(editContentTableValue(value, 'actionItems', type, modifyId, id))
  }

  const deleteComponent = (id: string) => {
    dispatch(removeContentTableValue('actionItems', modifyId, id))
    setView('')
  }

  return (
    <>
      {!content.typeButton && (
        <div className={classes.selectContainer}>
          <Input
            label="Content"
            options={[
              { value: 'One line', label: 'One line' },
              { value: 'Two lines', label: 'Two lines' },
              { value: 'Three lines', label: 'Three lines' }
            ]}
            value={item.selection}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'selection', item.id)
            }
          />
        </div>
      )}
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          {content.typeButton ? (
            <>
              <div className={classes.inputPadding}>
                <Input
                  label="Icon"
                  prefixIcon="Image"
                  value={icon}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'icon', item.id)
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Primary text"
                  value={label}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'label', item.id)
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Secondary text"
                  value={metaText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'metaText', item.id)
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.inputPadding}>
                <Input
                  label="First line"
                  value={firstLine}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'firstLine', item.id)
                  }
                />
              </div>
              {item.selection === 'Two lines' ||
              item.selection === 'Three lines' ? (
                <div className={classes.inputPadding}>
                  <Input
                    label="Second line"
                    value={secondLine}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeInput(e.target.value, 'secondLine', item.id)
                    }
                  />
                </div>
              ) : null}
              {item.selection === 'Three lines' && (
                <div className={classes.inputPadding}>
                  <Input
                    label="Third line"
                    value={thirdLine}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeInput(e.target.value, 'thirdLine', item.id)
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={() => deleteComponent(item.id)}
          />
        </div>
      </Collapse>
    </>
  )
}

export default TypeGroupsSettings
