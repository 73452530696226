import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import BoxWithPadding from 'components/common/BoxWithPadding'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Icons from 'components/common/Icons'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  listItemStyles: {
    height: '56px'
  },
  listItemHigherStyles: {
    height: '72px'
  },
  listIconStyles: {},
  listIconBigStyles: {
    '& svg': {
      fontSize: '2.5rem'
    }
  },
  titleStyles: {
    color: theme.palette.text.primary
  },
  secondaryItemStyles: {
    right: '4px'
  },
  actionTextStyles: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  actionIconStyles: {
    marginRight: 0
  },
  iconButton: {
    padding: '4px'
  }
}))

const StepList: React.FC<{
  icon?: string
  iconColor?: string
  bigIcon?: string
  title?: string
  titleColor?: string
  subtitle?: string
  subtitleList?: string[]
  actionText?: string
  actionIcon?: string
  backgroundColor?: string
  highElement?: boolean
  showOrderIcons?: boolean
  className?: any
  onClick?: React.MouseEventHandler
  onClickAction?: React.MouseEventHandler
  onClickComponent?: React.MouseEventHandler
  onClickOrderUp?: React.MouseEventHandler
  onClickOrderDown?: React.MouseEventHandler
}> = ({
  icon,
  iconColor,
  bigIcon,
  title,
  titleColor,
  subtitle,
  subtitleList,
  actionText,
  actionIcon,
  backgroundColor,
  highElement,
  showOrderIcons = false,
  className,
  onClick,
  onClickAction,
  onClickComponent,
  onClickOrderUp,
  onClickOrderDown
}) => {
  const classes = useStyles()

  let iconType: string | undefined = icon || ''
  if (!Object.prototype.hasOwnProperty.call(Icons, iconType)) {
    iconType = undefined
  }

  let actionIconType: string | undefined = actionIcon || ''
  if (!Object.prototype.hasOwnProperty.call(Icons, actionIconType)) {
    actionIconType = undefined
  }

  const higher = highElement || false

  const hasSubtitleList = subtitleList || []

  return (
    <BoxWithPadding className={className}>
      <List
        onClick={onClickComponent}
        disablePadding
        style={{
          backgroundColor,
          cursor: onClick || onClickComponent ? 'pointer' : 'default'
        }}
      >
        <ListItem
          onClick={onClick}
          classes={{
            root: clsx(
              !higher ? classes.listItemStyles : classes.listItemHigherStyles,
              {
                [classes.listIconBigStyles]: bigIcon
              }
            )
          }}
        >
          {iconType ? (
            <ListItemIcon
              style={{ color: iconColor }}
              classes={{ root: classes.listIconStyles }}
            >
              {React.createElement(Icons[iconType])}
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={
              <>
                {title ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.titleStyles}
                    style={{ color: titleColor }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                ) : null}
              </>
            }
            secondary={
              <>
                {subtitle ? (
                  <Typography
                    variant="body2"
                    component="span"
                    style={{ display: 'block' }}
                  >
                    {subtitle}
                  </Typography>
                ) : (
                  <>
                    {hasSubtitleList.map((text, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        component="span"
                        style={{ display: 'block' }}
                      >
                        {text}
                      </Typography>
                    ))}
                  </>
                )}
              </>
            }
          />
          <ListItemSecondaryAction
            classes={{ root: classes.secondaryItemStyles }}
          >
            {showOrderIcons && (
              <>
                <IconButton
                  onClick={onClickOrderUp}
                  classes={{ root: classes.iconButton }}
                >
                  <ExpandLess />
                </IconButton>
                <IconButton
                  onClick={onClickOrderDown}
                  classes={{ root: classes.iconButton }}
                >
                  <ExpandMore />
                </IconButton>
              </>
            )}
            {actionIconType ? (
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={onClickAction}
                style={{ color: '#525252' }}
                className={classes.actionIconStyles}
                edge="end"
              >
                {React.createElement(Icons[actionIconType])}
              </IconButton>
            ) : (
              <Typography
                onClick={onClickAction}
                variant="caption"
                className={classes.actionTextStyles}
              >
                {actionText}
              </Typography>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </BoxWithPadding>
  )
}

export default StepList
