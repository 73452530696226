import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BoxWithPadding from 'components/common/BoxWithPadding'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import Radio from '@material-ui/core/Radio'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Icons from 'components/common/Icons'

const useStyles = makeStyles((theme) => ({
  boxStyles: {
    paddingLeft: 0,
    paddingRight: 0
  },
  formControlStyles: {
    display: 'block'
  },
  inputField: {
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      fontStyle: theme.typography.caption.fontStyle,
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      letterSpacing: theme.typography.caption.letterSpacing,
      color: theme.palette.text.primary
    }
  },
  helperTextStyles: {
    fontStyle: theme.typography.caption.fontStyle,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: theme.typography.caption.letterSpacing,
    color: theme.palette.text.primary,
    marginLeft: '14px'
  },
  placeholderStyles: {
    // '&::placeholder': {
    //   opacity: 0.7
    // }
  }
}))

interface Options {
  value: string | number
  label: string
}

const Input: React.FC<{
  value?: string
  valueMultiple?: string[]
  onChange?: any
  name?: string
  autoComplete?: string
  label?: string
  error?: boolean
  type?: string
  max?: number
  min?: number
  placeholder?: string
  helperText?: string
  characterLimit?: number
  prefixIcon?: string
  prefixRadio?: boolean
  prefix?: string
  suffixIcon?: string
  suffix?: string
  suffixOnClick?: any
  rows?: number
  multiple?: boolean
  required?: boolean
  disable?: boolean
  autoFocus?: boolean
  options?: Options[]
}> = ({
  value = '',
  valueMultiple = [],
  onChange,
  name,
  autoComplete,
  label,
  error,
  type,
  max,
  min,
  placeholder,
  helperText,
  characterLimit,
  prefixIcon,
  prefixRadio,
  prefix,
  suffixIcon,
  suffix,
  suffixOnClick,
  rows,
  multiple,
  required,
  disable,
  autoFocus,
  options
}) => {
  const classes = useStyles()

  const isMultiple = multiple || false

  const isType = type || 'text'

  const rowsAmount = rows || 0

  const givenOptions = options || []

  const isRequired = required || false

  const isDisabled = disable || false

  const isAutoFocus = autoFocus || false

  const hasError = error || false

  return (
    <BoxWithPadding className={classes.boxStyles}>
      {givenOptions.length > 0 ? (
        <FormControl
          variant="outlined"
          classes={{ root: classes.formControlStyles }}
        >
          <InputLabel id="select-label">{label}</InputLabel>
          <Select
            labelId="select-label"
            className={classes.inputField}
            error={false}
            fullWidth
            multiple={isMultiple}
            label={label}
            value={isMultiple ? valueMultiple : value}
            onChange={onChange}
          >
            {givenOptions.map((option) => {
              return option.value && option.label ? (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ) : null
            })}
          </Select>
          <FormHelperText classes={{ root: classes.helperTextStyles }}>
            {helperText || ''}
          </FormHelperText>
        </FormControl>
      ) : prefix || suffix || suffixIcon || prefixRadio || prefixIcon ? (
        <TextField
          name={name}
          autoComplete={autoComplete}
          className={classes.inputField}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}
          variant="outlined"
          type={isType}
          required={isRequired}
          disabled={isDisabled}
          autoFocus={isAutoFocus}
          fullWidth
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={hasError}
          helperText={
            characterLimit ? (
              <>
                <span>{helperText || ''}</span>{' '}
                <span>
                  {value.length}/{characterLimit}
                </span>
              </>
            ) : (
              `${helperText || ''}`
            )
          }
          inputProps={{ maxLength: characterLimit }}
          InputProps={{
            startAdornment: (
              <>
                {prefix || prefixRadio || prefixIcon ? (
                  <InputAdornment position="start">
                    {prefixRadio ? (
                      <Radio color="default" />
                    ) : prefixIcon ? (
                      React.createElement(Icons[prefixIcon], {
                        style: { color: 'rgba(0, 0, 0, 0.6)' }
                      })
                    ) : (
                      prefix
                    )}
                  </InputAdornment>
                ) : null}
              </>
            ),
            endAdornment: (
              <>
                {suffix || suffixIcon ? (
                  <InputAdornment position="start">
                    {suffixIcon ? (
                      <div
                        style={{
                          cursor: suffixOnClick ? 'pointer' : 'default'
                        }}
                        onClick={suffixOnClick}
                      >
                        {React.createElement(Icons[suffixIcon], {
                          style: { color: 'rgba(0, 0, 0, 0.6)' }
                        })}
                      </div>
                    ) : (
                      suffix
                    )}
                  </InputAdornment>
                ) : null}
              </>
            )
          }}
        />
      ) : (
        <TextField
          name={name}
          autoComplete={autoComplete}
          className={classes.inputField}
          style={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}
          variant="outlined"
          type={isType}
          required={isRequired}
          disabled={isDisabled}
          autoFocus={isAutoFocus}
          fullWidth
          value={value}
          onChange={onChange}
          multiline={rowsAmount > 0}
          rows={rowsAmount}
          label={label}
          placeholder={placeholder}
          error={hasError}
          helperText={
            characterLimit ? (
              <>
                <span>{helperText || ''}</span>{' '}
                <span>
                  {value.length}/{characterLimit}
                </span>
              </>
            ) : (
              `${helperText || ''}`
            )
          }
          inputProps={{ maxLength: characterLimit }}
          InputProps={{
            inputProps: {
              max,
              min
            },
            classes: { input: classes.placeholderStyles }
          }}
        />
      )}
    </BoxWithPadding>
  )
}

export default Input
