import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import StepList from 'components/common/StepList'
import ComponentContent from 'components/blocks/ComponentContent'
import { componentToModify } from 'store/actions/componentModify'
import { AppState, Component } from 'types/types'
import { swapComponents } from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  screenHeader: {
    color: theme.palette.text.primary,
    padding: '16px'
  },
  screens: {
    padding: '0px 16px 8px 16px'
  },
  screenSeparator: {
    marginBottom: '8px'
  },
  container: {
    padding: '8px 16px'
  },
  sectionSeparator: {
    marginBottom: '8px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  childScreen: {
    width: '85%',
    marginLeft: 'auto',
    marginTop: '1px'
  },
  pathScreen: {
    width: '70%',
    marginLeft: 'auto',
    marginTop: '1px'
  }
}))

const ScreensActions: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const { modifyingComponent } = useSelector(
    (state: AppState) => state.componentModify
  )

  const handleClick = (id: string) => {
    const component = components.find((c) => c.id === id)
    if (component) {
      dispatch(componentToModify(true, false, component.id, 'All screens'))
    }
  }

  const handleOrderSwap = (id: string, direction: 'up' | 'down') => {
    const component = components.find((c) => c.id === id)
    const componentIndex = components.findIndex((c) => c.id === id)
    if (componentIndex > -1 && component) {
      let swapComponent: Component | undefined = undefined
      if (direction === 'up') {
        swapComponent = components.find(
          (c) => c.order === String(Number(component.order) - 1)
        )
      }
      if (direction === 'down') {
        swapComponent = components.find(
          (c) => c.order === String(Number(component.order) + 1)
        )
      }
      const swapComponentIndex = components.findIndex(
        (c) => c.id === swapComponent?.id
      )
      if (swapComponentIndex > -1 && swapComponent) {
        dispatch(
          swapComponents(
            component,
            componentIndex,
            swapComponent,
            swapComponentIndex
          )
        )
      }
    }
  }

  return (
    <>
      {!modifyingComponent ? (
        <>
          <Typography className={classes.screenHeader} variant="subtitle1">
            Select screen
          </Typography>
          <div className={classes.screens}>
            {components
              .filter((c) => c.parentId === '')
              .filter((c) => !c.pathParentId)
              .map((c) => (
                <div key={c.id} className={classes.screenSeparator}>
                  <Paper elevation={2}>
                    <StepList
                      highElement
                      icon={c.type === 'Status' ? 'Airplay' : 'PlayArrow'}
                      title={c.name}
                      onClick={() => handleClick(c.id)}
                      showOrderIcons={c.type !== 'View'}
                      onClickOrderUp={() => handleOrderSwap(c.id, 'up')}
                      onClickOrderDown={() => handleOrderSwap(c.id, 'down')}
                    />
                  </Paper>
                  {c.children?.map((id) => {
                    const children = components.filter((c) => c.id === id)
                    return children.map((component) => (
                      <div key={component.id}>
                        <Paper className={classes.childScreen} elevation={2}>
                          <StepList
                            icon={
                              component.type === 'Play' ? 'PlayArrow' : 'List'
                            }
                            title={component.name}
                            onClick={() => handleClick(component.id)}
                          />
                        </Paper>
                        {component.paths?.map((id) => {
                          const paths = components.filter((c) => c.id === id)
                          return paths.map((c) => (
                            <div key={c.id}>
                              <Paper
                                className={classes.pathScreen}
                                elevation={2}
                              >
                                <StepList
                                  icon="PlayArrow"
                                  title={c.name}
                                  onClick={() => handleClick(c.id)}
                                />
                              </Paper>
                            </div>
                          ))
                        })}
                      </div>
                    ))
                  })}
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <ComponentContent />
        </>
      )}
    </>
  )
}

export default ScreensActions
