/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { Button } from 'uniqore-components'
import Input from 'components/common/Input'
import Selector from 'components/common/Selector'
import { handleShare } from 'store/actions/sidebar'
import SpinnerLogoDark from 'assets/spinner-logo-dark.svg'
import { AppState } from 'types/types'

const getShareLink = gql`
  mutation shareServiceEngagement(
    $engagement: ID!
    $expires: DateTime
    $password: String
  ) {
    shareServiceEngagement(
      engagement: $engagement
      expires: $expires
      password: $password
    ) {
      code
      error
    }
  }
`

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '16px 16px 0px 24px',
    color: theme.palette.text.primary
  },
  text: {
    color: theme.palette.text.primary,
    paddingBottom: '16px'
  },
  url: {
    fontWeight: 'bold',
    padding: '8px 4px',
    color: theme.palette.text.primary
  },
  dialogActions: {
    padding: '12px 24px'
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))

const Share = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { share } = useSelector((state: AppState) => state.sidebar)
  const { id } = useSelector((state: AppState) => state.componentSettings)
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [inputError, setInputError] = useState<boolean>(false)
  const [passwordProtected, setPasswordProtected] = useState<boolean>(true)
  const [buttonLabel, setButtonLabel] = useState<string>('GET SHARE LINK')
  const [password, setPassword] = useState<string>('')

  const [shareCall, { loading: mutationLoading }] = useMutation(getShareLink, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.shareServiceEngagement.error) {
        setError(true)
      } else if (mutationResult.shareServiceEngagement.code) {
        setError(false)
        setButtonLabel('COPY LINK')
        setCode(mutationResult.shareServiceEngagement.code)
      }
    }
  })

  const handleSelector = (value: boolean) => {
    setPasswordProtected(!value)
    setPassword('')
  }

  const handleClick = () => {
    if (buttonLabel === 'LINK COPIED!') {
      return
    }
    if (buttonLabel === 'COPY LINK') {
      setButtonLabel('LINK COPIED!')
      if (process.env.REACT_APP_IS_LOCAL === 'true') {
        navigator.clipboard.writeText(`localhost:3000/share/${code}/${id}`)
      } else {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_GO_DOMAIN}/${code}/${id}`
        )
      }
    } else {
      if (passwordProtected && password === '') {
        setInputError(true)
        return
      } else {
        setInputError(false)
      }
      const expiryDate = new Date()
      expiryDate.setMonth(expiryDate.getMonth() + 1)

      shareCall({
        variables: {
          engagement: id,
          expires: expiryDate,
          password: password
        }
      }).catch((err: any) => console.error(err))
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={share}
      onBackdropClick={() => dispatch(handleShare())}
    >
      <div>
        <Typography variant="h5" className={classes.title}>
          Share concept
        </Typography>
        <DialogContent>
          {id ? (
            <>
              {code ? (
                <Typography className={classes.url}>
                  {process.env.REACT_APP_IS_LOCAL === 'true'
                    ? `localhost:3000/share/${code}/${id}`
                    : `${process.env.REACT_APP_GO_DOMAIN}/${code}/${id}`}
                </Typography>
              ) : null}
            </>
          ) : (
            <Typography className={classes.text} variant="subtitle1">
              Please save concept first before sharing.
            </Typography>
          )}
          {mutationLoading && (
            <object
              className={classes.spinnerOnButton}
              data={SpinnerLogoDark}
            />
          )}
          {error && (
            <Typography
              style={{ paddingBottom: '6px', color: '#E53935' }}
              variant="subtitle1"
            >
              Something went wrong, please try again.
            </Typography>
          )}
          {id && !code && (
            <>
              <div style={{ paddingBottom: '12px' }}>
                <Selector
                  title="Password protected"
                  checked={passwordProtected}
                  onChange={() => handleSelector(passwordProtected)}
                />
              </div>
              <Collapse in={passwordProtected} timeout="auto" unmountOnExit>
                <Input
                  label="Set password"
                  placeholder="Set password"
                  error={inputError}
                  helperText={inputError ? "Password can't be empty" : ''}
                  type="text"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                />
              </Collapse>
            </>
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            label="CLOSE"
            variant="default"
            color="secondary"
            border
            onClick={() => dispatch(handleShare())}
            disablePadding
          />
          <Button
            label={buttonLabel}
            variant="contained"
            color="secondary"
            disabled={!id}
            onClick={handleClick}
            disablePadding
          />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default Share
