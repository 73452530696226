import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SidebarIcons from 'components/Navigation/SidebarIcons'
import { AppState } from 'types/types'

const useStyles = makeStyles((theme) => ({
  sidebar: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    top: '64px',
    bottom: 0,
    width: '63px',
    borderRight: `1px solid ${theme.palette.divider}`
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '63px',
    height: '64px'
  },
  bottomIcons: {
    position: 'absolute',
    bottom: 0
  }
}))

const Sidebar: React.FC = () => {
  const classes = useStyles()
  const { sidebarTop, sidebarBottom } = useSelector(
    (state: AppState) => state.sidebar
  )

  return (
    <div className={classes.sidebar}>
      <div>
        {sidebarTop.map((v) => (
          <div className={classes.icons} key={v.name}>
            <SidebarIcons {...v} />
          </div>
        ))}
      </div>
      <div className={classes.bottomIcons}>
        {sidebarBottom.map((v) => (
          <div className={classes.icons} key={v.name}>
            <SidebarIcons {...v} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar
