import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import Screen from 'components/Canvas/Screen'
import Preview from 'components/Canvas/Preview'
import { changeOther, changeCanvasPosition } from 'store/actions/other'
import { componentToModify } from 'store/actions/componentModify'
import { editComponent } from 'store/actions/componentSettings'
// import { closeSideBar } from 'store/actions/sidebar'
import { AppState, SidebarItem, Component } from 'types/types'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Draggable from 'react-draggable'

const useStyles = makeStyles((theme) => ({
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#FAFAFA',
    zIndex: -1
  },
  canvasContentArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    paddingLeft: '64px'
  },
  canvasContent: {
    '& > div:first-child': {
      width: '100%',
      height: '100vh'
    }
  },
  zoomControl: {
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    bottom: 20,
    right: 20,
    borderRadius: '4px 0px 0px 4px'
  },
  zoomButton: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    width: '48px',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
  },
  zoomChangeButton: {
    cursor: 'pointer',
    fontSize: '25px',
    '&:hover': {
      backgroundColor: '#FAFAFA'
    }
  }
}))

const Canvas: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { sidebarTop } = useSelector((state: AppState) => state.sidebar)
  const { canvas, moveScreens } = useSelector((state: AppState) => state.other)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const { preview } = useSelector((state: AppState) => state.preview)
  const [disableCanvasMove, setDisableCanvaseMove] = useState<boolean>(false)
  const [clickedComponent, setClickedComponent] = useState<Component>()
  const [positions, setPositions] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  })
  const [selectedAction, setSelectedAction] = useState<SidebarItem | null>(null)
  // this is temporary, until scale value can be synced between mouse and tools
  const [scaleToolsUsed, setScaleToolsUsed] = useState<boolean>(false)

  useEffect(() => {
    let isSelected = false
    sidebarTop.forEach((v) => {
      if (v.selected) {
        isSelected = true
        setSelectedAction(v)
      }
    })
    if (!isSelected) {
      setSelectedAction(null)
    }
  }, [sidebarTop])

  // reset canvas scale, positionY and positionX
  useEffect(() => {
    dispatch(changeCanvasPosition(undefined, undefined))
    if (!scaleToolsUsed) {
      dispatch(changeOther(undefined, 'canvas', 'scale'))
      setScaleToolsUsed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas.scale, canvas.positionX, canvas.positionY])

  const canvasCoubleClick = () => {
    // dispatch(closeSideBar())
    dispatch(componentToModify(false, false, '', ''))
  }

  const changeScale = (type: string) => {
    const editScale = canvas.scale || 1
    if (editScale >= 1 && type === 'plus') {
      return
    }
    if (editScale <= 0.1 && type === 'minus') {
      return
    }
    let changeScale = 0
    if (type === 'plus') {
      changeScale = editScale + 0.1
    }
    if (type === 'minus') {
      changeScale = editScale - 0.1
    }
    changeScale = Number(changeScale.toFixed(1))
    setScaleToolsUsed(true)
    dispatch(changeOther(changeScale, 'canvas', 'scale'))
  }

  const calculateScalePercentage = () => {
    const editScale = canvas.scale || 1
    const amount = editScale * 100
    return amount.toFixed(0)
  }

  const handleOnStart = (id: string) => {
    if (moveScreens) {
      setDisableCanvaseMove(true)
      const findComponent = components.find((c) => c.id === id)
      if (findComponent) {
        setPositions({ x: findComponent.positionX, y: findComponent.positionY })
        setClickedComponent(findComponent)
      }
    }
  }

  const handleStop = () => {
    if (moveScreens) {
      setDisableCanvaseMove(false)
      if (clickedComponent) {
        dispatch(editComponent(positions.x, 'positionX', clickedComponent.id))
        dispatch(editComponent(positions.y, 'positionY', clickedComponent.id))
      }
    }
  }

  const handleDrag = (_e: any, ui: any) => {
    if (moveScreens) {
      if (clickedComponent) {
        const x = positions.x + ui.deltaX
        const y = positions.y + ui.deltaY
        setPositions({ x, y })
      }
    }
  }

  return (
    <>
      <div className={classes.canvas} />
      <div className={classes.canvasContentArea}>
        <Grid container>
          <Grid item xs={selectedAction ? 3 : 1}>
            <SideDrawer selectedAction={selectedAction} />
          </Grid>
          <Grid item xs={selectedAction ? 9 : 12}>
            <div
              onDoubleClick={canvasCoubleClick}
              className={classes.canvasContent}
            >
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={80}
                positionX={canvas.positionX}
                positionY={canvas.positionY}
                scale={canvas.scale}
                options={{
                  disabled: disableCanvasMove,
                  limitToBounds: false,
                  minScale: 0.1,
                  maxScale: 1
                }}
                doubleClick={{ disabled: true }}
              >
                <TransformComponent>
                  {preview ? (
                    <Preview />
                  ) : (
                    components.map((component) => (
                      <Draggable
                        key={component.id}
                        onDrag={(e, ui) => handleDrag(e, ui)}
                        onStart={() => handleOnStart(component.id)}
                        onStop={handleStop}
                        position={{
                          x: component.positionX,
                          y: component.positionY
                        }}
                        grid={[10, 10]}
                      >
                        <div style={{ position: 'absolute' }}>
                          <Screen
                            component={component}
                            components={components}
                          />
                        </div>
                      </Draggable>
                    ))
                  )}
                </TransformComponent>
              </TransformWrapper>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.zoomControl}>
        <div style={{ display: 'flex' }}>
          <Typography
            component="div"
            className={clsx(classes.zoomButton, classes.zoomChangeButton)}
            onClick={() => changeScale('minus')}
          >
            -
          </Typography>
          <Typography
            variant="button"
            component="div"
            className={classes.zoomButton}
            style={{ width: '70px' }}
          >
            {`${calculateScalePercentage()}%`}
          </Typography>
          <Typography
            component="div"
            className={clsx(classes.zoomButton, classes.zoomChangeButton)}
            onClick={() => changeScale('plus')}
          >
            +
          </Typography>
        </div>
      </div>
    </>
  )
}

export default Canvas
