import produce from 'immer'
import { PreviewActionTypes, PreviewState } from 'types/types'
import {
  START_PREVIEW,
  CLOSE_PREVIEW,
  CHANGE_PREVIEW_INDEX,
  CHANGE_PREVIEW_COMPONENTS,
  SHOW_VIEW_COMPONENT,
  ONLY_PREVIEW
} from 'store/actions/preview'

const initialState: PreviewState = {
  sharedPreview: false,
  preview: false,
  showIndex: 0,
  previewComponentsLength: 0,
  showView: ''
}

const preview = (state = initialState, action: PreviewActionTypes) => {
  switch (action.type) {
    case START_PREVIEW: {
      return produce(state, (draft) => {
        draft.preview = true
      })
    }

    case CLOSE_PREVIEW: {
      return produce(state, (draft) => {
        draft.preview = false
        draft.showIndex = 0
        draft.showView = ''
      })
    }

    case CHANGE_PREVIEW_INDEX: {
      const { payload } = action
      return produce(state, (draft) => {
        if (payload.direction === 'forward') {
          draft.showIndex = state.showIndex + 1
        }
        if (payload.direction === 'back') {
          draft.showIndex = state.showIndex - 1
        }
      })
    }

    case CHANGE_PREVIEW_COMPONENTS: {
      const { payload } = action
      return produce(state, (draft) => {
        draft.previewComponentsLength = payload.value
      })
    }

    case SHOW_VIEW_COMPONENT: {
      const { payload } = action
      return produce(state, (draft) => {
        draft.showView = payload.viewId
      })
    }

    case ONLY_PREVIEW: {
      return produce(state, (draft) => {
        draft.preview = true
        draft.sharedPreview = true
      })
    }

    default:
      return state
  }
}

export default preview
