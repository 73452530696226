import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import Selector from 'components/common/Selector'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const ProductViewSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [image, setImage] = useState<string | undefined>('')
  const [title, setTitle] = useState<string | undefined>('')
  const [subtitle, setSubtitle] = useState<string | undefined>('')
  const [price, setPrice] = useState<string | undefined>('')
  const [totalRatings, setTotalRatings] = useState<number>(0)

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setImage(content.image)
          setTitle(content.title)
          setSubtitle(content.subtitle)
          setPrice(content.price)
          setTotalRatings(Number(content.totalRatings))
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'image') setImage(value)
    if (type === 'title') setTitle(value)
    if (type === 'subtitle') setSubtitle(value)
    if (type === 'price') setPrice(value)
    if (type === 'totalRatings') setTotalRatings(Number(value))
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  return (
    <>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="Image"
              name="image"
              value={image}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'image')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Title"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'title')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Description"
              name="description"
              value={subtitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'subtitle')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Price"
              name="price"
              value={price}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'price')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Number of ratings"
              name="number of ratings"
              type="number"
              value={String(totalRatings)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'totalRatings')
              }
            />
          </div>
          <Selector
            title="Show rating"
            checked={content.showRating}
            onChange={() =>
              dispatch(
                changeValueContent(!content.showRating, 'showRating', modifyId)
              )
            }
          />
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default ProductViewSettings
