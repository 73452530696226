import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as LoginIcon } from 'assets/login.svg'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, Whitelabel } from 'uniqore-components'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import { passwordResetDone } from 'store/actions/other'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(() => ({
  content: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#002C38',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputWrapper: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: 32
  },
  success: {
    marginTop: 16,
    color: 'white'
  }
}))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const resetEmailQuery = gql`
  mutation resetOrgUserPassword($auth: ID!, $email: String) {
    resetOrgUserPassword(auth: $auth, email: $email) {
      ok
      error
    }
  }
`

const resetPasswordQuery = gql`
  mutation resetOrgUserPassword(
    $auth: ID!
    $code: String
    $password: String
    $passwordRepeat: String
    $uid: String
  ) {
    resetOrgUserPassword(
      auth: $auth
      code: $code
      password: $password
      passwordRepeat: $passwordRepeat
      uid: $uid
    ) {
      ok
      error
    }
  }
`

const PasswordReset: React.FC = () => {
  let query = useQuery()
  const dispatch = useDispatch()

  const code = query.get('code')
  const uid = query.get('uid')
  const history = useHistory()

  const classes = useStyles()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isEmailRequest] = useState<boolean>(code === null)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const [resetEmail] = useMutation(resetEmailQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetOrgUserPassword.error) {
        setError(mutationResult.resetOrgUserPassword.error)
      } else if (mutationResult.resetOrgUserPassword.ok) {
        setSuccess('Password reset email is on the way!')
        setEmail('')
      }
    }
  })

  const [resetPassword] = useMutation(resetPasswordQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetOrgUserPassword.error) {
        setError(mutationResult.resetOrgUserPassword.error)
      } else if (mutationResult.resetOrgUserPassword.ok) {
        setPassword('')
        setPasswordRepeat('')
        dispatch(passwordResetDone(true))
        history.push('/login')
      }
    }
  })

  const handleClick = () => {
    setShowPassword((prev) => !prev)
  }

  const submit = (event: React.FormEvent) => {
    setError('')
    if (isEmailRequest) {
      if (email === '') {
        setError('Email cannot be empty')
      } else {
        resetEmail({
          variables: {
            email: email,
            auth: process.env.REACT_APP_ORG_AUTH_ID
          }
        })
      }
    } else {
      resetPassword({
        variables: {
          auth: process.env.REACT_APP_ORG_AUTH_ID,
          code: code,
          uid: uid,
          password: password,
          passwordRepeat: passwordRepeat
        }
      })
    }

    event.preventDefault()
  }

  return (
    <>
      <div className={classes.content}>
        <form
          style={{
            textAlign: 'center'
          }}
          onSubmit={submit}
        >
          <div style={{ paddingBottom: '8px' }}>
            <LoginIcon />
          </div>
          <Typography
            style={{ color: '#FAFAFA', paddingBottom: '16px' }}
            variant="h6"
          >
            Reset your password
          </Typography>
          {success ? (
            <div className={classes.success}>{success}</div>
          ) : (
            <>
              {isEmailRequest ? (
                <div className={classes.inputWrapper}>
                  <TextField
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    placeholder="Email"
                    fullWidth
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                  />
                </div>
              ) : (
                <>
                  <div className={classes.inputWrapper}>
                    <TextField
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                      placeholder="Password"
                      fullWidth
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClick} edge="end">
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <TextField
                      value={passwordRepeat}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPasswordRepeat(e.target.value)
                      }
                      placeholder="Password again"
                      fullWidth
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClick} edge="end">
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={!!error}
                      helperText={error}
                    />
                  </div>
                </>
              )}
              <Button
                style={{ paddingTop: '16px' }}
                label={isEmailRequest ? 'RESET PASSWORD' : 'CHANGE PASSWORD'}
                color="secondary"
                variant="contained"
                type="submit"
              />
            </>
          )}
        </form>
      </div>
      <Whitelabel style={{ position: 'absolute', bottom: 0, width: '100%' }} />
    </>
  )
}

export default PasswordReset
