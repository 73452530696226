import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import AddNewComponentList from 'components/blocks/AddNewComponentList'
import HeaderSettings from 'components/ComponentSettings/HeaderSettings'
import FooterSettings from 'components/ComponentSettings/FooterSettings'
import StatusHeroSettings from 'components/ComponentSettings/StatusHeroSettings'
import FeedSettings from 'components/ComponentSettings/FeedSettings'
import HeadingSettings from 'components/ComponentSettings/HeadingSettings'
import ActionAreaSettings from 'components/ComponentSettings/ActionAreaSettings'
import DataDisplaySettings from 'components/ComponentSettings/DataDisplaySettings'
import CatalogSettings from 'components/ComponentSettings/CatalogSettings'
import SmallPrintSettings from 'components/ComponentSettings/SmallPrintSettings'
import ButtonSettings from 'components/ComponentSettings/ButtonSettings'
import CustomSvgSettings from 'components/ComponentSettings/CustomSvgSettings'
import DatePickerSettings from 'components/ComponentSettings/DatePickerSettings'
import LoginSettings from 'components/ComponentSettings/LoginSettings'
import ProductViewSettings from 'components/ComponentSettings/ProductViewSettings'
import OnboardingSettings from 'components/ComponentSettings/OnboardingSettings'
import ErrorPageSettings from 'components/ComponentSettings/ErrorPageSettings'
import SearchDialogSettings from 'components/ComponentSettings/SearchDialogSettings'
import { AppState } from 'types/types'

interface Props {
  view: string
  setView: Dispatch<SetStateAction<string>>
}

const ComponentValues: React.FC<Props> = ({ view, setView }) => {
  const { modifyName } = useSelector((state: AppState) => state.componentModify)

  return (
    <div>
      {modifyName === 'Add component' && <AddNewComponentList />}
      {modifyName === 'Header' && <HeaderSettings />}
      {modifyName === 'Footer' && <FooterSettings />}
      {modifyName === 'Heading' && <HeadingSettings />}
      {modifyName === 'Status' && (
        <StatusHeroSettings view={view} setView={setView} />
      )}
      {modifyName === 'Feed' && <FeedSettings />}
      {modifyName === 'Action area' && (
        <ActionAreaSettings view={view} setView={setView} />
      )}
      {modifyName === 'Data display' && (
        <DataDisplaySettings view={view} setView={setView} />
      )}
      {modifyName === 'Catalog' && (
        <CatalogSettings view={view} setView={setView} />
      )}
      {modifyName === 'Small print' && <SmallPrintSettings />}
      {modifyName === 'CTA Button' && <ButtonSettings />}
      {modifyName === 'Custom Svg' && <CustomSvgSettings />}
      {modifyName === 'Date picker' && <DatePickerSettings />}
      {modifyName === 'Login' && <LoginSettings />}
      {modifyName === 'Product View' && <ProductViewSettings />}
      {modifyName === 'Onboarding' && <OnboardingSettings />}
      {modifyName === 'Error Page' && <ErrorPageSettings />}
      {modifyName === 'Search Dialog' && <SearchDialogSettings />}
    </div>
  )
}

export default ComponentValues
