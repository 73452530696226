import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import { AppState, Content, ContentKey } from 'types/types'
import { changeValueContent } from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px'
  },
  sectionSeparator: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

interface Props {
  view: string
  setView: Dispatch<SetStateAction<string>>
}

const StatusHeroSettings: React.FC<Props> = ({ view, setView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [contentExpand, setContentExpand] = useState(true)
  const [ctaExpand, setCtaExpand] = useState(true)
  const [content, setContent] = useState<Content | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [icon, setIcon] = useState<string | undefined>('')
  const [noticeText, setNoticeText] = useState<string | undefined>('')
  const [headline, setHeadline] = useState<string | undefined>('')
  const [title, setTitle] = useState<string | undefined>('')
  const [subtitle, setSubtitle] = useState<string | undefined>('')
  const [body, setBody] = useState<string | undefined>('')
  const [ctaIcon, setCtaIcon] = useState<string | undefined>('')
  const [label, setLabel] = useState<string | undefined>('')
  const [ctaSecondaryIcon, setCtaSecondaryIcon] = useState<string | undefined>(
    ''
  )
  const [secondaryLabel, setSecondaryLabel] = useState<string | undefined>('')
  const [image, setImage] = useState<string | undefined>('')
  const [video, setVideo] = useState<string | undefined>('')
  const [feedbackText, setFeedbackText] = useState<string | undefined>('')
  const [svg, setSvg] = useState<string | undefined>('')

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setContent(content)
          setIcon(content.icon)
          setNoticeText(content.noticeText)
          setHeadline(content.headline)
          setTitle(content.title)
          setSubtitle(content.subtitle)
          setBody(content.body)
          setCtaIcon(content.ctaIcon)
          setLabel(content.label)
          setCtaSecondaryIcon(content.ctaSecondaryIcon)
          setSecondaryLabel(content.secondaryLabel)
          setImage(content.image)
          setVideo(content.video)
          setFeedbackText(content.feedbackText)
          setSvg(content.svg)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const hideContent = (value: boolean, stateKey: ContentKey) => {
    dispatch(changeValueContent(value, stateKey, modifyId))
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'icon') setIcon(value)
    if (type === 'noticeText') setNoticeText(value)
    if (type === 'headline') setHeadline(value)
    if (type === 'title') setTitle(value)
    if (type === 'subtitle') setSubtitle(value)
    if (type === 'body') setBody(value)
    if (type === 'ctaIcon') setCtaIcon(value)
    if (type === 'label') setLabel(value)
    if (type === 'ctaSecondaryIcon') setCtaSecondaryIcon(value)
    if (type === 'secondaryLabel') setSecondaryLabel(value)
    if (type === 'image') setImage(value)
    if (type === 'video') setVideo(value)
    if (type === 'feedbackText') setFeedbackText(value)
    if (type === 'svg') setSvg(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  return (
    <>
      {view === '' && (
        <div className={classes.container}>
          <Paper elevation={2}>
            <StepList
              className={classes.sectionSeparator}
              icon="Image"
              title="Status image"
              subtitle={content.icon}
              actionIcon={content.iconVisible ? 'Visibility' : 'VisibilityOff'}
              highElement
              onClick={() => setView('Status image')}
              onClickAction={() =>
                hideContent(!content.iconVisible, 'iconVisible')
              }
            />
            <StepList
              className={classes.sectionSeparator}
              icon="TextFormat"
              title="Status text"
              subtitle={content.textSelection}
              actionIcon={content.textVisible ? 'Visibility' : 'VisibilityOff'}
              highElement
              onClick={() => setView('Status text')}
              onClickAction={() =>
                hideContent(!content.textVisible, 'textVisible')
              }
            />
            <StepList
              icon="PlayCircleFilled"
              title="Status CTA"
              subtitle={content.ctaSelection}
              actionIcon={content.ctaVisible ? 'Visibility' : 'VisibilityOff'}
              highElement
              onClick={() => setView('Status CTA')}
              onClickAction={() =>
                hideContent(!content.ctaVisible, 'ctaVisible')
              }
            />
          </Paper>
        </div>
      )}
      {view === 'Status image' && (
        <div className={classes.selectContainer}>
          <div className={classes.inputPadding}>
            <Input
              label="Icon"
              value={icon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'icon')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              type="number"
              label="Icon size (max. 80)"
              min={1}
              max={80}
              value={content.iconSize}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'iconSize')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Image url"
              value={image}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'image')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Video url"
              value={video}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'video')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Svg code"
              rows={10}
              value={svg}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'svg')
              }
              helperText="Max width 328px"
            />
          </div>
        </div>
      )}
      {view === 'Status text' && (
        <>
          <div className={classes.selectContainer}>
            <Input
              label="Type"
              options={[
                { value: 'Notice text', label: 'Notice text' },
                { value: 'Headline', label: 'Headline' },
                { value: 'Subtitle', label: 'Subtitle' },
                { value: 'Headline + Subtitle', label: 'Headline + Subtitle' },
                { value: 'Custom', label: 'Custom' }
              ]}
              value={content.textSelection}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'textSelection')
              }
            />
          </div>
          <CollapseList
            expanded={contentExpand}
            setExpanded={setContentExpand}
            primary="Content"
          />
          {content.textSelection !== '' && (
            <Collapse in={contentExpand} timeout="auto" unmountOnExit>
              <div className={classes.collapseItems}>
                {content.textSelection === 'Notice text' ||
                content.textSelection === 'Custom' ? (
                  <div className={classes.inputPadding}>
                    <Input
                      label="Notice text"
                      value={noticeText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'noticeText')
                      }
                    />
                  </div>
                ) : null}
                {content.textSelection === 'Headline' ||
                content.textSelection === 'Custom' ||
                content.textSelection === 'Headline + Subtitle' ? (
                  <div className={classes.inputPadding}>
                    <Input
                      label="Headline"
                      value={headline}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'headline')
                      }
                    />
                  </div>
                ) : null}
                {content.textSelection === 'Title' ||
                content.textSelection === 'Custom' ? (
                  <div className={classes.inputPadding}>
                    <Input
                      label="Title"
                      value={title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'title')
                      }
                    />
                  </div>
                ) : null}
                {content.textSelection === 'Subtitle' ||
                content.textSelection === 'Custom' ||
                content.textSelection === 'Headline + Subtitle' ? (
                  <div className={classes.inputPadding}>
                    <Input
                      label="Subtitle"
                      rows={5}
                      value={subtitle}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'subtitle')
                      }
                    />
                  </div>
                ) : null}
                <div className={classes.inputPadding}>
                  <Input
                    label="Body"
                    value={body}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeInput(e.target.value, 'body')
                    }
                  />
                </div>
              </div>
            </Collapse>
          )}
        </>
      )}
      {view === 'Status CTA' && (
        <div>
          <div className={classes.selectContainer}>
            <Input
              label="Type"
              options={[
                { value: 'CTA', label: 'CTA' },
                { value: 'CTA + secondary CTA', label: 'CTA + secondary CTA' },
                { value: 'Feedback', label: 'Feedback' }
              ]}
              value={content.ctaSelection}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'ctaSelection')
              }
            />
          </div>
          <CollapseList
            expanded={ctaExpand}
            setExpanded={setCtaExpand}
            primary="Content"
          />
          <Collapse in={ctaExpand} timeout="auto" unmountOnExit>
            <div className={classes.collapseItems}>
              {content.ctaSelection === 'CTA' ||
              content.ctaSelection === 'CTA + secondary CTA' ? (
                <>
                  <div className={classes.inputPadding}>
                    <Input
                      label="CTA icon"
                      prefixIcon="Image"
                      value={ctaIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'ctaIcon')
                      }
                    />
                  </div>
                  <div className={classes.inputPadding}>
                    <Input
                      label="CTA text"
                      value={label}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'label')
                      }
                    />
                  </div>
                </>
              ) : null}
              {content.ctaSelection === 'CTA + secondary CTA' && (
                <>
                  <div className={classes.inputPadding}>
                    <Input
                      label="CTA secondary icon"
                      prefixIcon="Image"
                      value={ctaSecondaryIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'ctaSecondaryIcon')
                      }
                    />
                  </div>
                  <div className={classes.inputPadding}>
                    <Input
                      label="CTA secondary text"
                      value={secondaryLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'secondaryLabel')
                      }
                    />
                  </div>
                </>
              )}
              {content.ctaSelection === 'Feedback' && (
                <>
                  <div className={classes.inputPadding}>
                    <Input
                      label="Feedback text"
                      value={feedbackText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'feedbackText')
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </Collapse>
        </div>
      )}
    </>
  )
}

export default StatusHeroSettings
