import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  ThemeOptions
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { SpeedDial, defaultTheme } from 'uniqore-components'
import { default as Status } from 'components/examples/StatusComponent'
import { default as Play } from 'components/examples/PlayComponent'
import { default as View } from 'components/examples/ViewComponent'
import { componentToModify } from 'store/actions/componentModify'
import { changeSelected } from 'store/actions/sidebar'
import { addNewComponent } from 'store/actions/componentSettings'
import { componentStatus, componentPlay, componentView } from 'util/common'
import { AppState, Component } from 'types/types'

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    position: 'relative'
  },
  border: {
    outline: '2px solid transparent'
  },
  borderShow: {
    outline: '2px solid #1E88E5'
  },
  componentText: {
    position: 'absolute',
    width: '100%',
    top: '-25px',
    paddingBottom: '7px',
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  sideSpeedDial: {
    position: 'absolute',
    right: '-65px',
    top: '120px',
    '& [class*="MuiSpeedDialAction-staticTooltipLabel"]': {
      backgroundColor: 'rgba(0,0,0,0.87)'
    },
    '& [class*="MuiSpeedDialAction-fab"]': {
      color: 'rgba(0,0,0,0.54)'
    }
  },
  sideSpeedDialPath: {
    position: 'absolute',
    right: '-65px',
    top: '220px',
    '& [class*="MuiSpeedDialAction-staticTooltipLabel"]': {
      backgroundColor: 'rgba(0,0,0,0.87)'
    },
    '& [class*="MuiSpeedDialAction-fab"]': {
      color: 'rgba(0,0,0,0.54)'
    }
  },
  bottomSpeedDial: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '-70px',
    '& [class*="MuiSpeedDialAction-staticTooltipLabel"]': {
      backgroundColor: 'rgba(0,0,0,0.87)'
    },
    '& [class*="MuiSpeedDialAction-fab"]': {
      color: 'rgba(0,0,0,0.54)'
    }
  }
}))

const Screen: React.FC<{
  component: Component
  components: Component[]
}> = ({ component, components }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { preview, showIndex, previewComponentsLength } = useSelector(
    (state: AppState) => state.preview
  )
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const {
    clientName,
    logo,
    logoSize,
    primary,
    secondary,
    textOnPrimary,
    textOnSecondary,
    statusBackground,
    statusText
  } = useSelector((state: AppState) => state.componentSettings)
  const [Components] = useState({ Status, Play, View })
  const [customTheme, setCustomTheme] = useState({})

  useEffect(() => {
    setCustomTheme(
      createMuiTheme({
        palette: {
          ...defaultTheme.palette,
          primary: {
            main: primary === '#' ? '#0' : primary,
            contrastText: textOnPrimary === '#' ? '#0' : textOnPrimary
          },
          secondary: {
            main: secondary === '#' ? '#0' : secondary,
            contrastText: textOnSecondary === '#' ? '#0' : textOnSecondary
          },
          text: {
            primary: 'rgba(0, 44, 56, 0.87)',
            secondary: 'rgba(0, 44, 56, 0.6)',
            disabled: 'rgba(0, 44, 56, 0.38)'
          }
        },
        custom: {
          status: {
            background: statusBackground === '#' ? '#0' : statusBackground,
            color: statusText === '#' ? '#0' : statusText
          }
        },
        typography: {
          ...defaultTheme.typography,
          fontFamily: '"Roboto"',
          h1: {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '96px',
            lineHeight: '76px'
          },
          h3: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '48px',
            lineHeight: '50px'
          },
          h5: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px'
          },
          h6: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '31px',
            letterSpacing: '0.15px'
          },
          subtitle1: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '0.15px'
          },
          body2: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.25px'
          },
          button: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            alignItems: 'center',
            letterSpacing: '1.25px'
          },
          caption: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.4px'
          },
          overline: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '1.5px'
          }
        }
      } as ThemeOptions)
    )
  }, [
    primary,
    secondary,
    textOnPrimary,
    textOnSecondary,
    statusBackground,
    statusText
  ])

  const handleNameClick = (id: string) => {
    dispatch(changeSelected('Screens', 'sidebarTop', true))
    dispatch(componentToModify(true, false, id, 'All screens'))
  }

  const addScreenSide = (type: Component, leftId: string) => {
    dispatch(addNewComponent(type, leftId))
  }

  const addScreenSidePath = (type: Component, pathLeftId: string) => {
    const clickedComponent = components.find((c) => c.id === pathLeftId)
    if (clickedComponent) {
      const pathParent = components.find(
        (c) => c.id === clickedComponent.pathParentId
      )
      // addNewComponent fourth argument should always be the id of the first path id (pathParent)
      if (pathParent) {
        dispatch(
          addNewComponent(
            type,
            undefined,
            undefined,
            pathParent.id,
            clickedComponent.order
          )
        )
      } else {
        dispatch(
          addNewComponent(
            type,
            undefined,
            undefined,
            clickedComponent.id,
            clickedComponent.order
          )
        )
      }
    }
  }

  const addScreenBottom = (type: Component, topId: string) => {
    dispatch(addNewComponent(type, undefined, topId))
  }

  const addScreenDuplicate = (leftId: string) => {
    const copiedComponent = components.find((c) => c.id === leftId)
    if (copiedComponent) {
      addScreenSide(copiedComponent, leftId)
    }
  }

  return (
    <div>
      <div className={classes.componentContainer}>
        <div
          className={classes.componentText}
          onClick={() => handleNameClick(component.id)}
        >
          <Typography variant="overline">
            {component.name || 'No name'}
          </Typography>
        </div>
        <div>
          <Paper elevation={1}>
            <ThemeProvider theme={customTheme}>
              <div
                className={clsx({
                  [classes.border]: modifyId !== component.id,
                  [classes.borderShow]: modifyId === component.id
                })}
              >
                {React.createElement(Components[component.type], {
                  component,
                  clientName,
                  primary,
                  secondary,
                  statusText,
                  statusBackground,
                  logo,
                  logoSize,
                  modifyId,
                  preview,
                  showIndex,
                  previewComponentsLength
                })}
              </div>
            </ThemeProvider>
          </Paper>
          {/* Speeddial under Status and Play */}
          {component.type !== 'View' &&
            (component.parentId || component.pathParentId ? null : (
              <SpeedDial
                backdropDisable
                color="#43A047"
                backgroundColor="#FFFFFF"
                className={classes.bottomSpeedDial}
                actions={
                  component.type === 'Status'
                    ? [
                        {
                          label: 'Add view',
                          icon: 'List',
                          onClick: () =>
                            addScreenBottom(componentView, component.id)
                        }
                      ]
                    : [
                        {
                          label: 'Add path',
                          icon: 'CallSplit',
                          onClick: () =>
                            addScreenBottom(componentPlay, component.id)
                        }
                      ]
                }
              />
            ))}
        </div>
      </div>
      {/* Speeddial on the left side of Play and Status */}
      {component.type !== 'View' &&
        (component.parentId || component.pathParentId ? (
          <SpeedDial
            backdropDisable
            color="#43A047"
            backgroundColor="#FFFFFF"
            className={classes.sideSpeedDialPath}
            actions={[
              {
                icon: 'PlayArrow',
                label: 'Add Play',
                onClick: () => addScreenSidePath(componentPlay, component.id)
              }
            ]}
          />
        ) : (
          <SpeedDial
            backdropDisable
            color="#43A047"
            backgroundColor="#FFFFFF"
            className={classes.sideSpeedDial}
            actions={[
              {
                icon: 'FileCopy',
                label: 'Add Duplicate',
                onClick: () => addScreenDuplicate(component.id)
              },
              {
                icon: 'PlayArrow',
                label: 'Add Play',
                onClick: () => addScreenSide(componentPlay, component.id)
              },
              {
                icon: 'Airplay',
                label: 'Add Status',
                onClick: () => addScreenSide(componentStatus, component.id)
              }
            ]}
          />
        ))}
    </div>
  )
}

export default React.memo(Screen)
