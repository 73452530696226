import React, { useState, useRef, useEffect, useReducer } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Component } from 'types/types'
import { changePreviewIndex } from 'store/actions/preview'
import { componentToModify } from 'store/actions/componentModify'
import { changeSelected } from 'store/actions/sidebar'
import { editComponent } from 'store/actions/componentSettings'
import parse from 'html-react-parser'
import Icons from 'components/common/Icons'
import { ActionItem, Content, SpeedDialItem } from 'types/types'
import Onboarding from 'components/Custom/Onboarding'
import SearchDialog from 'components/Custom/SearchDialog'
import {
  AppBar,
  BottomBar,
  BottomNavigation,
  Button,
  DatePicker,
  ErrorPage,
  Heading,
  CatalogItem,
  Checkbox,
  ListItem,
  Login,
  ProductView,
  Radio,
  Switch,
  SmallPrint,
  Select,
  SpeedDial,
  TextField,
  Verification,
  Whitelabel
} from 'uniqore-components'

const useStyles = makeStyles(() => ({
  component: {
    position: 'relative',
    minHeight: '640px',
    width: '360px',
    backgroundColor: '#FFFFFF'
  },
  outlineHover: {
    '&:hover': {
      outline: '2px solid #8ec8fc'
    }
  },
  outlineClicked: {
    outline: '2px solid #1E88E5'
  },
  speedDial: {
    position: 'absolute',
    marginLeft: '130px',
    marginBottom: '15px',
    width: '100%',
    bottom: 0
  },
  bottomBar: {
    position: 'absolute',
    width: '100%',
    bottom: '34px'
  },
  footerSafeArea: {
    paddingTop: '40px'
  },
  footerVisible: {
    paddingTop: '105px'
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%'
  }
}))

const reducerSingle = (state: ActionItem[], action: any) => {
  switch (action.type) {
    case 'init':
      return action.payload
    case 'increase':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, selected: true }
        } else {
          return item
        }
      })
    case 'decrease':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, selected: false }
        } else {
          return item
        }
      })
    default:
      return state
  }
}

function reducerMulti(state: ActionItem[], action: any) {
  switch (action.type) {
    case 'init':
      return action.payload
    case 'increase':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! += 1) }
        } else {
          return item
        }
      })
    case 'decrease':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! -= 1) }
        } else {
          return item
        }
      })
    case 'remove':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity = 0) }
        } else {
          return item
        }
      })
    case 'setQuantity':
      return state.map((item: ActionItem) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity = action.payload.value) }
        } else {
          return item
        }
      })
    default:
      return state
  }
}

interface Props {
  component: Component
  modifyId: string
  preview: boolean
  showIndex: number
  previewComponentsLength: number
  clientName?: string
  primary?: string
  secondary?: string
  statusText?: string
  statusBackground?: string
  logo?: string
  logoSize?: string
}

const PlayComponent: React.FC<Props> = React.memo(
  ({ component, modifyId, preview, showIndex, previewComponentsLength }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [catalogItemsSingle, dispatchSingle] = useReducer(reducerSingle, [])
    const [catalogItemsMulti, dispatchMulti] = useReducer(reducerMulti, [])
    const [date, setDate] = useState<Date>(new Date())
    const [selectValue, setSelectValue] = useState<string>('')
    const [radioValue, setRadioValue] = useState<number | string>()
    const [checkboxValue, setCheckboxValue] = useState<string[] | number[]>([])
    const [speedDialActions, setSpeedDialActions] = useState<SpeedDialItem[]>(
      []
    )
    const [bottomBarButtons, setBottomBarButtons] = useState<JSX.Element[]>([])
    const [bottomNavigationActions, setBottomNavigationActions] = useState<
      {
        label: string | undefined
        value: string | undefined
        icon: string | undefined
      }[]
    >([])
    const componentRef = useRef(null)

    const goBack = () => {
      if (preview && showIndex !== 0) {
        dispatch(changePreviewIndex('back'))
      }
    }

    const goForward = () => {
      if (preview) {
        if (showIndex !== previewComponentsLength - 1) {
          dispatch(changePreviewIndex('forward'))
        }
      }
    }

    useEffect(() => {
      if (componentRef.current) {
        //@ts-ignore
        const height = componentRef.current.clientHeight || 640
        //@ts-ignore
        const width = componentRef.current.clientWidth || 360
        dispatch(editComponent(String(height + 110), 'height', component.id))
        dispatch(editComponent(String(width + 98), 'width', component.id))
      }
    }, [component, dispatch])

    // sets catalog items
    useEffect(() => {
      const newCatalogItems: ActionItem[] = []
      component.contents.forEach((content) => {
        if (
          content.name === 'Catalog' &&
          content.selection === 'Catalog item'
        ) {
          content.actionItems!.forEach((item) => {
            const newItem = JSON.parse(JSON.stringify(item))
            newCatalogItems.push(newItem)
          })
        }
      })
      dispatchSingle({ type: 'init', payload: newCatalogItems })
      dispatchMulti({ type: 'init', payload: newCatalogItems })
    }, [component.contents])

    // Set footer types:
    // If type is SpeedDial, set speedDialActions, also check and set default icon to prevent error
    // If type is Naviation, set bottomBarButtons
    // If type is Tabs, set bottomNavigationActions
    useEffect(() => {
      if (component.footer) {
        const footer = component.footer
        if (footer.type === 'SpeedDial') {
          const getSpeedDialActions = component.footer.speedDialActions || []
          let actions: SpeedDialItem[] = []
          getSpeedDialActions.forEach((item) => {
            let itemSet = item
            const itemIcon = item.icon || ''
            if (!Object.prototype.hasOwnProperty.call(Icons, itemIcon)) {
              const editItemSet = { ...itemSet, icon: 'RadioButtonUnchecked' }
              actions.push(editItemSet)
            } else {
              actions.push(itemSet)
            }
          })
          setSpeedDialActions(actions)
        }
        if (
          footer.type === 'Navigation' ||
          !Object.prototype.hasOwnProperty.call(footer, 'type')
        ) {
          const buttons = []
          if (footer.backLabel || footer.backIcon) {
            buttons.push(
              <Button
                label={footer.backLabel}
                icon={footer.backIcon}
                disablePadding
                fullWidth
                color="primary"
                variant="default"
                onClick={goBack}
              />
            )
          }
          if (footer.nextLabel || footer.nextIcon) {
            buttons.push(
              <Button
                label={footer.nextLabel}
                icon={footer.nextIcon}
                disablePadding
                fullWidth
                color="primary"
                variant="contained"
                onClick={goForward}
              />
            )
          }
          setBottomBarButtons(buttons)
        }
        if (footer.type === 'Tabs') {
          const actions = []
          if (footer.firstTabIcon || footer.firstTabLabel) {
            actions.push({
              label: footer.firstTabLabel,
              value: footer.firstTabLabel,
              icon: footer.firstTabIcon
            })
          }
          if (footer.secondTabIcon || footer.secondTabLabel) {
            actions.push({
              label: footer.secondTabLabel,
              value: footer.secondTabLabel,
              icon: footer.secondTabIcon
            })
          }
          if (footer.thirdTabIcon || footer.thirdTabLabel) {
            actions.push({
              label: footer.thirdTabLabel,
              value: footer.thirdTabLabel,
              icon: footer.thirdTabIcon
            })
          }
          if (footer.fourthTabIcon || footer.fourthTabLabel) {
            actions.push({
              label: footer.fourthTabLabel,
              value: footer.fourthTabLabel,
              icon: footer.fourthTabIcon
            })
          }
          if (footer.fifthTabIcon || footer.fifthTabLabel) {
            actions.push({
              label: footer.fifthTabLabel,
              value: footer.fifthTabLabel,
              icon: footer.fifthTabIcon
            })
          }
          setBottomNavigationActions(actions)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component.footer])

    const handleRadioClick = (value: number | string) => {
      setRadioValue(value)
    }

    const handleCheckboxClick = (value: number | string) => {
      // @ts-ignore
      const currentIndex: number = checkboxValue.indexOf(value)
      // @ts-ignore
      const newChecked: string[] | number[] = [...checkboxValue]

      if (currentIndex === -1) {
        // @ts-ignore
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }
      setCheckboxValue(newChecked)
    }

    const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectValue(event.target.value as string)
    }

    const getLoginAndOnboardingButtons = (
      content: Content
    ): React.ReactNode[] => {
      const buttons: React.ReactNode[] = []
      if (content.label) {
        buttons.push(<Button color="primary" fullWidth label={content.label} />)
      }
      if (content.secondaryLabel) {
        buttons.push(
          <Button
            variant="text"
            fullWidth
            color="primary"
            label={content.secondaryLabel}
            disablePadding
          />
        )
      }
      return buttons
    }

    const getLoginTextFields = (content: Content): React.ReactNode[] => {
      const textFields: React.ReactNode[] = []
      if (content.primaryTextField) {
        textFields.push(
          <TextField
            fullWidth
            placeholder={content.primaryTextField}
            prefix={{ icon: content.primaryPrefix }}
            suffix={{ icon: content.primarySuffix }}
          />
        )
      }
      if (content.secondaryTextField) {
        textFields.push(
          <TextField
            fullWidth
            placeholder={content.secondaryTextField}
            prefix={{ icon: content.secondaryPrefix }}
            suffix={{ icon: content.secondarySuffix }}
          />
        )
      }
      return textFields
    }

    const handleChangeNavigation = () => {
      if (footer.firstTabActive) {
        return footer.firstTabLabel
      }
      if (footer.secondTabActive) {
        return footer.secondTabLabel
      }
      if (footer.thirdTabActive) {
        return footer.thirdTabLabel
      }
      if (footer.fourthTabActive) {
        return footer.fourthTabLabel
      }
      if (footer.fifthTabActive) {
        return footer.fifthTabLabel
      }
    }

    if (!component) {
      return null
    }

    const contents = component.contents
    const header = component.header
    const footer = component.footer

    const handleClickComponent = (id: string, name: string) => {
      if (!preview) {
        dispatch(changeSelected('Screens', 'sidebarTop', true))
        dispatch(componentToModify(true, true, id, name))
      }
    }

    return (
      <div ref={componentRef} className={classes.component}>
        {contents.map((content) => (
          <div
            key={content.id}
            style={{ display: content.visible ? '' : 'none' }}
            className={clsx({
              [classes.outlineHover]: modifyId !== content.id && !preview,
              [classes.outlineClicked]: modifyId === content.id && !preview
            })}
            onClick={() => handleClickComponent(content.id, content.name)}
          >
            {content.name === 'Error Page' && (
              <div
                style={{ position: 'absolute', height: '100%', width: '100%' }}
              >
                <ErrorPage
                  text={content.title}
                  errorText={content.subtitle}
                  errorType={content.error}
                  link={{ text: content.link }}
                />
              </div>
            )}
            {content.name === 'Onboarding' && (
              <Onboarding
                actionDirection={content.ctaDirection as any}
                actionComponents={getLoginAndOnboardingButtons(content)}
                showIndicators={false}
                screens={[
                  {
                    color: content.color,
                    backgroundColor: content.backgroundColor,
                    title: content.title,
                    subtitle: content.subtitle,
                    image: content.image,
                    backgroundImage: content.backgroundImage
                  }
                ]}
              />
            )}
          </div>
        ))}
        <div
          className={clsx({
            [classes.outlineHover]: modifyId !== header.id && !preview,
            [classes.outlineClicked]: modifyId === header.id && !preview
          })}
          style={{ display: header.visible ? '' : 'none' }}
          onClick={() => handleClickComponent(header.id, 'Header')}
        >
          <AppBar
            mainAction={{ icon: header.mainAction as string, onClick: goBack }}
            text={header.text}
            backgroundColor="white"
            actions={header.actions?.map((action) => ({ icon: action }))}
            border={header.border}
            centerText={header.centerText}
          />
        </div>
        {contents.map((content) => {
          return (
            <div
              key={content.id}
              style={{ display: content.visible ? '' : 'none' }}
              className={clsx({
                [classes.outlineHover]: modifyId !== content.id && !preview,
                [classes.outlineClicked]: modifyId === content.id && !preview
              })}
              onClick={() => handleClickComponent(content.id, content.name)}
            >
              {content.name === 'Custom Svg' && (
                <div>{parse(content.svg as string)}</div>
              )}
              {content.name === 'Heading' && (
                <Heading
                  title={content.title}
                  body={
                    content.selection === 'Title + Body' ? content.body : ''
                  }
                  align={content.textLeftAlign ? 'left' : 'center'}
                />
              )}
              {content.name === 'Small print' && (
                <SmallPrint
                  htmlText={content.text}
                  align={content.textLeftAlign ? 'left' : 'center'}
                />
              )}
              {content.name === 'CTA Button' && (
                <Button
                  label={content.label}
                  icon={content.icon}
                  variant={
                    content.selection === 'Contained' ? 'contained' : 'text'
                  }
                  color={content.colorSecondary ? 'secondary' : 'primary'}
                  fullWidth
                />
              )}
              {content.name === 'Date picker' && (
                <DatePicker
                  date={date}
                  onChange={setDate}
                  label={content.label}
                  language={content.languageFi ? 'fi' : 'en'}
                  disablePast={content.disablePast}
                  disableToolbar={content.disableToolbar}
                  variant={
                    content.selection === 'Calendar' ? 'static' : 'dialog'
                  }
                />
              )}
              {content.name === 'Product View' && (
                <ProductView
                  title={content.title}
                  description={content.subtitle}
                  image={content.image}
                  price={content.price}
                  ratingValue={content.showRating ? 0 : undefined}
                  totalRatings={
                    // eslint-disable-next-line eqeqeq
                    content.totalRatings == 0
                      ? undefined
                      : Number(content.totalRatings)
                  }
                />
              )}
              {content.name === 'Search Dialog' && (
                <SearchDialog
                  inputPlaceholder={content.inputPlaceholder}
                  resultsHeading={content.resultsHeading}
                  resultsPlaceholder={content.resultsPlaceholder}
                  label={content.label}
                  color={content.primaryColor ? 'primary' : 'secondary'}
                  buttonColor={content.primaryButton ? 'primary' : 'secondary'}
                />
              )}
              {content.name === 'Login' &&
                (content.selection === 'Basic' ? (
                  <Login
                    maxWidth="sm"
                    headline={content.headline}
                    subtitle={content.subtitle}
                    actionComponents={getLoginAndOnboardingButtons(content)}
                    contentComponents={getLoginTextFields(content)}
                  />
                ) : content.selection === 'OTP' ? (
                  <Verification
                    maxWidth="sm"
                    headline={content.headline}
                    subtitle={content.subtitle}
                    length={Number(content.length)}
                    showPassword
                    showNumpad
                  />
                ) : null)}
              {content.name === 'Catalog' && (
                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                  {content.selection === 'Catalog item' &&
                  content.variant === 'Single'
                    ? catalogItemsSingle.map((item: ActionItem) => {
                        return (
                          <CatalogItem
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            text={item.text}
                            subtitles={
                              item.selection === 'Two lines'
                                ? [item.secondLine ? item.secondLine : '']
                                : item.selection === 'Three lines'
                                ? [
                                    item.secondLine ? item.secondLine : '',
                                    item.thirdLine ? item.thirdLine : ''
                                  ]
                                : []
                            }
                            image={item.image}
                            unit={item.unit}
                            itemId={item.id}
                            dispatch={dispatchSingle}
                            variant="single"
                            selected={item.selected as boolean}
                          />
                        )
                      })
                    : catalogItemsMulti.map((item: ActionItem) => {
                        return (
                          <CatalogItem
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            text={item.text}
                            subtitles={
                              item.selection === 'Two lines'
                                ? [item.secondLine ? item.secondLine : '']
                                : item.selection === 'Three lines'
                                ? [
                                    item.secondLine ? item.secondLine : '',
                                    item.thirdLine ? item.thirdLine : ''
                                  ]
                                : []
                            }
                            image={item.image}
                            unit={item.unit}
                            itemId={item.id}
                            dispatch={dispatchMulti}
                            variant="multi"
                            selected={item.quantity! > 0}
                            quantity={item.quantity}
                          />
                        )
                      })}
                </div>
              )}
              {content.name === 'Data display' && (
                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                  {content.selection === 'List' &&
                    content.actionItems?.map((item) => {
                      return (
                        <ListItem
                          key={item.id}
                          style={{ display: item.visible ? '' : 'none' }}
                          text={item.label}
                          subtitles={
                            item.selection === 'Two lines'
                              ? [item.secondLine ? item.secondLine : '']
                              : item.selection === 'Three lines'
                              ? [
                                  item.secondLine ? item.secondLine : '',
                                  item.thirdLine ? item.thirdLine : ''
                                ]
                              : []
                          }
                          avatar={
                            item.avatarImage || item.avatarText
                              ? {
                                  image: item.avatarImage,
                                  text: item.avatarText
                                }
                              : undefined
                          }
                          image={item.image}
                          imageRectangle={item.imageRectangle}
                          icon={item.icon}
                          metaIcon={item.metaIcon}
                          metaText={item.metaText}
                          hightlightMeta={item.highlightMeta}
                        />
                      )
                    })}
                </div>
              )}
              {content.name === 'Action area' && (
                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                  {content.selection === 'Groups' &&
                    content.actionItems?.map((item) => {
                      if (content.typeRadio) {
                        if (content.typeButton) {
                          return (
                            <Button
                              key={item.id}
                              style={{ display: item.visible ? '' : 'none' }}
                              role="radio"
                              checked={radioValue === item.value}
                              value={item.value}
                              icon={content.showIcon ? item.icon : ''}
                              label={item.label}
                              metaText={content.showMeta ? item.metaText : ''}
                              onClick={() => handleRadioClick(item.value)}
                            />
                          )
                        } else {
                          return (
                            <Radio
                              key={item.id}
                              style={{ display: item.visible ? '' : 'none' }}
                              checked={radioValue === item.value}
                              value={item.value}
                              label={item.firstLine}
                              subtitles={
                                item.selection === 'Two lines'
                                  ? [item.secondLine ? item.secondLine : '']
                                  : item.selection === 'Three lines'
                                  ? [
                                      item.secondLine ? item.secondLine : '',
                                      item.thirdLine ? item.thirdLine : ''
                                    ]
                                  : []
                              }
                              onChange={() => handleRadioClick(item.value)}
                            />
                          )
                        }
                      } else {
                        if (content.typeButton) {
                          return (
                            <Button
                              key={item.id}
                              style={{ display: item.visible ? '' : 'none' }}
                              role="checkbox"
                              // @ts-ignore
                              checked={checkboxValue.indexOf(item.value) !== -1}
                              value={item.value}
                              icon={content.showIcon ? item.icon : ''}
                              label={item.label}
                              metaText={content.showMeta ? item.metaText : ''}
                              onClick={() => handleCheckboxClick(item.value)}
                            />
                          )
                        } else {
                          return (
                            <Checkbox
                              key={item.id}
                              style={{ display: item.visible ? '' : 'none' }}
                              // @ts-ignore
                              checked={checkboxValue.indexOf(item.value) !== -1}
                              value={item.value}
                              label={item.firstLine}
                              subtitles={
                                item.selection === 'Two lines'
                                  ? [item.secondLine ? item.secondLine : '']
                                  : item.selection === 'Three lines'
                                  ? [
                                      item.secondLine ? item.secondLine : '',
                                      item.thirdLine ? item.thirdLine : ''
                                    ]
                                  : []
                              }
                              onChange={() => handleCheckboxClick(item.value)}
                            />
                          )
                        }
                      }
                    })}
                  {content.selection === 'Inputs' &&
                    content.actionItems?.map((item) => {
                      if (item.type === 'Checkbox') {
                        return (
                          <Checkbox
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            // @ts-ignore
                            checked={checkboxValue.indexOf(item.value) !== -1}
                            value={item.value}
                            label={item.firstLine}
                            subtitles={
                              item.selection === 'Two lines'
                                ? [item.secondLine ? item.secondLine : '']
                                : item.selection === 'Three lines'
                                ? [
                                    item.secondLine ? item.secondLine : '',
                                    item.thirdLine ? item.thirdLine : ''
                                  ]
                                : []
                            }
                            onChange={() => handleCheckboxClick(item.value)}
                          />
                        )
                      }
                      if (item.type === 'Text field') {
                        return (
                          <TextField
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            label={item.label}
                            placeholder={item.placeholder}
                            helperText={item.helperText}
                            prefix={{
                              text: item.prefix,
                              icon: item.prefixIcon
                            }}
                            suffix={{
                              text: item.suffix,
                              icon: item.suffixIcon
                            }}
                            rows={Number(item.rows)}
                            fullWidth
                          />
                        )
                      }
                      if (item.type === 'Select') {
                        return (
                          <Select
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            label={item.label}
                            helperText={item.helperText}
                            value={
                              selectValue === item.value ? selectValue : ''
                            }
                            onChange={handleSelect}
                            options={[
                              {
                                label: item.option as string,
                                value: item.value
                              }
                            ]}
                          />
                        )
                      }
                      if (item.type === 'Switch') {
                        return (
                          <Switch
                            key={item.id}
                            style={{ display: item.visible ? '' : 'none' }}
                            // @ts-ignore
                            checked={checkboxValue.indexOf(item.value) !== -1}
                            value={item.value}
                            label={item.firstLine}
                            subtitles={
                              item.selection === 'Two lines'
                                ? [item.secondLine ? item.secondLine : '']
                                : item.selection === 'Three lines'
                                ? [
                                    item.secondLine ? item.secondLine : '',
                                    item.thirdLine ? item.thirdLine : ''
                                  ]
                                : []
                            }
                            onChange={() => handleCheckboxClick(item.value)}
                          />
                        )
                      }
                      return null
                    })}
                </div>
              )}
            </div>
          )
        })}
        {footer.type === 'SpeedDial' ? (
          <>
            <div style={{ paddingTop: '102px' }} />
            <div
              style={{
                position: 'absolute',
                width: '100%',
                bottom: '0px',
                height: '100px',
                display: footer.visible ? '' : 'none'
              }}
              className={clsx({
                [classes.outlineHover]: modifyId !== footer.id && !preview,
                [classes.outlineClicked]: modifyId === footer.id && !preview
              })}
              onClick={() => handleClickComponent(footer.id, 'Footer')}
            >
              <SpeedDial
                style={{
                  display: !preview ? '' : 'none'
                }}
                className={classes.speedDial}
                actions={speedDialActions}
              />
            </div>
            <div className={classes.footer}>
              <Whitelabel />
            </div>
          </>
        ) : footer.type === 'Tabs' ? (
          <>
            <div
              className={clsx({
                [classes.footerSafeArea]: !footer.visible,
                [classes.footerVisible]: footer.visible
              })}
            />
            <div className={classes.footer}>
              <div
                style={{ display: footer.visible ? '' : 'none' }}
                className={clsx({
                  [classes.outlineHover]: modifyId !== footer.id && !preview,
                  [classes.outlineClicked]: modifyId === footer.id && !preview
                })}
                onClick={() => handleClickComponent(footer.id, 'Footer')}
              >
                <BottomNavigation
                  value={handleChangeNavigation()}
                  actions={bottomNavigationActions.slice(
                    0,
                    Number(footer.tabAmount)
                  )}
                />
              </div>
              <Whitelabel />
            </div>
          </>
        ) : (
          <>
            <div
              className={clsx({
                [classes.footerSafeArea]: !footer.visible,
                [classes.footerVisible]: footer.visible
              })}
            />
            <div
              style={{ display: footer.visible ? '' : 'none' }}
              className={clsx(classes.bottomBar, {
                [classes.outlineHover]: modifyId !== footer.id && !preview,
                [classes.outlineClicked]: modifyId === footer.id && !preview
              })}
              onClick={() => handleClickComponent(footer.id, 'Footer')}
            >
              <BottomBar components={bottomBarButtons} />
            </div>
            <div className={classes.footer}>
              <Whitelabel />
            </div>
          </>
        )}
      </div>
    )
  }
)

export default PlayComponent
