import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const isLogin = () => {
  return localStorage.getItem('token')
}

const PrivateRoute = ({
  component: Component,
  exact,
  path,
  ...rest
}: {
  component: any
  exact: boolean
  path: string
}) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
}

export default PrivateRoute
