import { Dispatch } from 'redux'
import {
  ComponentSettingsActionTypes,
  ComponentSettingsState,
  AppActions,
  Component,
  ComponentSettingStateKey,
  ContentItem,
  ContentTableItem,
  ComponentKey,
  HeaderAndFooterKey,
  HeaderFooterItem,
  HeaderFooterItemKeys,
  HeaderFooterTableKeys,
  Content,
  ContentKey,
  ContentTableKey,
  ActionItem,
  ActionItemKey
} from 'types/types'

export const LOAD_SETTINGS = 'LOAD_SETTINGS'
export const CHANGE_SETTING = 'CHANGE_SETTING'
export const ADD_NEW_COMPONENT = 'ADD_NEW_COMPONENT'
export const EDIT_COMPONENT = 'EDIT_COMPONENT'
export const DELETE_COMPONENT = 'DELETE_COMPONENT'
export const CHANGE_VALUE_CONTENT = 'CHANGE_VALUE'
export const CHANGE_VALUE_HEADER_FOOTER = 'CHANGE_VALUE_HEADER_FOOTER'
export const ADD_HEADER_FOOTER_TABLE_VALUE = 'ADD_HEADER_FOOTER_TABLE_VALUE'
export const REMOVE_HEADER_FOOTER_TABLE_VALUE =
  'REMOVE_HEADER_FOOTER_TABLE_VALUE'
export const EDIT_HEADER_FOOTER_TABLE_VALUE = 'EDIT_HEADER_FOOTER_TABLE_VALUE'
export const SWAP_COMPONENTS = 'SWAP_COMPONENTS'
export const SWAP_CONTENT = 'SWAP_CONTENT'
export const SWAP_CONTENT_TABLE = 'SWAP_CONTENT_TABLE'
export const ADD_NEW_CONTENT = 'ADD_NEW_CONTENT'
export const REMOVE_CONTENT = 'REMOVE_CONTENT'
export const ADD_CONTENT_TABLE_VALUE = 'ADD_CONTENT_TABLE_VALUE'
export const EDIT_CONTENT_TABLE_VALUE = 'EDIT_CONTENT_TABLE_VALUE'
export const REMOVE_CONTENT_TABLE_VALUE = 'REMOVE_CONTENT_TABLE_VALUE'
export const EDIT_CONTENT_TABLE_VALUE_ALL = 'EDIT_CONTENT_TABLE_VALUE_ALL'

export const loadSettings = (value: ComponentSettingsState, id: string) => (
  dispatch: Dispatch<AppActions>
): ComponentSettingsActionTypes => {
  return dispatch({
    type: LOAD_SETTINGS,
    payload: { value, id }
  })
}

export const changeSetting = (
  value: string,
  stateKey: ComponentSettingStateKey
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: CHANGE_SETTING,
    payload: { value, stateKey }
  })
}

export const addNewComponent = (
  value: Component,
  leftId?: string,
  topId?: string,
  pathLeftId?: string,
  order?: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: ADD_NEW_COMPONENT,
    payload: { value, leftId, topId, pathLeftId, order }
  })
}

export const editComponent = (
  value: string | number,
  stateKey: 'name' | 'svg' | 'height' | 'width' | 'positionX' | 'positionY',
  id: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: EDIT_COMPONENT,
    payload: { value, stateKey, id }
  })
}

export const deleteComponent = (id: string) => (
  dispatch: Dispatch<AppActions>
): ComponentSettingsActionTypes => {
  return dispatch({
    type: DELETE_COMPONENT,
    payload: { id }
  })
}

export const changeValueHeaderFooter = (
  value: string | boolean | number,
  stateKey: ComponentKey,
  stateAdditionalKey: HeaderAndFooterKey,
  id: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: CHANGE_VALUE_HEADER_FOOTER,
    payload: { value, stateKey, stateAdditionalKey, id }
  })
}

export const addHeaderFooterTableValue = (
  value: HeaderFooterItem,
  stateKey: ComponentKey,
  stateAdditionalKey: HeaderFooterTableKeys,
  id: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: ADD_HEADER_FOOTER_TABLE_VALUE,
    payload: { value, stateKey, stateAdditionalKey, id }
  })
}

export const editHeaderFooterTableValue = (
  value: string,
  itemKey: HeaderFooterItemKeys,
  stateKey: ComponentKey,
  stateAdditionalKey: HeaderFooterTableKeys,
  modifyId: string,
  itemId: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: EDIT_HEADER_FOOTER_TABLE_VALUE,
    payload: { value, itemKey, stateKey, stateAdditionalKey, modifyId, itemId }
  })
}

export const removeHeaderFooterTableValue = (
  stateKey: ComponentKey,
  stateAdditionalKey: HeaderFooterTableKeys,
  modifyId: string,
  itemId: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: REMOVE_HEADER_FOOTER_TABLE_VALUE,
    payload: { stateKey, stateAdditionalKey, modifyId, itemId }
  })
}

export const changeValueContent = (
  value: string | boolean | number,
  stateKey: ContentKey,
  id: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: CHANGE_VALUE_CONTENT,
    payload: { value, stateKey, id }
  })
}

export const swapComponents = (
  component: Component,
  componentIndex: number,
  swapComponent: Component,
  swapComponentIndex: number
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: SWAP_COMPONENTS,
    payload: {
      component,
      componentIndex,
      swapComponent,
      swapComponentIndex
    }
  })
}

export const swapContent = (contents: Content[], componentId: string) => (
  dispatch: Dispatch<AppActions>
): ComponentSettingsActionTypes => {
  return dispatch({
    type: SWAP_CONTENT,
    payload: { contents, componentId }
  })
}

export const swapContentTable = (
  contentTables: ActionItem[],
  componentId: string,
  contentId: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: SWAP_CONTENT_TABLE,
    payload: { contentTables, componentId, contentId }
  })
}

export const addNewContent = (value: ContentItem, id: string) => (
  dispatch: Dispatch<AppActions>
): ComponentSettingsActionTypes => {
  return dispatch({
    type: ADD_NEW_CONTENT,
    payload: { value, id }
  })
}

export const removeContent = (id: string) => (
  dispatch: Dispatch<AppActions>
): ComponentSettingsActionTypes => {
  return dispatch({
    type: REMOVE_CONTENT,
    payload: { id }
  })
}

export const addContentTableValue = (
  value: ContentTableItem,
  stateKey: ContentTableKey,
  id: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: ADD_CONTENT_TABLE_VALUE,
    payload: { value, stateKey, id }
  })
}

export const editContentTableValue = (
  value: string | boolean | number,
  stateKey: ContentTableKey,
  stateAdditionalKey: ActionItemKey,
  contentId: string,
  contentTableId: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: EDIT_CONTENT_TABLE_VALUE,
    payload: { value, stateKey, stateAdditionalKey, contentId, contentTableId }
  })
}

export const removeContentTableValue = (
  stateKey: ContentTableKey,
  contentId: string,
  contentTableId: string
) => (dispatch: Dispatch<AppActions>): ComponentSettingsActionTypes => {
  return dispatch({
    type: REMOVE_CONTENT_TABLE_VALUE,
    payload: { stateKey, contentId, contentTableId }
  })
}
