/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as LoginIcon } from 'assets/login.svg'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, Whitelabel } from 'uniqore-components'
import { IconButton, InputAdornment, Snackbar } from '@material-ui/core'
import { Close, Visibility, VisibilityOff } from '@material-ui/icons'
import { useMutation } from '@apollo/client'
import { gql } from 'apollo-boost'
import { useHistory } from 'react-router-dom'
import PublicClient from 'api/public'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'types/types'
import SpinnerLogoWhite from 'assets/spinner-logo-white-60.svg'
import { passwordResetDone } from 'store/actions/other'

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#002C38',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputWrapper: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: 32
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    bottom: '145px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  reset: {
    color: theme.palette.grey[400],
    textDecoration: 'underline',
    '& .MuiButton-text': {
      color: theme.palette.grey[400],
      fontWeight: 400
    }
  },
  snackbar: {
    background: '#1E88E5'
  }
}))

export const loginQuery = gql`
  mutation loginOrgUser($auth: ID!, $email: String!, $password: String!) {
    loginOrgUser(auth: $auth, email: $email, password: $password) {
      token
      error
      orgUserId
    }
  }
`

const Login: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  let history = useHistory()
  const { passwordResetDone: passDone } = useSelector(
    (state: AppState) => state.other
  )

  const handleClick = () => {
    setShowPassword((prev) => !prev)
  }

  const [login, { loading: mutationLoading }] = useMutation(loginQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.loginOrgUser.error) {
        setError(mutationResult.loginOrgUser.error)
      } else if (mutationResult.loginOrgUser.token) {
        localStorage.setItem('token', mutationResult.loginOrgUser.token)
        localStorage.setItem('userId', mutationResult.loginOrgUser.orgUserId)
        history.push('/')
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    login({
      variables: {
        email: email,
        password: password,
        auth: process.env.REACT_APP_ORG_AUTH_ID
      }
    })
    event.preventDefault()
  }

  const handleClose = () => {
    dispatch(passwordResetDone(false))
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={passDone}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Hooray! Password changed."
        ContentProps={{
          classes: {
            root: classes.snackbar
          }
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <div className={classes.content}>
        <form
          style={{
            textAlign: 'center'
          }}
          onSubmit={submit}
        >
          <div style={{ paddingBottom: '8px' }}>
            <LoginIcon />
          </div>
          <Typography
            style={{ color: '#FAFAFA', paddingBottom: '16px' }}
            variant="h6"
          >
            Don’t Compromise your Vision
          </Typography>
          <div className={classes.inputWrapper}>
            <TextField
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              placeholder="Email"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              placeholder="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!error}
              helperText={error}
            />
          </div>
          <div>
            {mutationLoading && (
              <object
                className={classes.spinnerOnButton}
                data={SpinnerLogoWhite}
              />
            )}
            <Button
              style={{ paddingTop: '16px' }}
              label="LOGIN"
              color="secondary"
              type="submit"
              variant="contained"
              disabled={mutationLoading}
            />
          </div>
          <Button
            variant="text"
            className={classes.reset}
            label="Forgot my password"
            onClick={() => {
              history.push('/reset-password')
            }}
          />
        </form>
      </div>
      <Whitelabel style={{ position: 'absolute', bottom: 0, width: '100%' }} />
    </>
  )
}

export default Login
