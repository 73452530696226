import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Selector from 'components/common/Selector'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import { AppState, Content, ActionItem, ActionItemKey } from 'types/types'
import {
  editContentTableValue,
  removeContentTableValue
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  },
  noContent: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  }
}))

interface Props {
  content: Content
  itemId: string
  setView: Dispatch<SetStateAction<string>>
}

const TypeListSettings: React.FC<Props> = ({ content, itemId, setView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [styleExpand, setStyleExpand] = useState<boolean>(false)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [item, setItem] = useState<ActionItem | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)

  const [label, setLabel] = useState<string | undefined>('')
  const [secondLine, setSecondLine] = useState<string | undefined>('')
  const [thirdLine, setThirdLine] = useState<string | undefined>('')
  const [avatarImage, setAvatarImage] = useState<string | undefined>('')
  const [avatarText, setAvatarText] = useState<string | undefined>('')
  const [image, setImage] = useState<string | undefined>('')
  const [icon, setIcon] = useState<string | undefined>('')
  const [metaIcon, setMetaIcon] = useState<string | undefined>('')
  const [metaText, setMetaText] = useState<string | undefined>('')

  useEffect(() => {
    const findItem = content.actionItems?.find((c) => c.id === itemId)
    if (findItem) {
      setItem(content.actionItems?.find((c) => c.id === itemId))
      setLabel(findItem.label)
      setSecondLine(findItem.secondLine)
      setThirdLine(findItem.thirdLine)
      setAvatarImage(findItem.avatarImage)
      setAvatarText(findItem.avatarText)
      setImage(findItem.image)
      setIcon(findItem.icon)
      setMetaIcon(findItem.metaIcon)
      setMetaText(findItem.metaText)
    }
  }, [itemId, content])

  if (!item) {
    return null
  }

  const handleChangeInput = (
    value: string,
    type: ActionItemKey,
    id: string
  ) => {
    if (type === 'label') setLabel(value)
    if (type === 'secondLine') setSecondLine(value)
    if (type === 'thirdLine') setThirdLine(value)
    if (type === 'avatarImage') setAvatarImage(value)
    if (type === 'avatarText') setAvatarText(value)
    if (type === 'image') setImage(value)
    if (type === 'icon') setIcon(value)
    if (type === 'metaIcon') setMetaIcon(value)
    if (type === 'metaText') setMetaText(value)
    dispatch(editContentTableValue(value, 'actionItems', type, modifyId, id))
  }

  const deleteComponent = (id: string) => {
    dispatch(removeContentTableValue('actionItems', modifyId, id))
    setView('')
  }

  return (
    <>
      <div className={classes.selectContainer}>
        <Input
          label="Content"
          options={[
            { value: 'One line', label: 'One line' },
            { value: 'Two lines', label: 'Two lines' },
            { value: 'Three lines', label: 'Three lines' }
          ]}
          value={item.selection}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeInput(e.target.value, 'selection', item.id)
          }
        />
      </div>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="First line"
              value={label}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'label', item.id)
              }
            />
          </div>
          {item.selection === 'Two lines' ||
          item.selection === 'Three lines' ? (
            <div className={classes.inputPadding}>
              <Input
                label="Second line"
                value={secondLine}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'secondLine', item.id)
                }
              />
            </div>
          ) : null}
          {item.selection === 'Three lines' && (
            <div className={classes.inputPadding}>
              <Input
                label="Third line"
                value={thirdLine}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'thirdLine', item.id)
                }
              />
            </div>
          )}
          <div className={classes.inputPadding}>
            <Input
              label="Image"
              placeholder="Image"
              prefixIcon="Image"
              value={image}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'image', item.id)
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Avatar image"
              placeholder="Avatar image"
              prefixIcon="AccountCircle"
              value={avatarImage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'avatarImage', item.id)
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Avatar text"
              placeholder="Avatar text"
              prefixIcon="AccountCircleOutlined"
              value={avatarText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'avatarText', item.id)
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Icon"
              placeholder="Icon"
              prefixIcon="CropOriginal"
              value={icon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'icon', item.id)
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Meta icon (overrides meta text)"
              placeholder="Meta icon"
              prefixIcon="WallpaperOutlined"
              value={metaIcon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'metaIcon', item.id)
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Meta text"
              placeholder="Meta text"
              value={metaText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'metaText', item.id)
              }
            />
          </div>
        </div>
      </Collapse>
      <CollapseList
        expanded={styleExpand}
        setExpanded={setStyleExpand}
        primary="Style"
      />
      <Collapse in={styleExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          {content.selection === 'List' ? (
            <>
              <div className={classes.inputPadding}>
                <Selector
                  title="Image rectangle"
                  disable={!image}
                  checked={item.imageRectangle}
                  onChange={() =>
                    dispatch(
                      editContentTableValue(
                        !item.imageRectangle,
                        'actionItems',
                        'imageRectangle',
                        modifyId,
                        item.id
                      )
                    )
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Selector
                  title="Highlight meta"
                  disable={!metaIcon && !metaText}
                  checked={item.highlightMeta}
                  onChange={() =>
                    dispatch(
                      editContentTableValue(
                        !item.highlightMeta,
                        'actionItems',
                        'highlightMeta',
                        modifyId,
                        item.id
                      )
                    )
                  }
                />
              </div>
            </>
          ) : (
            <Typography variant="body2" className={classes.noContent}>
              No styles to show
            </Typography>
          )}
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={() => deleteComponent(item.id)}
          />
        </div>
      </Collapse>
    </>
  )
}

export default TypeListSettings
