import {
  Component,
  FeedItem,
  HeadingItem,
  SmallPrintItem,
  ButtonItem,
  ListItem,
  ActionAreaItem,
  DataDisplayItem,
  Catalog,
  CatalogItem,
  ActionItem,
  SpeedDialItem,
  SvgItem,
  LoginItem,
  DatePickerItem,
  ProductViewItem,
  OnBoardingItem,
  ErrorPageItem,
  SearchDialogItem
} from 'types/types'

export const componentStatus: Component = {
  id: '',
  name: '',
  type: 'Status',
  order: '',
  positionX: 0,
  positionY: 0,
  height: '',
  width: '',
  parentId: '',
  children: [],
  header: {
    id: '',
    visible: true,
    menu: true,
    text: '',
    mainAction: '',
    actions: ['HelpOutline'],
    border: false,
    centerText: false
  },
  contents: [
    {
      id: '',
      nameIcon: 'Image',
      name: 'Status',
      selection: '',
      visible: true,
      iconVisible: true,
      textVisible: true,
      ctaVisible: true,
      textSelection: 'Headline + Subtitle',
      ctaSelection: 'CTA + secondary CTA',
      icon: 'Event',
      iconSize: '80',
      image: '',
      video: '',
      svg: '',
      noticeText: 'Notice text',
      headline: "What's next",
      title: 'Title',
      subtitle: "Description of what's next",
      body: '',
      ctaIcon: 'Done',
      label: 'Subscribe',
      ctaSecondaryIcon: 'Done',
      secondaryLabel: 'Summary'
    },
    {
      id: '',
      nameIcon: 'DynamicFeed',
      name: 'Feed',
      selection: 'Feed',
      visible: true,
      feedTitle: true,
      feedTitleText: 'Recent actions',
      feedUser: true,
      feedDate: true,
      feedSubtitle: 'Additional information',
      feedText:
        'This is a short description of what just happened. Text can include shortcodes such as time and user details.'
    }
  ],
  footer: {
    id: '',
    visible: true,
    speedDialActions: []
  }
}

export const componentPlay: Component = {
  id: '',
  name: '',
  type: 'Play',
  order: '',
  positionX: 0,
  positionY: 0,
  height: '',
  width: '',
  parentId: '',
  // shows the first Play path's id
  pathParentId: '',
  // if this Play component has components 'under', their id's will be in children array
  children: [],
  // if this Play component is the first path screen, all the following Plays/paths id's will be in paths array
  paths: [],
  header: {
    id: '',
    visible: true,
    text: 'Previous screen',
    mainAction: 'ArrowBack',
    actions: ['HelpOutline'],
    border: true,
    centerText: false
  },
  contents: [],
  footer: {
    id: '',
    visible: true,
    backLabel: 'Previous',
    backIcon: '',
    nextLabel: 'Forward',
    nextIcon: ''
  }
}

export const componentView: Component = {
  id: '',
  name: '',
  type: 'View',
  order: '',
  positionX: 0,
  positionY: 0,
  height: '',
  width: '',
  parentId: '',
  header: {
    id: '',
    visible: true,
    text: 'Go back to X',
    mainAction: 'ArrowBack',
    actions: ['HelpOutline'],
    border: true,
    centerText: false
  },
  contents: [],
  footer: {
    id: '',
    visible: true,
    tabAmount: '1',
    firstTabIcon: 'AddCircleOutline',
    firstTabLabel: 'Tab 1',
    secondTabIcon: 'AddCircleOutline',
    secondTabLabel: 'Tab 2',
    thirdTabIcon: 'AddCircleOutline',
    thirdTabLabel: 'Tab 3',
    fourthTabIcon: 'AddCircleOutline',
    fourthTabLabel: 'Tab 4',
    fifthTabIcon: 'AddCircleOutline',
    fifthTabLabel: 'Tab 5'
  }
}

export const datePickerItem: DatePickerItem = {
  id: '',
  nameIcon: 'Today',
  name: 'Date picker',
  selection: 'Calendar',
  visible: true,
  languageFi: true,
  disablePast: true,
  disableToolbar: false,
  label: 'Label'
}

export const feedItem: FeedItem = {
  id: '',
  nameIcon: 'DynamicFeed',
  name: 'Feed',
  selection: 'Feed',
  visible: true,
  feedTitle: false,
  feedTitleText: 'Recent actions',
  feedUser: true,
  feedDate: true,
  feedSubtitle: 'Additional information',
  feedText:
    'This is a short description of what just happened. Text can include shortcodes such as time and user details.'
}

export const headingItem: HeadingItem = {
  id: '',
  nameIcon: 'TextFormat',
  name: 'Heading',
  selection: 'Title + Body',
  visible: true,
  title: 'Title',
  textLeftAlign: false,
  body: 'Body'
}

export const smallPrintItem: SmallPrintItem = {
  id: '',
  nameIcon: 'Info',
  name: 'Small print',
  selection: '',
  visible: true,
  text: 'Some additional information',
  textLeftAlign: false
}

export const buttonItem: ButtonItem = {
  id: '',
  nameIcon: 'PlayCircleFilled',
  name: 'CTA Button',
  selection: 'Contained',
  visible: true,
  icon: 'Done',
  label: 'CONFIRM',
  colorSecondary: true
}

export const listItem: ListItem = {
  id: '',
  nameIcon: 'List',
  name: 'List item',
  selection: 'Two lines',
  visible: true,
  actionType: 'Icon',
  label: 'First line',
  secondLine: 'Second line',
  thirdLine: 'Third line',
  avatarImage: '',
  avatarText: '',
  image: '',
  imageRectangle: false,
  icon: 'AddCircleOutline',
  metaIcon: '',
  metaText: '',
  highlightMeta: false
}

export const catalogItem: CatalogItem = {
  id: '',
  name: 'Catalog item',
  selection: 'Two lines',
  visible: true,
  text: 'Product name',
  secondLine: 'Price',
  thirdLine: 'Third line',
  image: '',
  unit: 'kpl',
  quantity: 0,
  minimumQuantity: 0
}

export const actionAreaItem: ActionAreaItem = {
  id: '',
  nameIcon: 'RadioButtonChecked',
  name: 'Action area',
  selection: 'Groups',
  visible: true,
  typeRadio: true,
  typeButton: true,
  showIcon: true,
  showMeta: true,
  actionItems: []
}

export const dataDisplayItem: DataDisplayItem = {
  id: '',
  nameIcon: 'Storage',
  name: 'Data display',
  selection: 'List',
  visible: true,
  actionItems: []
}

export const catalog: Catalog = {
  id: '',
  nameIcon: 'ViewStream',
  name: 'Catalog',
  selection: 'Catalog item',
  visible: true,
  variant: 'Single',
  actionItems: []
}

export const actionItem: ActionItem = {
  id: '',
  value: '',
  selection: 'Two lines',
  type: 'Text field',
  typeRadio: false,
  typeButton: false,
  visible: true,
  showIcon: true,
  showMeta: true,
  icon: 'Done',
  label: 'Primary',
  metaText: 'Meta',
  firstLine: 'First line',
  secondLine: 'Second line',
  thirdLine: 'Third line',
  placeholder: '',
  helperText: '',
  prefix: '',
  prefixIcon: '',
  suffix: '',
  suffixIcon: '',
  maxLength: 0,
  rows: 0,
  option: 'Example'
}

export const speedDialItem: SpeedDialItem = {
  id: '',
  label: 'Action',
  icon: 'Send'
}

export const svgItem: SvgItem = {
  id: '',
  nameIcon: 'ImageAspectRatio',
  name: 'Custom Svg',
  visible: true,
  svg: ''
}

export const loginItem: LoginItem = {
  id: '',
  nameIcon: 'LockOpen',
  name: 'Login',
  selection: 'Basic',
  visible: true,
  headline: 'Kirjaudu sisään',
  subtitle: '',
  primaryTextField: 'Sähköpostiosoite',
  secondaryTextField: 'Salasana',
  primaryPrefix: 'MailOutline',
  secondaryPrefix: 'LockOutlined',
  primarySuffix: '',
  secondarySuffix: 'Visibility',
  label: 'Kirjaudu sisään',
  secondaryLabel: 'Salasana unohtunut?',
  length: 6
}

export const productViewItem: ProductViewItem = {
  id: '',
  nameIcon: 'FeaturedPlayList',
  name: 'Product View',
  selection: '',
  visible: true,
  title: 'Title',
  subtitle: 'Description',
  price: 'Price: 10,00€',
  showRating: true,
  totalRatings: 0
}

export const onboardingItem: OnBoardingItem = {
  id: '',
  nameIcon: 'SwitchCamera',
  name: 'Onboarding',
  selection: '',
  visible: true,
  image:
    'https://images.theconversation.com/files/125391/original/image-20160606-13080-s7o3qu.jpg?ixlib=rb-1.1.0&rect=273%2C0%2C2639%2C1379&q=45&auto=format&w=926&fit=clip',
  backgroundImage: '',
  title: 'Title',
  subtitle: 'Subtitle',
  label: 'GET STARTED',
  secondaryLabel: 'SEE DETAILS',
  ctaDirection: 'column',
  color: '',
  backgroundColor: ''
}

export const errorPageItem: ErrorPageItem = {
  id: '',
  nameIcon: 'ErrorOutline',
  name: 'Error Page',
  selection: '',
  visible: true,
  title: 'Oops! Something went wrong.',
  subtitle: 'If you can reach out to our support, please tell them following:',
  error: '404: Page not found',
  link: ''
}

export const searchDialogItem: SearchDialogItem = {
  id: '',
  nameIcon: 'Search',
  name: 'Search Dialog',
  selection: '',
  visible: true,
  inputPlaceholder: 'Search...',
  resultsHeading: 'Results',
  resultsPlaceholder: 'Enter a search term',
  label: '',
  primaryColor: true,
  primaryButton: false
}

export const changeArrayOrder = (array: any, from: number, to: number) => {
  return array.splice(to, 0, array.splice(from, 1)[0])
}
