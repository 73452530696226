import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import Selector from 'components/common/Selector'
import StepList from 'components/common/StepList'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const SmallPrintSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [content, setContent] = useState<Content | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [text, setText] = useState<string | undefined>('')

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setText(content.text)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeSelector = (
    value: boolean | undefined,
    type: ContentKey
  ) => {
    dispatch(changeValueContent(!value, type, modifyId))
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'text') setText(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  return (
    <>
      <div className={classes.collapseItems}>
        <div className={classes.inputPadding}>
          <Selector
            title="Align text"
            checked={content.textLeftAlign}
            onChange={() =>
              handleChangeSelector(content.textLeftAlign, 'textLeftAlign')
            }
          />
        </div>
        <div className={classes.inputPadding}>
          <Input
            label="Text"
            value={text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'text')
            }
          />
        </div>
      </div>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default SmallPrintSettings
