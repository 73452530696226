import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const LoginSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )

  const [headline, setHeadline] = useState<string | undefined>('')
  const [subtitle, setSubtitle] = useState<string | undefined>('')
  const [primaryTextField, setPrimaryTextField] = useState<string | undefined>(
    ''
  )
  const [secondaryTextField, setSecondaryTextField] = useState<
    string | undefined
  >('')
  const [primaryPrefix, setPrimaryPrefix] = useState<string | undefined>('')
  const [primarySuffix, setPrimarySuffix] = useState<string | undefined>('')
  const [secondaryPrefix, setSecondaryPrefix] = useState<string | undefined>('')
  const [secondarySuffix, setSecondarySuffix] = useState<string | undefined>('')
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState<
    string | undefined
  >('')
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState<
    string | undefined
  >('')
  const [otpLength, setOtpLength] = useState<number | undefined>(0)

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setHeadline(content.headline)
          setSubtitle(content.subtitle)
          setPrimaryTextField(content.primaryTextField)
          setSecondaryTextField(content.secondaryTextField)
          setPrimaryPrefix(content.primaryPrefix)
          setPrimarySuffix(content.primarySuffix)
          setSecondaryPrefix(content.secondaryPrefix)
          setSecondarySuffix(content.secondarySuffix)
          setPrimaryButtonLabel(content.label)
          setSecondaryButtonLabel(content.secondaryLabel)
          setOtpLength(content.length)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'headline') setHeadline(value)
    if (type === 'subtitle') setSubtitle(value)
    if (type === 'primaryTextField') setPrimaryTextField(value)
    if (type === 'secondaryTextField') setSecondaryTextField(value)
    if (type === 'primaryPrefix') setPrimaryPrefix(value)
    if (type === 'primarySuffix') setPrimarySuffix(value)
    if (type === 'secondaryPrefix') setSecondaryPrefix(value)
    if (type === 'secondarySuffix') setSecondarySuffix(value)
    if (type === 'label') setPrimaryButtonLabel(value)
    if (type === 'secondaryLabel') setSecondaryButtonLabel(value)
    if (type === 'length') setOtpLength(Number(value))
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  return (
    <>
      <div className={classes.selectContainer}>
        <Input
          label="Type"
          options={[
            { value: 'Basic', label: 'Basic' },
            { value: 'OTP', label: 'OTP' }
          ]}
          value={content.selection}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeInput(e.target.value, 'selection')
          }
        />
      </div>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="Headline"
              name="headline"
              value={headline}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'headline')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Subtitle"
              name="subtitle"
              value={subtitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'subtitle')
              }
            />
          </div>
          {content.selection === 'Basic' && (
            <>
              <div className={classes.inputPadding}>
                <Input
                  label="First input text"
                  name="First input text"
                  prefixIcon="TextFields"
                  value={primaryTextField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'primaryTextField')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="First input prefix"
                  placeholder="First input prefix"
                  name="First input prefix"
                  prefixIcon="Image"
                  value={primaryPrefix}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'primaryPrefix')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="First input suffix"
                  placeholder="First input suffix"
                  name="First input suffix"
                  prefixIcon="ImageOutlined"
                  value={primarySuffix}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'primarySuffix')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Second input text"
                  name="Second input text"
                  prefixIcon="TextFields"
                  value={secondaryTextField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'secondaryTextField')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Second input prefix"
                  placeholder="Second input prefix"
                  name="Second input prefix"
                  prefixIcon="Image"
                  value={secondaryPrefix}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'secondaryPrefix')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Second input suffix"
                  placeholder="Second input suffix"
                  name="Second input suffix"
                  prefixIcon="ImageOutlined"
                  value={secondarySuffix}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'secondarySuffix')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="First button label"
                  name="First button"
                  value={primaryButtonLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'label')
                  }
                />
              </div>
              <div className={classes.inputPadding}>
                <Input
                  label="Second button label"
                  name="Second button"
                  value={secondaryButtonLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'secondaryLabel')
                  }
                />
              </div>
            </>
          )}
          {content.selection === 'OTP' && (
            <>
              <div className={classes.inputPadding}>
                <Input
                  label="Number of inputs"
                  name="Number of inputs"
                  type="number"
                  max={12}
                  min={1}
                  value={String(otpLength)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'length')
                  }
                />
              </div>
            </>
          )}
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default LoginSettings
