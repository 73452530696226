import { AppActions, PreviewActionTypes } from 'types/types'

import { Dispatch } from 'redux'
export const START_PREVIEW = 'START_PREVIEW'
export const CLOSE_PREVIEW = 'CLOSE_PREVIEW'
export const CHANGE_PREVIEW_INDEX = 'CHANGE_PREVIEW_INDEX'
export const CHANGE_PREVIEW_COMPONENTS = 'CHANGE_PREVIEW_COMPONENTS'
export const SHOW_VIEW_COMPONENT = 'SHOW_VIEW_COMPONENT'
export const ONLY_PREVIEW = 'ONLY_PREVIEW'

export const startPreview = () => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: START_PREVIEW
  })
}

export const closePreview = () => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: CLOSE_PREVIEW
  })
}

export const changePreviewIndex = (direction: string) => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: CHANGE_PREVIEW_INDEX,
    payload: { direction }
  })
}

export const changePreviewComponents = (value: number) => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: CHANGE_PREVIEW_COMPONENTS,
    payload: { value }
  })
}

export const previewView = (viewId: string) => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: SHOW_VIEW_COMPONENT,
    payload: { viewId }
  })
}

export const onlyPreview = () => (
  dispatch: Dispatch<AppActions>
): PreviewActionTypes => {
  return dispatch({
    type: ONLY_PREVIEW
  })
}
