import { combineReducers } from 'redux'
import preview from './preview'
import sidebar from './sidebar'
import other from './other'
import componentModify from './componentModify'
import componentSettings from './componentSettings'

export default combineReducers({
  preview,
  sidebar,
  other,
  componentModify,
  componentSettings
})
