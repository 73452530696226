// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from 'styles/theme'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'
import App from './App'
import { ApolloProvider } from '@apollo/client'
import Client from 'api/index'
import * as serviceWorker from './serviceWorker'

export const store = initStore()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={Client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
