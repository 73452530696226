import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import { AppState, Content, ActionItem, ActionItemKey } from 'types/types'
import {
  editContentTableValue,
  removeContentTableValue
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

interface Props {
  content: Content
  itemId: string
  setView: Dispatch<SetStateAction<string>>
}

const CatalogItemSettings: React.FC<Props> = ({ content, itemId, setView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [item, setItem] = useState<ActionItem | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)

  const [text, setText] = useState<string | undefined>('')
  const [secondLine, setSecondLine] = useState<string | undefined>('')
  const [thirdLine, setThirdLine] = useState<string | undefined>('')
  const [image, setImage] = useState<string | undefined>('')
  const [unit, setUnit] = useState<string | undefined>('')

  useEffect(() => {
    const findItem = content.actionItems?.find((c) => c.id === itemId)
    if (findItem) {
      setItem(content.actionItems?.find((c) => c.id === itemId))
      setText(findItem.text)
      setSecondLine(findItem.secondLine)
      setThirdLine(findItem.thirdLine)
      setImage(findItem.image)
      setUnit(findItem.unit)
    }
  }, [itemId, content])

  if (!item) {
    return null
  }

  const handleChangeInput = (
    value: string,
    type: ActionItemKey,
    id: string
  ) => {
    if (type === 'text') setText(value)
    if (type === 'secondLine') setSecondLine(value)
    if (type === 'thirdLine') setThirdLine(value)
    if (type === 'image') setImage(value)
    if (type === 'unit') setUnit(value)
    dispatch(editContentTableValue(value, 'actionItems', type, modifyId, id))
  }

  const deleteComponent = (id: string) => {
    dispatch(removeContentTableValue('actionItems', modifyId, id))
    setView('')
  }

  return (
    <>
      <div className={classes.selectContainer}>
        <Input
          label="Content"
          options={[
            { value: 'One line', label: 'One line' },
            { value: 'Two lines', label: 'Two lines' },
            { value: 'Three lines', label: 'Three lines' }
          ]}
          value={item.selection}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeInput(e.target.value, 'selection', item.id)
          }
        />
      </div>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="First line"
              value={text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'text', item.id)
              }
            />
          </div>
          {item.selection === 'Two lines' ||
          item.selection === 'Three lines' ? (
            <div className={classes.inputPadding}>
              <Input
                label="Second line"
                value={secondLine}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'secondLine', item.id)
                }
              />
            </div>
          ) : null}
          {item.selection === 'Three lines' && (
            <div className={classes.inputPadding}>
              <Input
                label="Third line"
                value={thirdLine}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'thirdLine', item.id)
                }
              />
            </div>
          )}
          <div className={classes.inputPadding}>
            <Input
              label="Image url"
              placeholder="Image url"
              prefixIcon="Image"
              value={image}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'image', item.id)
              }
            />
          </div>
          {item.variant === 'Quantity' && (
            <div className={classes.inputPadding}>
              <Input
                label="Quantity unit"
                placeholder="Quantity unit"
                value={unit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'unit', item.id)
                }
              />
            </div>
          )}
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={() => deleteComponent(item.id)}
          />
        </div>
      </Collapse>
    </>
  )
}

export default CatalogItemSettings
