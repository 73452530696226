import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { changeOther } from 'store/actions/other'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PublicClient from 'api/public'

const useStyles = makeStyles((theme) => ({
  searchInput: {
    flexGrow: 1
  },
  specialOutline: {
    borderColor: 'transparent'
  },
  linkStyle: {
    width: '100%',
    fontSize: 16,
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  input: {
    borderRadius: 0,
    color: theme.palette.grey[50],
    '& ::placeholder': {
      color: theme.palette.grey[50]
    },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: 'transparent!important'
    }
  }
}))

interface Option {
  name: string
  id: string
}

const getQuery = gql`
  query fetchDataview($id: ID!, $search: String) {
    fetchDataview(id: $id, search: $search)
  }
`

const replaceAll = (str: string, strReplace: string, strWith: string) => {
  var esc = strReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  var reg = new RegExp(esc, 'ig')
  return str.replace(reg, strWith)
}

const SearchInput: React.FC<{
  setSearchOpen?: any
  searchOpen: boolean
}> = ({ setSearchOpen, searchOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [options, setOptions] = useState<Array<Option>>([])
  const [search, setSearch] = useState<string>('')
  const searchEl = useRef(null)
  const [value, setValue] = React.useState('')
  const [getSearch, { loading: queryLoading }] = useLazyQuery(getQuery, {
    fetchPolicy: 'network-only',
    client: PublicClient,
    onCompleted(queryData) {
      const newOptions: any = []
      for (const [, value] of Object.entries(queryData.fetchDataview.data)) {
        const concept: any = value
        newOptions.push({
          name: `${concept.clientName} / ${concept.serviceName} / ${concept.id}`,
          id: concept.id
        })
      }
      setOptions(newOptions)
    }
  })
  let history = useHistory()

  useEffect(() => {
    if (searchOpen && searchEl.current) {
      //@ts-ignore
      searchEl.current.focus()
    }
  }, [searchOpen, searchEl])

  // checks if query is loading
  useEffect(() => {
    if (queryLoading) {
      dispatch(changeOther(true, 'servicesLoading'))
    } else {
      dispatch(changeOther(false, 'servicesLoading'))
    }
  }, [queryLoading, dispatch])

  const CustomPaper = (props: any) => {
    return <Paper {...props} style={{ borderRadius: 0 }} />
  }

  const [inputValue, setInputValue] = React.useState('')

  return (
    <>
      <Autocomplete
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name || ''}
        className={classes.searchInput}
        options={options}
        freeSolo
        PaperComponent={CustomPaper}
        disableClearable
        renderOption={(option) => (
          <Link className={classes.linkStyle} to={`/${option.id}`}>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceAll(option.name, search, `<b>${search}</b>`)
              }}
            />
          </Link>
        )}
        onChange={(event: any, value: any) => {
          if (event.keyCode === 13) {
            // When enter is hit
            setOptions([])
            history.push(`/${value.id}`)
          }
          setValue('')
          setInputValue('')
          setSearchOpen(false)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputRef={searchEl}
            placeholder="Search Client, Service name..."
            onChange={(event) => {
              if (event.target.value !== '') {
                getSearch({
                  variables: {
                    id: process.env.REACT_APP_SEARCH_CONCEPT,
                    search: event.target.value
                  }
                })
              } else {
                setOptions([])
                setValue('')
              }

              setSearch(event.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.specialOutline,
                root: classes.input
              }
            }}
          />
        )}
      />
    </>
  )
}

export default SearchInput
