import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { editComponent } from 'store/actions/componentSettings'
import { componentToModify } from 'store/actions/componentModify'
import { changeSelected } from 'store/actions/sidebar'
import { changePreviewIndex, previewView } from 'store/actions/preview'
import Icons from 'components/common/Icons'
import { SpeedDialItem, Component } from 'types/types'
import parse from 'html-react-parser'
import {
  AppBar,
  Button,
  BottomBar,
  BottomNavigation,
  Feedback,
  FeedCard,
  FeedTitle,
  StatusHero,
  SpeedDial,
  Whitelabel
} from 'uniqore-components'

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: '#FAFAFA',
    position: 'relative',
    minHeight: '640px',
    width: '360px'
  },
  outlineHover: {
    '&:hover': {
      outline: '2px solid #8ec8fc'
    }
  },
  outlineClicked: {
    outline: '2px solid #1E88E5'
  },
  speedDial: {
    position: 'absolute',
    marginLeft: '130px',
    marginBottom: '15px',
    width: '100%',
    bottom: 0
  },
  bottomBar: {
    position: 'absolute',
    width: '100%',
    bottom: '34px'
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%'
  },
  footerSafeArea: {
    paddingTop: '40px'
  },
  footerVisible: {
    paddingTop: '105px'
  }
}))

interface Props {
  component: Component
  modifyId: string
  preview: boolean
  showIndex: number
  previewComponentsLength: number
  clientName?: string
  primary?: string
  secondary?: string
  statusText?: string
  statusBackground?: string
  logo?: string
  logoSize?: string
}

const StatusComponent: React.FC<Props> = React.memo(
  ({
    component,
    clientName,
    primary,
    secondary,
    statusText,
    statusBackground,
    logo,
    logoSize,
    modifyId,
    preview,
    showIndex,
    previewComponentsLength
  }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [speedDialActions, setSpeedDialActions] = useState<SpeedDialItem[]>(
      []
    )
    const [bottomBarButtons, setBottomBarButtons] = useState<JSX.Element[]>([])
    const [bottomNavigationActions, setBottomNavigationActions] = useState<
      {
        label: string | undefined
        value: string | undefined
        icon: string | undefined
      }[]
    >([])
    const componentRef = useRef(null)

    // Set footer types:
    // If type is SpeedDial, set speedDialActions, also check and set default icon to prevent error
    // If type is Naviation, set bottomBarButtons
    // If type is Tabs, set bottomNavigationActions
    useEffect(() => {
      if (component.footer) {
        const footer = component.footer
        if (
          footer.type === 'SpeedDial' ||
          !Object.prototype.hasOwnProperty.call(footer, 'type')
        ) {
          const getSpeedDialActions = component.footer.speedDialActions || []
          let actions: SpeedDialItem[] = []
          getSpeedDialActions.forEach((item) => {
            let itemSet = item
            const itemIcon = item.icon || ''
            if (!Object.prototype.hasOwnProperty.call(Icons, itemIcon)) {
              const editItemSet = { ...itemSet, icon: 'RadioButtonUnchecked' }
              actions.push(editItemSet)
            } else {
              actions.push(itemSet)
            }
          })
          setSpeedDialActions(actions)
        }
        if (footer.type === 'Navigation') {
          const buttons = []
          if (footer.backLabel || footer.backIcon) {
            buttons.push(
              <Button
                label={footer.backLabel}
                icon={footer.backIcon}
                disablePadding
                fullWidth
                color="primary"
                variant="default"
                onClick={goBack}
              />
            )
          }
          if (footer.nextLabel || footer.nextIcon) {
            buttons.push(
              <Button
                label={footer.nextLabel}
                icon={footer.nextIcon}
                disablePadding
                fullWidth
                color="primary"
                variant="contained"
                onClick={goForward}
              />
            )
          }
          setBottomBarButtons(buttons)
        }
        if (footer.type === 'Tabs') {
          const actions = []
          if (footer.firstTabIcon || footer.firstTabLabel) {
            actions.push({
              label: footer.firstTabLabel,
              value: footer.firstTabLabel,
              icon: footer.firstTabIcon
            })
          }
          if (footer.secondTabIcon || footer.secondTabLabel) {
            actions.push({
              label: footer.secondTabLabel,
              value: footer.secondTabLabel,
              icon: footer.secondTabIcon
            })
          }
          if (footer.thirdTabIcon || footer.thirdTabLabel) {
            actions.push({
              label: footer.thirdTabLabel,
              value: footer.thirdTabLabel,
              icon: footer.thirdTabIcon
            })
          }
          if (footer.fourthTabIcon || footer.fourthTabLabel) {
            actions.push({
              label: footer.fourthTabLabel,
              value: footer.fourthTabLabel,
              icon: footer.fourthTabIcon
            })
          }
          if (footer.fifthTabIcon || footer.fifthTabLabel) {
            actions.push({
              label: footer.fifthTabLabel,
              value: footer.fifthTabLabel,
              icon: footer.fifthTabIcon
            })
          }
          setBottomNavigationActions(actions)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component.footer])

    useEffect(() => {
      if (componentRef.current) {
        //@ts-ignore
        const height = componentRef.current.clientHeight || 640
        //@ts-ignore
        const width = componentRef.current.clientWidth || 360
        dispatch(editComponent(String(height + 110), 'height', component.id))
        dispatch(editComponent(String(width + 98), 'width', component.id))
      }
    }, [component, dispatch])

    const handleChangeNavigation = () => {
      if (footer.firstTabActive) {
        return footer.firstTabLabel
      }
      if (footer.secondTabActive) {
        return footer.secondTabLabel
      }
      if (footer.thirdTabActive) {
        return footer.thirdTabLabel
      }
      if (footer.fourthTabActive) {
        return footer.fourthTabLabel
      }
      if (footer.fifthTabActive) {
        return footer.fifthTabLabel
      }
    }

    if (!component) {
      return null
    }

    const contents = component.contents
    const header = component.header
    const footer = component.footer
    const statusHero = component.contents.find((c) => c.name === 'Status')

    const goBack = () => {
      if (preview && showIndex !== 0) {
        dispatch(changePreviewIndex('back'))
      }
    }

    const goForward = () => {
      if (preview) {
        if (showIndex !== previewComponentsLength - 1) {
          dispatch(changePreviewIndex('forward'))
        }
      }
    }

    const goToView = () => {
      if (preview) {
        const findStatus = component
        if (findStatus && findStatus.children) {
          const viewId = findStatus.children[0]
          dispatch(previewView(viewId))
        }
      }
    }

    const handleClickComponent = (id: string, name: string) => {
      if (!preview) {
        dispatch(changeSelected('Screens', 'sidebarTop', true))
        dispatch(componentToModify(true, true, id, name))
      }
    }

    return (
      <div ref={componentRef} className={classes.component}>
        <div
          className={clsx({
            [classes.outlineHover]: modifyId !== header.id && !preview,
            [classes.outlineClicked]: modifyId === header.id && !preview
          })}
          style={{ display: header.visible ? '' : 'none' }}
          onClick={() => handleClickComponent(header.id, 'Header')}
        >
          <AppBar
            mainAction={{ icon: header.mainAction as string }}
            color={statusText}
            backgroundColor={statusBackground}
            actions={header.actions?.map((action) => ({ icon: action }))}
            text={header.text}
            logo={logo}
            logoSize={Number(logoSize)}
            border={header.border}
            centerText={header.centerText}
          />
        </div>

        {statusHero && (
          <div
            className={clsx({
              [classes.outlineHover]: modifyId !== statusHero.id && !preview,
              [classes.outlineClicked]: modifyId === statusHero.id && !preview
            })}
            onClick={() => handleClickComponent(statusHero.id, statusHero.name)}
          >
            <StatusHero
              style={{ display: statusHero.visible ? '' : 'none' }}
              color={statusText}
              backgroundColor={statusBackground}
              image={statusHero.svg ? parse(statusHero.svg) : statusHero.image}
              video={statusHero.video}
              iconSize={
                Number(statusHero.iconSize) > 80
                  ? 80
                  : Number(statusHero.iconSize)
              }
              icon={statusHero.iconVisible ? statusHero.icon : ''}
              noticeText={
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Notice text') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Custom')
                  ? statusHero.noticeText
                  : ''
              }
              headline={
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Headline') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Headline + Subtitle') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Custom')
                  ? statusHero.headline
                  : ''
              }
              title={
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Title') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Custom')
                  ? statusHero.title
                  : ''
              }
              subtitle={
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Subtitle') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Headline + Subtitle') ||
                (statusHero.textVisible &&
                  statusHero.textSelection === 'Custom')
                  ? statusHero.subtitle
                  : ''
              }
              body={statusHero.textVisible ? statusHero.body : ''}
              actionComponent={
                !statusHero.ctaVisible ? null : statusHero.ctaSelection ===
                    'CTA' ||
                  statusHero.ctaSelection === 'CTA + secondary CTA' ? (
                  <Button
                    icon={statusHero.ctaIcon}
                    label={statusHero.label}
                    role="status"
                    onClick={goForward}
                  />
                ) : statusHero.ctaSelection === 'Feedback' ? (
                  <Feedback name="feedback" text={statusHero.feedbackText} />
                ) : null
              }
            />
            <div
              style={{
                display: statusHero.ctaVisible
                  ? statusHero.ctaSelection === 'CTA + secondary CTA'
                    ? ''
                    : 'none'
                  : 'none'
              }}
            >
              <Button
                style={{ textAlign: 'center' }}
                icon={statusHero.ctaSecondaryIcon}
                label={statusHero.secondaryLabel}
                variant="text"
                color="primary"
                underline
                onClick={goToView}
              />
            </div>
          </div>
        )}

        {contents.map((content) => {
          return (
            <div
              key={content.id}
              style={{ display: content.visible ? '' : 'none' }}
              className={clsx({
                [classes.outlineHover]: modifyId !== content.id && !preview,
                [classes.outlineClicked]: modifyId === content.id && !preview
              })}
              onClick={() => handleClickComponent(content.id, content.name)}
            >
              {content.name === 'Custom Svg' && (
                <div>{parse(content.svg as string)}</div>
              )}
              {content.name === 'Feed' && (
                <>
                  <FeedTitle
                    style={{ display: content.feedTitle ? '' : 'none' }}
                    text={content.feedTitleText}
                  />
                  <FeedCard
                    user={
                      content.feedUser
                        ? 'You'
                        : clientName
                        ? clientName
                        : 'Uniqore'
                    }
                    date={content.feedDate ? 'Today 12:02' : ''}
                    subtitle={content.feedSubtitle}
                    text={content.feedText}
                    avatarBackgroundColor={
                      content.feedUser ? secondary : primary
                    }
                  />
                </>
              )}
            </div>
          )
        })}
        {footer.type === 'Navigation' ? (
          <>
            <div
              className={clsx({
                [classes.footerSafeArea]: !footer.visible,
                [classes.footerVisible]: footer.visible
              })}
            />
            <div
              style={{ display: footer.visible ? '' : 'none' }}
              className={clsx(classes.bottomBar, {
                [classes.outlineHover]: modifyId !== footer.id && !preview,
                [classes.outlineClicked]: modifyId === footer.id && !preview
              })}
              onClick={() => handleClickComponent(footer.id, 'Footer')}
            >
              <BottomBar components={bottomBarButtons} />
            </div>
            <div className={classes.footer}>
              <Whitelabel />
            </div>
          </>
        ) : footer.type === 'Tabs' ? (
          <>
            <div
              className={clsx({
                [classes.footerSafeArea]: !footer.visible,
                [classes.footerVisible]: footer.visible
              })}
            />
            <div className={classes.footer}>
              <div
                style={{ display: footer.visible ? '' : 'none' }}
                className={clsx({
                  [classes.outlineHover]: modifyId !== footer.id && !preview,
                  [classes.outlineClicked]: modifyId === footer.id && !preview
                })}
                onClick={() => handleClickComponent(footer.id, 'Footer')}
              >
                <BottomNavigation
                  value={handleChangeNavigation()}
                  actions={bottomNavigationActions.slice(
                    0,
                    Number(footer.tabAmount)
                  )}
                />
              </div>
              <Whitelabel />
            </div>
          </>
        ) : (
          <>
            <div style={{ paddingTop: '102px' }} />
            <div
              style={{
                position: 'absolute',
                width: '100%',
                bottom: '0px',
                height: '100px',
                display: footer.visible ? '' : 'none'
              }}
              className={clsx({
                [classes.outlineHover]: modifyId !== footer.id && !preview,
                [classes.outlineClicked]: modifyId === footer.id && !preview
              })}
              onClick={() => handleClickComponent(footer.id, 'Footer')}
            >
              <SpeedDial
                style={{
                  display: !preview ? '' : 'none'
                }}
                className={classes.speedDial}
                actions={speedDialActions}
              />
            </div>
            <div className={classes.footer}>
              <Whitelabel />
            </div>
          </>
        )}
      </div>
    )
  }
)

export default StatusComponent
