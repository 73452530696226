import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Input from 'components/common/Input'
import { ChromePicker } from 'react-color'
import CollapseList from 'components/common/CollapseList'
import { changeSetting } from 'store/actions/componentSettings'
import { AppState, ComponentSettingStateKey } from 'types/types'

const useStyles = makeStyles((theme) => ({
  collapseItems: {
    padding: '0px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  colorPicker: {
    display: 'flex',
    padding: '8px 0px'
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const BrandingActions: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [colorExpanded, setColorExpanded] = useState<boolean>(false)
  const [logoExpanded, setLogoExpanded] = useState<boolean>(false)
  const {
    logo,
    logoSize,
    primary,
    secondary,
    textOnPrimary,
    textOnSecondary,
    statusBackground,
    statusText
  } = useSelector((state: AppState) => state.componentSettings)
  const [pColor, setPColor] = useState<string>(primary || '#')
  const [sColor, setSColor] = useState<string>(secondary || '#')
  const [pText, setPText] = useState<string>(textOnPrimary || '#')
  const [sText, setSText] = useState<string>(textOnSecondary || '#')
  const [backgroundStatus, setBackgroundStatus] = useState<string>(
    statusBackground || '#'
  )
  const [textStatus, setTextStatus] = useState<string>(statusText || '#')

  const [pExpanded, setPExpanded] = useState<boolean>(false)
  const [pTextExpanded, setPTextExpanded] = useState<boolean>(false)
  const [sExpanded, setSExpanded] = useState<boolean>(false)
  const [sTextExpanded, setSTextExpanded] = useState<boolean>(false)
  const [statusBExpanded, setStatusBExpanded] = useState<boolean>(false)
  const [statusTExpanded, setStatusTExpanded] = useState<boolean>(false)

  const resetColors = () => {
    dispatch(changeSetting('#43A047', 'primary'))
    dispatch(changeSetting('#002C38', 'secondary'))
    dispatch(changeSetting('#FFFFFF', 'textOnPrimary'))
    dispatch(changeSetting('#FFFFFF', 'textOnSecondary'))
    dispatch(changeSetting('#002C38', 'statusBackground'))
    dispatch(changeSetting('#FFFFFF', 'statusText'))
    setPColor('#43A047')
    setSColor('#002C38')
    setPText('#FFFFFF')
    setSText('#FFFFFF')
    setBackgroundStatus('#002C38')
    setTextStatus('#FFFFFF')
  }

  const changeColor = (color: string, stateKey: ComponentSettingStateKey) => {
    let value: string = color || ''
    if (value.charAt(0) !== '#') {
      value = `#${value}`
    }
    if (stateKey === 'primary') setPColor(value)
    if (stateKey === 'secondary') setSColor(value)
    if (stateKey === 'textOnPrimary') setPText(value)
    if (stateKey === 'textOnSecondary') setSText(value)
    if (stateKey === 'statusBackground') setBackgroundStatus(value)
    if (stateKey === 'statusText') setTextStatus(value)
    dispatch(changeSetting(value, stateKey))
  }

  const getStyle = (colorType: string) => {
    return {
      alignSelf: 'flex-start',
      marginLeft: '16px',
      minHeight: '54px',
      minWidth: '54px',
      borderRadius: '50%',
      cursor: 'pointer',
      border: '1px solid black',
      backgroundColor: colorType
    }
  }

  return (
    <div>
      <CollapseList
        expanded={colorExpanded}
        setExpanded={setColorExpanded}
        primary="Colors"
      />
      <Collapse in={colorExpanded} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          {/* Primary color */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Primary color"
                  value={pColor}
                  onChange={(e: any) => changeColor(e.target.value, 'primary')}
                  suffixIcon={pExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setPExpanded(!pExpanded)}
                />
              </div>
              <Collapse in={pExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={pColor}
                  onChange={(color) => changeColor(color.hex, 'primary')}
                />
              </Collapse>
            </div>
            <div
              onClick={() => setPExpanded(!pExpanded)}
              style={getStyle(pColor)}
            />
          </div>

          {/* Text on primary */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Text on primary"
                  value={pText}
                  onChange={(e: any) =>
                    changeColor(e.target.value, 'textOnPrimary')
                  }
                  suffixIcon={pTextExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setPTextExpanded(!pTextExpanded)}
                />
              </div>
              <Collapse in={pTextExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={pText}
                  onChange={(color) => changeColor(color.hex, 'textOnPrimary')}
                />
              </Collapse>
            </div>
            <div
              onClick={() => setPTextExpanded(!pTextExpanded)}
              style={getStyle(pText)}
            />
          </div>

          {/* Secondary color */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Secondary color"
                  value={sColor}
                  onChange={(e: any) =>
                    changeColor(e.target.value, 'secondary')
                  }
                  suffixIcon={sExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setSExpanded(!sExpanded)}
                />
              </div>
              <Collapse in={sExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={sColor}
                  onChange={(color) => changeColor(color.hex, 'secondary')}
                />
              </Collapse>
            </div>
            <div
              onClick={() => setSExpanded(!sExpanded)}
              style={getStyle(sColor)}
            />
          </div>

          {/* Text on secondary */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Text on secondary"
                  value={sText}
                  onChange={(e: any) =>
                    changeColor(e.target.value, 'textOnSecondary')
                  }
                  suffixIcon={sTextExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setSTextExpanded(!sTextExpanded)}
                />
              </div>
              <Collapse in={sTextExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={sText}
                  onChange={(color) =>
                    changeColor(color.hex, 'textOnSecondary')
                  }
                />
              </Collapse>
            </div>
            <div
              onClick={() => setSTextExpanded(!sTextExpanded)}
              style={getStyle(sText)}
            />
          </div>

          {/* Status background */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Status background"
                  value={backgroundStatus}
                  onChange={(e: any) =>
                    changeColor(e.target.value, 'statusBackground')
                  }
                  suffixIcon={statusBExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setStatusBExpanded(!statusBExpanded)}
                />
              </div>
              <Collapse in={statusBExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={backgroundStatus}
                  onChange={(color) =>
                    changeColor(color.hex, 'statusBackground')
                  }
                />
              </Collapse>
            </div>
            <div
              onClick={() => setStatusBExpanded(!statusBExpanded)}
              style={getStyle(backgroundStatus)}
            />
          </div>

          {/* Status text */}
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Status text"
                  value={textStatus}
                  onChange={(e: any) =>
                    changeColor(e.target.value, 'statusText')
                  }
                  suffixIcon={statusTExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setStatusTExpanded(!statusTExpanded)}
                />
              </div>
              <Collapse in={statusTExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={textStatus}
                  onChange={(color) => changeColor(color.hex, 'statusText')}
                />
              </Collapse>
            </div>
            <div
              onClick={() => setStatusTExpanded(!statusTExpanded)}
              style={getStyle(textStatus)}
            />
          </div>

          <Button
            style={{ paddingTop: '8px' }}
            variant="contained"
            onClick={resetColors}
          >
            Reset colors
          </Button>
        </div>
      </Collapse>
      <CollapseList
        expanded={logoExpanded}
        setExpanded={setLogoExpanded}
        primary="Logo"
      />
      <Collapse in={logoExpanded} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="Logo url"
              value={logo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(changeSetting(e.target.value, 'logo'))
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              type="number"
              label="Logo size"
              value={logoSize}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(changeSetting(e.target.value, 'logoSize'))
              }
            />
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default BrandingActions
