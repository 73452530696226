import { Dispatch } from 'redux'
import { ComponentModifyActionTypes, AppActions } from 'types/types'
export const COMPONENT_TO_MODIFY = 'COMPONENT_TO_MODIFY'

export const componentToModify = (
  modifyingComponent: boolean,
  showValues: boolean,
  id: string,
  name: string
) => (dispatch: Dispatch<AppActions>): ComponentModifyActionTypes => {
  return dispatch({
    type: COMPONENT_TO_MODIFY,
    payload: { modifyingComponent, showValues, id, name }
  })
}
