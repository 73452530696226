import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Icons from 'components/common/Icons'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import BrandingActions from 'components/SideDrawer/BrandingActions'
import SettingsActions from 'components/SideDrawer/SettingsActions'
import ScreensActions from 'components/SideDrawer/ScreensActions'
import { AppState, SidebarItem } from 'types/types'

const useStyles = makeStyles((theme) => ({
  sideDrawer: {
    paddingTop: '64px',
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100vh',
    overflow: 'auto'
    // scrollbarWidth: 'none',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // }
  },
  title: {
    height: '63px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  // @ts-ignore
  iconColor: ({ color }) => ({
    color
  }),
  // @ts-ignore
  titleText: ({ color }) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.15px',
    color
  })
}))

const SideDrawer: React.FC<{ selectedAction: SidebarItem | null }> = ({
  selectedAction
}) => {
  const [color, setColor] = useState({ color: '' })
  const classes = useStyles(color)
  const { modifyingComponent } = useSelector(
    (state: AppState) => state.componentModify
  )

  useEffect(() => {
    if (selectedAction) {
      setColor({ color: selectedAction.color })
    }
  }, [selectedAction])

  if (selectedAction === null) {
    return null
  }

  return (
    <div className={classes.sideDrawer}>
      <div>
        {!modifyingComponent && (
          <div className={classes.title}>
            <List>
              <ListItem>
                <ListItemIcon classes={{ root: classes.iconColor }}>
                  {React.createElement(Icons[selectedAction.icon])}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.titleText }}
                  primary={selectedAction.name}
                />
              </ListItem>
            </List>
          </div>
        )}
        <div>
          {selectedAction.name === 'Branding' && <BrandingActions />}
          {selectedAction.name === 'Settings' && <SettingsActions />}
          {selectedAction.name === 'Screens' && <ScreensActions />}
        </div>
      </div>
    </div>
  )
}

export default SideDrawer
