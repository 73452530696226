import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { ReactComponent as Phone } from 'assets/phone.svg'
import { default as Status } from 'components/examples/StatusComponent'
import { default as Play } from 'components/examples/PlayComponent'
import { default as View } from 'components/examples/ViewComponent'
import Icons from 'components/common/Icons'
import {
  closePreview,
  changePreviewIndex,
  changePreviewComponents,
  previewView
} from 'store/actions/preview'
import { AppState, Component, SpeedDialItem } from 'types/types'
import { ArrowForwardIos, Close } from '@material-ui/icons'
import { SpeedDial } from 'uniqore-components'

const useStyles = makeStyles(() => ({
  previewPhone: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  dialog: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: '#002C38'
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    height: '640px',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  arrowIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '60px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '50px',
      color: '#FAFAFA'
    }
  },
  backwardIcon: {
    marginRight: '10px'
  },
  forwardIcon: {
    marginLeft: '10px'
  },
  closeIcon: {
    position: 'fixed',
    top: 15,
    left: 15,
    cursor: 'pointer',
    '& svg': {
      fontSize: '50px',
      color: '#FAFAFA'
    }
  },
  speedDial: {
    position: 'absolute',
    right: '90px',
    bottom: '-630px'
  }
}))

const Preview: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    preview,
    sharedPreview,
    showIndex,
    previewComponentsLength,
    showView
  } = useSelector((state: AppState) => state.preview)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const {
    clientName,
    logo,
    logoSize,
    primary,
    secondary,
    textOnPrimary,
    textOnSecondary,
    statusBackground,
    statusText,
    components
  } = useSelector((state: AppState) => state.componentSettings)
  const [Components] = useState({ Status, Play, View })
  const [customTheme, setCustomTheme] = useState({})
  const [previewComponents, setPreviewComponents] = useState<Component[]>([])
  const [viewComponents, setViewComponents] = useState<Component[]>([])
  const [
    currentPreviewComponent,
    setCurrentPreviewComponent
  ] = useState<Component>()
  const [speedDialActions, setSpeedDialActions] = useState<SpeedDialItem[]>([])

  // set current component shown in preview
  useEffect(() => {
    const currentComponent = components[showIndex]
    if (currentComponent.type === 'Status') {
      setCurrentPreviewComponent(components[showIndex])
    } else {
      setCurrentPreviewComponent(undefined)
    }
  }, [showIndex, components])

  // sets current preview Status-component's speed dial actions
  useEffect(() => {
    if (currentPreviewComponent && currentPreviewComponent.type === 'Status') {
      const getSpeedDialActions =
        currentPreviewComponent.footer.speedDialActions || []
      let actions: SpeedDialItem[] = []
      getSpeedDialActions.forEach((item) => {
        let itemSet = item
        const itemIcon = item.icon || ''
        if (!Object.prototype.hasOwnProperty.call(Icons, itemIcon)) {
          const editItemSet = { ...itemSet, icon: 'RadioButtonUnchecked' }
          actions.push(editItemSet)
        } else {
          actions.push(itemSet)
        }
      })
      setSpeedDialActions(actions)
    }
  }, [currentPreviewComponent])

  useEffect(() => {
    setCustomTheme(
      createMuiTheme({
        palette: {
          primary: {
            main: primary === '#' ? '#0' : primary,
            contrastText: textOnPrimary === '#' ? '#0' : textOnPrimary
          },
          secondary: {
            main: secondary === '#' ? '#0' : secondary,
            contrastText: textOnSecondary === '#' ? '#0' : textOnSecondary
          },
          text: {
            primary: 'rgba(0, 44, 56, 0.87)',
            secondary: 'rgba(0, 44, 56, 0.6)',
            disabled: 'rgba(0, 44, 56, 0.38)'
          }
        },
        // @ts-ignore
        custom: {
          status: {
            background: statusBackground === '#' ? '#0' : statusBackground,
            color: statusText === '#' ? '#0' : statusText
          }
        },
        typography: {
          fontFamily: '"Roboto"',
          h1: {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '96px',
            lineHeight: '76px'
          },
          h3: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '48px',
            lineHeight: '50px'
          },
          h5: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px'
          },
          h6: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '31px',
            letterSpacing: '0.15px'
          },
          subtitle1: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '0.15px'
          },
          body2: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.25px'
          },
          button: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            alignItems: 'center',
            letterSpacing: '1.25px'
          },
          caption: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.4px'
          },
          overline: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '1.5px'
          }
        }
      })
    )
  }, [
    primary,
    secondary,
    textOnPrimary,
    textOnSecondary,
    statusBackground,
    statusText
  ])

  // sets preview components
  useEffect(() => {
    const addComponents: Component[] = []
    const addViewComponents: Component[] = []
    components.forEach((component) => {
      if (component.type !== 'View') {
        addComponents.push(component)
      } else {
        addViewComponents.push(component)
      }
    })
    dispatch(changePreviewComponents(addComponents.length))
    setViewComponents(addViewComponents)
    setPreviewComponents(addComponents)
  }, [components, dispatch])

  const goBack = () => {
    if (showView) {
      dispatch(previewView(''))
    } else if (showIndex !== 0) {
      dispatch(changePreviewIndex('back'))
    }
  }

  const goForward = () => {
    if (showIndex !== previewComponentsLength - 1) {
      dispatch(changePreviewIndex('forward'))
    }
  }

  const handleClosePreview = () => {
    if (!sharedPreview) {
      dispatch(closePreview())
    }
  }

  return (
    <div>
      <Dialog
        PaperProps={{ elevation: 0 }}
        onBackdropClick={handleClosePreview}
        open={preview}
        scroll="body"
        classes={{ root: classes.dialog }}
      >
        <div style={{ position: 'relative' }}>
          <div className={classes.previewPhone}>
            <Phone />
          </div>
          <div
            style={{
              display: currentPreviewComponent
                ? currentPreviewComponent.footer.visible
                  ? ''
                  : 'none'
                : 'none'
            }}
          >
            <ThemeProvider theme={customTheme}>
              <SpeedDial
                className={classes.speedDial}
                actions={speedDialActions}
              />
            </ThemeProvider>
          </div>
        </div>
        <div
          style={{ display: sharedPreview ? 'none' : '' }}
          className={classes.closeIcon}
          onClick={() => dispatch(closePreview())}
        >
          <Close />
        </div>
        <div className={classes.dialogContent}>
          <div
            style={{
              visibility: showIndex === 0 && !showView ? 'hidden' : 'visible'
            }}
            className={clsx(classes.arrowIcons, classes.backwardIcon)}
            onClick={goBack}
          >
            <ArrowForwardIos style={{ transform: 'rotate(180deg)' }} />
          </div>
          <div style={{ alignSelf: 'flex-start' }}>
            {showView ? (
              <>
                {viewComponents.map((component) => {
                  if (component.id === showView) {
                    return (
                      <div key={component.id}>
                        <ThemeProvider theme={customTheme}>
                          {React.createElement(Components[component.type], {
                            component,
                            clientName,
                            primary,
                            secondary,
                            statusText,
                            statusBackground,
                            logo,
                            logoSize,
                            modifyId,
                            preview,
                            showIndex,
                            previewComponentsLength
                          })}
                        </ThemeProvider>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
              </>
            ) : (
              <>
                {previewComponents.map((component, index) => {
                  if (showIndex === index) {
                    return (
                      <div key={component.id}>
                        <ThemeProvider theme={customTheme}>
                          {React.createElement(Components[component.type], {
                            component,
                            clientName,
                            primary,
                            secondary,
                            statusText,
                            statusBackground,
                            logo,
                            logoSize,
                            modifyId,
                            preview,
                            showIndex,
                            previewComponentsLength
                          })}
                        </ThemeProvider>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
              </>
            )}
          </div>
          <div
            style={{
              visibility:
                showView || showIndex === previewComponentsLength - 1
                  ? 'hidden'
                  : 'visible'
            }}
            className={clsx(classes.arrowIcons, classes.forwardIcon)}
            onClick={goForward}
          >
            <ArrowForwardIos />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default React.memo(Preview)
