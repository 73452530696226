import produce from 'immer'
import { v4 as uuid } from 'uuid'
import {
  ComponentSettingsState,
  ComponentSettingsActionTypes,
  ContentTableItem,
  HeaderFooterItem,
  Content,
  Component
} from 'types/types'
import {
  LOAD_SETTINGS,
  CHANGE_SETTING,
  ADD_NEW_COMPONENT,
  EDIT_COMPONENT,
  DELETE_COMPONENT,
  CHANGE_VALUE_CONTENT,
  CHANGE_VALUE_HEADER_FOOTER,
  ADD_HEADER_FOOTER_TABLE_VALUE,
  EDIT_HEADER_FOOTER_TABLE_VALUE,
  REMOVE_HEADER_FOOTER_TABLE_VALUE,
  SWAP_COMPONENTS,
  SWAP_CONTENT,
  SWAP_CONTENT_TABLE,
  ADD_NEW_CONTENT,
  REMOVE_CONTENT,
  ADD_CONTENT_TABLE_VALUE,
  EDIT_CONTENT_TABLE_VALUE,
  REMOVE_CONTENT_TABLE_VALUE
} from 'store/actions/componentSettings'

const initialState: ComponentSettingsState = {
  id: '',
  clientName: '',
  serviceName: '',
  logo:
    'https://assets.website-files.com/5fa15e111bdcf3ea29f1fec1/5fa15e111bdcf3306df1ff16_uniqore-logo_tm_white.svg',
  logoSize: '100',
  primary: '#43A047',
  secondary: '#002C38',
  textOnPrimary: '#FFFFFF',
  textOnSecondary: '#FFFFFF',
  statusBackground: '#002C38',
  statusText: '#FFFFFF',
  components: [
    {
      id: '1',
      name: '1-Status',
      type: 'Status',
      order: '1',
      positionX: 0,
      positionY: 0,
      height: '',
      width: '',
      parentId: '',
      children: [],
      header: {
        id: '10',
        visible: true,
        menu: true,
        text: '',
        mainAction: '',
        actions: ['HelpOutline'],
        border: false,
        centerText: false
      },
      contents: [
        {
          id: '11',
          nameIcon: 'Image',
          name: 'Status',
          selection: '',
          visible: true,
          iconVisible: true,
          textVisible: true,
          ctaVisible: true,
          textSelection: 'Headline + Subtitle',
          ctaSelection: 'CTA + secondary CTA',
          icon: 'Event',
          iconSize: '80',
          image: '',
          video: '',
          svg: '',
          noticeText: 'Notice text',
          headline: "What's next",
          title: 'Title',
          subtitle: "Description of what's next",
          body: '',
          ctaIcon: 'Done',
          label: 'Play CTA',
          ctaSecondaryIcon: 'Done',
          secondaryLabel: 'View CTA',
          feedbackText: ''
        },
        {
          id: '12',
          nameIcon: 'DynamicFeed',
          name: 'Feed',
          selection: 'Feed',
          visible: true,
          feedTitle: true,
          feedTitleText: 'Recent actions',
          feedUser: true,
          feedDate: true,
          feedSubtitle: 'Additional information',
          feedText:
            'This is a short description of what just happened. Text can include shortcodes such as time and user details.'
        }
      ],
      footer: {
        id: '19',
        visible: true,
        type: 'SpeedDial',
        speedDialActions: []
      }
    },
    {
      id: '2',
      name: '1-Play',
      type: 'Play',
      order: '2',
      positionX: 458,
      positionY: 0,
      height: '',
      width: '',
      parentId: '',
      children: [],
      header: {
        id: '20',
        visible: true,
        text: 'Previous screen',
        mainAction: 'ArrowBack',
        actions: ['HelpOutline'],
        border: true,
        centerText: false
      },
      contents: [
        {
          id: '21',
          nameIcon: 'TextFormat',
          name: 'Heading',
          selection: 'Title + Body',
          visible: true,
          title: 'Main question',
          textLeftAlign: false,
          body: 'Description area if needed'
        },
        {
          id: '22',
          nameIcon: 'RadioButtonChecked',
          name: 'Action area',
          selection: 'Groups',
          visible: true,
          typeRadio: true,
          typeButton: true,
          showIcon: true,
          showMeta: true,
          actionItems: [
            {
              id: '221',
              type: 'Text field',
              typeRadio: true,
              typeButton: true,
              selection: 'Two lines',
              value: 221,
              visible: true,
              showIcon: true,
              showMeta: true,
              icon: '',
              label: 'Text',
              metaText: '',
              firstLine: 'First line',
              secondLine: 'Second line',
              thirdLine: 'Third line',
              placeholder: '',
              helperText: '',
              prefix: '',
              prefixIcon: '',
              suffix: '',
              suffixIcon: '',
              maxLength: 0,
              rows: 0,
              option: 'Example'
            },
            {
              id: '222',
              type: 'Text field',
              typeRadio: true,
              typeButton: true,
              selection: 'Two lines',
              value: 222,
              visible: true,
              showIcon: true,
              showMeta: true,
              icon: '',
              label: 'Text',
              metaText: '',
              firstLine: 'First line',
              secondLine: 'Second line',
              thirdLine: 'Third line',
              placeholder: '',
              helperText: '',
              prefix: '',
              prefixIcon: '',
              suffix: '',
              suffixIcon: '',
              maxLength: 0,
              rows: 0,
              option: 'Example'
            },
            {
              id: '223',
              type: 'Text field',
              typeRadio: true,
              typeButton: true,
              selection: 'Two lines',
              value: 223,
              visible: true,
              showIcon: true,
              showMeta: true,
              icon: '',
              label: 'Text',
              metaText: '',
              firstLine: 'First line',
              secondLine: 'Second line',
              thirdLine: 'Third line',
              placeholder: '',
              helperText: '',
              prefix: '',
              prefixIcon: '',
              suffix: '',
              suffixIcon: '',
              maxLength: 0,
              rows: 0,
              option: 'Example'
            }
          ]
        },
        {
          id: '23',
          nameIcon: 'Info',
          name: 'Small print',
          selection: '',
          visible: true,
          text: 'Small print text',
          textLeftAlign: false
        }
      ],
      footer: {
        id: '29',
        visible: true,
        type: 'Navigation',
        backLabel: 'Previous',
        backIcon: '',
        nextLabel: 'Forward',
        nextIcon: ''
      }
    }
    // {
    //   id: '3',
    //   name: '1-View',
    //   type: 'View',
    //   height: '',
    //   width: '',
    //   header: {
    //     id: '30',
    //     visible: true,
    //     text: 'Go back to X',
    //     mainAction: 'ArrowBack',
    //     actions: ['HelpOutline'],
    //     border: true,
    //     centerText: false
    //   },
    //   contents: [
    //     {
    //       id: '31',
    //       nameIcon: 'TextFormat',
    //       name: 'Heading',
    //       selection: 'Title',
    //       visible: true,
    //       title: 'Summary',
    //       body: '',
    //       textLeftAlign: true
    //     },
    //     {
    //       id: '32',
    //       nameIcon: 'Storage',
    //       name: 'Data display',
    //       selection: 'List',
    //       visible: true,
    //       actionItems: [
    //         {
    //           id: '321',
    //           type: 'List item',
    //           selection: 'Two lines',
    //           value: 321,
    //           visible: true,
    //           showIcon: true,
    //           showMeta: false,
    //           icon: 'AddCircleOutline',
    //           label: 'First line',
    //           metaText: '',
    //           secondLine: 'Second line',
    //           thirdLine: 'Third line',
    //           placeholder: '',
    //           helperText: '',
    //           prefix: '',
    //           prefixIcon: '',
    //           suffix: '',
    //           suffixIcon: '',
    //           maxLength: 0,
    //           rows: 0,
    //           option: 'Example'
    //         }
    //       ]
    //     },
    //     {
    //       id: '33',
    //       nameIcon: 'PlayCircleFilled',
    //       name: 'CTA Button',
    //       selection: 'Contained',
    //       visible: true,
    //       icon: 'Done',
    //       label: 'CONFIRM',
    //       colorSecondary: true
    //     }
    //   ],
    //   footer: {
    //     id: '39',
    //     visible: true,
    //     tabAmount: '1',
    //     firstTabIcon: 'AddCircleOutline',
    //     firstTabLabel: 'Tab 1',
    //     secondTabIcon: 'AddCircleOutline',
    //     secondTabLabel: 'Tab 2',
    //     thirdTabIcon: 'AddCircleOutline',
    //     thirdTabLabel: 'Tab 3',
    //     fourthTabIcon: 'AddCircleOutline',
    //     fourthTabLabel: 'Tab 4',
    //     fifthTabIcon: 'AddCircleOutline',
    //     fifthTabLabel: 'Tab 5'
    //   }
    // }
  ]
}

// modifies 1 to 01 etc...
const formatNumber = (value: number) => {
  return value.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}

/**
 * Sorting works like: 1, 2, 3, 1.0100, 1.0101, 1.0200, 1.0201, 2.0301 etc...
 * First two decimal digits are pointing the order of the
 * children component under its parent (the very first digit).
 * Last two decimal digits are pointing the path's order under its children.
 * Example 3.02.01: parent's order is 3, second child of that parent (02), first path of that child (01)
 * @param components
 * @returns array of components sorted based on their order value
 */
const sortComponentsByOrder = (components: Component[]) => {
  // parents order looks like: 1, 2, 3 etc...
  const parents: Component[] = []
  // children order looks like 1.1000, 1.1001, 2.20101 etc...
  const children: Component[] = []

  components.forEach((c) => {
    if (Number.isInteger(Number(c.order))) {
      parents.push(c)
    } else {
      children.push(c)
    }
  })

  parents.sort((a: any, b: any) => a.order - b.order)
  children.sort((a, b) =>
    a.order.localeCompare(b.order, undefined, { numeric: true })
  )
  return parents.concat(children)
} // sortComponentsByOrder

/**
 * Sets components' positionX and positionY and updates any changes
 * to the children and paths if their parent's order number or
 * position changes
 * @param components
 * @returns updated components's positions
 */
const setComponentPositions = (components: Component[]) => {
  const sortComponents = JSON.parse(JSON.stringify(components))
  for (let i = 0; i < sortComponents.length; i++) {
    if (!sortComponents[i].parentId && !sortComponents[i].pathParentId) {
      // parent component, position Y stays the same (0), positionX changes
      sortComponents[i].order = String(i + 1)
      sortComponents[i].positionX = 458 * i
    } else if (sortComponents[i].parentId) {
      // if component has a parent (on top), set childs positionX the same as its parent
      // child's positionY updates if other child components are removed under the same parent
      const parent = sortComponents.find(
        (c: Component) => c.id === sortComponents[i].parentId
      )
      const childIndex = parent.children.findIndex(
        (id: string) => id === sortComponents[i].id
      )
      sortComponents[i].positionY = 690 * childIndex + Number(parent.height)
      sortComponents[i].positionX = parent.positionX
      sortComponents[i].order = `${parent.order}.${formatNumber(
        childIndex + 1
      )}00`
    } else if (sortComponents[i].pathParentId) {
      // if components has a path parent, set childs positionX and positionY according to its path parent and others in same path
      const pathParent = sortComponents.find(
        (c: Component) => c.id === sortComponents[i].pathParentId
      )
      const pathChildIndex = pathParent.paths.findIndex(
        (id: string) => id === sortComponents[i].id
      )
      sortComponents[i].order = `${pathParent.order.substring(
        0,
        pathParent.order.length - 2
      )}${formatNumber(pathChildIndex + 1)}`
      sortComponents[i].positionX =
        pathParent.positionX + 458 * (pathChildIndex + 1)
      sortComponents[i].positionY = pathParent.positionY
    }
  }

  return sortComponents
} // setComponentPositions

const getContentAndIndex = (state: ComponentSettingsState, id: string) => {
  let content = null
  let contentIndex = -1
  state.components.forEach((c) => {
    c.contents.forEach((h: Content) => {
      if (h.id === id) {
        content = c.contents
        contentIndex = c.contents.findIndex((v: Content) => v.id === h.id)
      }
    })
  })
  return { content, contentIndex }
}

const getComponentAndIndex = (state: ComponentSettingsState, id: string) => {
  let component = state.components.find((c) => c.id === id)
  let componentIndex = -1
  if (!component) {
    state.components.forEach((c) => {
      c.contents.forEach((content) => {
        if (content.id === id) {
          component = c
        }
      })
    })
  }
  if (component) {
    componentIndex = state.components.findIndex((c) => c.id === component?.id)
  }
  return { component, componentIndex }
}

const setComponentName = (state: ComponentSettingsState, type: string) => {
  let componentAmount = 1
  state.components.forEach((component) => {
    if (component.type === type) {
      componentAmount++
    }
  })
  return `${componentAmount}-${type}`
}

const componentSettings = (
  state = initialState,
  action: ComponentSettingsActionTypes
): ComponentSettingsState => {
  switch (action.type) {
    case LOAD_SETTINGS: {
      const { payload } = action
      const newState = JSON.parse(JSON.stringify(payload.value))
      newState.id = payload.id
      return newState
    }

    case CHANGE_SETTING: {
      const { payload } = action
      return produce(state, (draft) => {
        draft[payload.stateKey] = payload.value
      })
    }

    case ADD_NEW_COMPONENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const componentName = setComponentName(state, payload.value.type)
        const setContents = payload.value.contents.map((content) => ({
          ...content,
          id: uuid()
        }))
        const newComponent = {
          ...payload.value,
          id: uuid(),
          name: componentName,
          header: {
            ...payload.value.header,
            id: uuid()
          },
          footer: {
            ...payload.value.footer,
            id: uuid()
          },
          contents: setContents
        }
        let components = JSON.parse(JSON.stringify(state.components))
        // add component below its parent
        if (payload.topId) {
          const { componentIndex, component } = getComponentAndIndex(
            state,
            payload.topId
          )
          if (componentIndex > -1 && component) {
            newComponent.parentId = payload.topId
            if (payload.value.type === 'Play') {
              newComponent.paths = []
            }
            components[componentIndex].children?.push(newComponent.id)
            const childIndex = components[componentIndex].children!.findIndex(
              (id: string) => id === newComponent.id
            )
            newComponent.order = `${component.order}.${formatNumber(
              childIndex + 1
            )}00`
            newComponent.positionX = component.positionX
            newComponent.positionY =
              Number(component.height) +
              (690 * state.components[componentIndex].children!.length + 1)
            components.push(newComponent)
          }
        }

        // add component to the right side of the clicked screen's speeddial
        else if (payload.leftId) {
          const { componentIndex } = getComponentAndIndex(state, payload.leftId)
          if (componentIndex > -1) {
            const sortComponents = JSON.parse(JSON.stringify(state.components))
            sortComponents.splice(componentIndex + 1, 0, newComponent)
            components = setComponentPositions(sortComponents)
          }
        }

        // add Play paths
        else if (payload.pathLeftId && payload.order) {
          const { componentIndex, component } = getComponentAndIndex(
            state,
            payload.pathLeftId
          )
          if (componentIndex > -1 && component) {
            // add new play id to the first/main Play's path array
            components[componentIndex].paths?.push(newComponent.id)
            const paths: Component[] = []
            components[componentIndex].paths!.forEach((id: string) => {
              state.components.forEach((c) => {
                if (id === c.id) {
                  paths.push(c)
                }
              })
            })
            // TODO, currently every new path screen is added to the end
            // of its path, add feature to add somewhere in middle also
            newComponent.pathParentId = component.id
            newComponent.order = `${component.order.substring(
              0,
              component.order.length - 2
            )}${formatNumber(paths.length + 1)}`
            newComponent.positionY = component.positionY
            newComponent.positionX =
              component.positionX + 458 * (component.paths!.length + 1)
            components.push(newComponent)
          }
        } else {
          components.push(newComponent)
        }
        draft.components = sortComponentsByOrder(components)
      })
    } // case ADD_NEW_COMPONENT

    case EDIT_COMPONENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const { componentIndex } = getComponentAndIndex(state, payload.id)
        if (componentIndex > -1) {
          // @ts-ignore
          draft.components[componentIndex][payload.stateKey] = payload.value
        }
      })
    }

    case DELETE_COMPONENT: {
      const { payload } = action
      return produce(state, (draft) => {
        let components = JSON.parse(JSON.stringify(state.components))
        const componentToDelete = components.find(
          (c: Component) => c.id === payload.id
        )
        const idsToDelete: string[] = []
        if (componentToDelete) {
          // component is in a path, is not a parent or a child
          if (componentToDelete.pathParentId) {
            const pathParent = components.find(
              (c: Component) => c.id === componentToDelete.pathParentId
            )
            pathParent.paths = pathParent.paths.filter(
              (id: string) => id !== componentToDelete.id
            )
            components = components.map((c: Component) =>
              c.id === pathParent.id ? pathParent : c
            )
            idsToDelete.push(componentToDelete.id)
          }
          // components is a child, it may have paths
          else if (componentToDelete.parentId !== '') {
            const parent = components.find(
              (c: Component) => c.id === componentToDelete.parentId
            )
            parent.children = parent.children.filter(
              (id: string) => id !== componentToDelete.id
            )
            components = components.map((c: Component) =>
              c.id === parent.id ? parent : c
            )
            if (componentToDelete.paths) {
              idsToDelete.push(...componentToDelete.paths)
            }
            idsToDelete.push(componentToDelete.id)
          }
          // component has children, is a parent
          else if (componentToDelete.parentId === '') {
            componentToDelete.children.forEach((id: string) => {
              const child = state.components.find((c) => c.id === id)
              if (child) {
                if (child.paths) {
                  idsToDelete.push(...child.paths)
                }
                idsToDelete.push(child.id)
              }
            })
            idsToDelete.push(componentToDelete.id)
          }
        }
        for (let i = 0; i < idsToDelete.length; i++) {
          components = components.filter(
            (c: Component) => c.id !== idsToDelete[i]
          )
        }
        const sortedComponents = sortComponentsByOrder(components)
        draft.components = setComponentPositions(sortedComponents)
      })
    } // case DELETE_COMPONENT

    case CHANGE_VALUE_HEADER_FOOTER: {
      const { payload } = action
      return produce(state, (draft) => {
        const index = state.components.findIndex(
          (c) => c[payload.stateKey].id === payload.id
        )
        if (index > -1) {
          // @ts-ignore
          draft.components[index][payload.stateKey][
            payload.stateAdditionalKey
          ] = payload.value
        }
      })
    }

    case ADD_HEADER_FOOTER_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const componentIndex = state.components.findIndex(
          (c) => c[payload.stateKey].id === payload.id
        )
        if (componentIndex > -1) {
          const findTable =
            state.components[componentIndex][payload.stateKey][
              payload.stateAdditionalKey
            ] || []

          if (findTable) {
            let editTable = JSON.parse(JSON.stringify(findTable))
            const newValue = {
              ...payload.value,
              id: uuid()
            }
            editTable.push(newValue)
            // @ts-ignore
            draft.components[componentIndex][payload.stateKey][
              payload.stateAdditionalKey
            ] = editTable
          }
        }
      })
    }

    case EDIT_HEADER_FOOTER_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const componentIndex = state.components.findIndex(
          (c) => c[payload.stateKey].id === payload.modifyId
        )
        if (componentIndex > -1) {
          const value = state.components[componentIndex][payload.stateKey][
            payload.stateAdditionalKey
          ]!.find((v: HeaderFooterItem) => v.id === payload.itemId)

          if (value) {
            const newValue = JSON.parse(JSON.stringify(value))
            newValue[payload.itemKey] = payload.value

            draft.components[componentIndex][payload.stateKey][
              payload.stateAdditionalKey
            ] = state.components[componentIndex][payload.stateKey][
              payload.stateAdditionalKey
            ]!.map((c: HeaderFooterItem) =>
              c.id === payload.itemId ? newValue : c
            )
          }
        }
      })
    }

    case REMOVE_HEADER_FOOTER_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const componentIndex = state.components.findIndex(
          (c) => c[payload.stateKey].id === payload.modifyId
        )
        if (componentIndex > -1) {
          draft.components[componentIndex][payload.stateKey][
            payload.stateAdditionalKey
          ] = state.components[componentIndex][payload.stateKey][
            payload.stateAdditionalKey
          ]!.filter((v: HeaderFooterItem) => v.id !== payload.itemId)
        }
      })
    }

    case CHANGE_VALUE_CONTENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const { contentIndex } = getContentAndIndex(state, payload.id)
        const { componentIndex } = getComponentAndIndex(state, payload.id)
        if (componentIndex > -1 && contentIndex > -1) {
          // @ts-ignore
          draft.components[componentIndex].contents[contentIndex][
            payload.stateKey
          ] = payload.value
        }
      })
    }

    case SWAP_COMPONENTS: {
      const { payload } = action
      return produce(state, (draft) => {
        let components: Component[] = JSON.parse(
          JSON.stringify(state.components)
        )
        // swap position and order of the components (payload.component & payload.swapComponent)
        components[payload.componentIndex].positionX =
          payload.swapComponent.positionX
        components[payload.componentIndex].order = payload.swapComponent.order
        components[payload.swapComponentIndex].positionX =
          payload.component.positionX
        components[payload.swapComponentIndex].order = payload.component.order
        // if component has children, update their order
        if (components[payload.componentIndex].children) {
          components[payload.componentIndex].children?.forEach((id) => {
            const child = components.find((c) => c.id === id)
            const childIndex = components.findIndex((c) => c.id === child!.id)
            components[childIndex].order = child!.order.replace(
              child!.order.charAt(0),
              payload.swapComponent.order
            )
            // if child has paths, update their order
            if (child?.paths) {
              child.paths.forEach((id) => {
                const path = components.find((c) => c.id === id)
                const pathIndex = components.findIndex((c) => c.id === path!.id)
                components[pathIndex].order = path!.order.replace(
                  path!.order.charAt(0),
                  payload.swapComponent.order
                )
              })
            }
          })
        }
        // if swap component has children, update their order
        if (components[payload.swapComponentIndex].children) {
          components[payload.swapComponentIndex].children?.forEach((id) => {
            const child = components.find((c) => c.id === id)
            const childIndex = components.findIndex((c) => c.id === child!.id)
            components[childIndex].order = child!.order.replace(
              child!.order.charAt(0),
              payload.component.order
            )
            // if child has paths, update their order
            if (child?.paths) {
              child.paths.forEach((id) => {
                const path = components.find((c) => c.id === id)
                const pathIndex = components.findIndex((c) => c.id === path!.id)
                components[pathIndex].order = path!.order.replace(
                  path!.order.charAt(0),
                  payload.component.order
                )
              })
            }
          })
        }
        const sortedComponents = sortComponentsByOrder(components)
        draft.components = setComponentPositions(sortedComponents)
      })
    } // case SWAP_COMPONENTS

    case SWAP_CONTENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const { componentIndex } = getComponentAndIndex(
          state,
          payload.componentId
        )
        if (componentIndex > -1) {
          draft.components[componentIndex].contents = payload.contents
        }
      })
    }

    case SWAP_CONTENT_TABLE: {
      const { payload } = action
      return produce(state, (draft) => {
        const { componentIndex } = getComponentAndIndex(
          state,
          payload.componentId
        )
        const { contentIndex } = getContentAndIndex(state, payload.contentId)
        if (componentIndex > -1 && contentIndex > -1) {
          draft.components[componentIndex].contents[contentIndex].actionItems =
            payload.contentTables
        }
      })
    }

    case ADD_NEW_CONTENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const { component, componentIndex } = getComponentAndIndex(
          state,
          payload.id
        )
        if (component && componentIndex > -1) {
          let updateContents = JSON.parse(JSON.stringify(component.contents))
          const newContent = {
            ...payload.value,
            id: uuid()
          }
          updateContents = updateContents.concat(newContent)
          draft.components[componentIndex].contents = updateContents
        }
      })
    }

    case REMOVE_CONTENT: {
      const { payload } = action
      return produce(state, (draft) => {
        const { contentIndex } = getContentAndIndex(state, payload.id)
        const { componentIndex } = getComponentAndIndex(state, payload.id)
        if (componentIndex > -1 && contentIndex > -1) {
          draft.components[componentIndex].contents = state.components[
            componentIndex
          ].contents.filter((c: Content) => c.id !== payload.id)
        }
      })
    }

    case ADD_CONTENT_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const { component, componentIndex } = getComponentAndIndex(
          state,
          payload.id
        )
        const { contentIndex } = getContentAndIndex(state, payload.id)
        if (component && componentIndex > -1 && contentIndex > -1) {
          const content = component.contents.find(
            (c: Content) => c.id === payload.id
          )

          if (content) {
            let contentTable = JSON.parse(
              JSON.stringify(content[payload.stateKey])
            )
            const newId: string = uuid()
            const newValue = {
              ...payload.value,
              id: newId,
              value: newId
            }
            contentTable = contentTable.concat(newValue)
            draft.components[componentIndex].contents[contentIndex][
              payload.stateKey
            ] = contentTable
          }
        }
      })
    }

    case EDIT_CONTENT_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const { componentIndex } = getComponentAndIndex(
          state,
          payload.contentId
        )
        const { contentIndex } = getContentAndIndex(state, payload.contentId)

        if (componentIndex > -1 && contentIndex > -1) {
          const value = state.components[componentIndex].contents[contentIndex][
            payload.stateKey
          ]!.find((v: ContentTableItem) => v.id === payload.contentTableId)

          if (value) {
            const newValue = JSON.parse(JSON.stringify(value))
            newValue[payload.stateAdditionalKey] = payload.value

            draft.components[componentIndex].contents[contentIndex][
              payload.stateKey
            ] = draft.components[componentIndex].contents[contentIndex][
              payload.stateKey
            ]!.map((c: ContentTableItem) =>
              c.id === payload.contentTableId ? newValue : c
            )
          }
        }
      })
    }

    case REMOVE_CONTENT_TABLE_VALUE: {
      const { payload } = action
      return produce(state, (draft) => {
        const { componentIndex } = getComponentAndIndex(
          state,
          payload.contentId
        )
        const { contentIndex } = getContentAndIndex(state, payload.contentId)

        if (componentIndex > -1 && contentIndex > -1) {
          draft.components[componentIndex].contents[contentIndex][
            payload.stateKey
          ] = state.components[componentIndex].contents[contentIndex][
            payload.stateKey
          ]!.filter((v: ContentTableItem) => v.id !== payload.contentTableId)
        }
      })
    }

    default:
      return state
  }
}

export default componentSettings
