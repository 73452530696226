import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import StepList from 'components/common/StepList'
import { AppState, ContentItem } from 'types/types'
import { addNewContent } from 'store/actions/componentSettings'
import { componentToModify } from 'store/actions/componentModify'
import {
  feedItem,
  headingItem,
  smallPrintItem,
  buttonItem,
  actionAreaItem,
  dataDisplayItem,
  catalog,
  svgItem,
  loginItem,
  datePickerItem,
  productViewItem,
  onboardingItem,
  errorPageItem,
  searchDialogItem
} from 'util/common'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px'
  },
  sectionSeparator: {
    marginBottom: '8px',
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const AddNewComponentList: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentType, setComponentType] = useState<string>('')
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )

  useEffect(() => {
    const findComponent = components.find((c) => c.id === modifyId)
    if (findComponent) {
      setComponentType(findComponent.type)
    }
  }, [components, modifyId])

  const addComponent = (item: ContentItem) => {
    dispatch(addNewContent(item, modifyId))
    dispatch(componentToModify(true, false, modifyId, 'All screens'))
  }

  return (
    <div className={classes.container}>
      {componentType === 'Status' && (
        <Paper className={classes.sectionSeparator} elevation={2}>
          <StepList
            icon="DynamicFeed"
            title="Feed"
            actionIcon="AddCircleOutline"
            onClickComponent={() => addComponent(feedItem)}
          />
        </Paper>
      )}
      {componentType === 'Play' && (
        <>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="TextFormat"
              title="Heading"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(headingItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="PlayCircleFilled"
              title="CTA Button"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(buttonItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="RadioButtonChecked"
              title="Action area"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(actionAreaItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Storage"
              title="Data display"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(dataDisplayItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Info"
              title="Small print"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(smallPrintItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="ViewStream"
              title="Catalog"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(catalog)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="LockOpen"
              title="Login"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(loginItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Today"
              title="Date picker"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(datePickerItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="FeaturedPlayList"
              title="Product View"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(productViewItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="SwitchCamera"
              title="Onboarding"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(onboardingItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="ErrorOutline"
              title="Error Page"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(errorPageItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Search"
              title="Search Dialog"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(searchDialogItem)}
            />
          </Paper>
        </>
      )}
      {componentType === 'View' && (
        <>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="TextFormat"
              title="Heading"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(headingItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="PlayCircleFilled"
              title="CTA Button"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(buttonItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="LockOpen"
              title="Login"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(loginItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Storage"
              title="Data display"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(dataDisplayItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="ViewStream"
              title="Catalog"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(catalog)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="FeaturedPlayList"
              title="Product View"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(productViewItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="SwitchCamera"
              title="Onboarding"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(onboardingItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="ErrorOutline"
              title="Error Page"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(errorPageItem)}
            />
          </Paper>
          <Paper className={classes.sectionSeparator} elevation={2}>
            <StepList
              icon="Search"
              title="Search Dialog"
              actionIcon="AddCircleOutline"
              onClickComponent={() => addComponent(searchDialogItem)}
            />
          </Paper>
        </>
      )}
      <Paper className={classes.sectionSeparator} elevation={2}>
        <StepList
          icon="ImageAspectRatio"
          title="Custom Svg"
          actionIcon="AddCircleOutline"
          onClickComponent={() => addComponent(svgItem)}
        />
      </Paper>
    </div>
  )
}

export default AddNewComponentList
