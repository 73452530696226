import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import BoxWithPadding from 'components/common/BoxWithPadding'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
  listItemStyles: {
    paddingLeft: '0px'
  },
  checkboxStyles: {
    minWidth: '42px'
  },
  titleOpacity: {
    color: theme.palette.text.primary
  },
  secondaryTextStyles: {
    fontStyle: theme.typography.body2.fontStyle,
    fontWeight: theme.typography.body2.fontWeight,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    color: theme.typography.caption.color
  },
  secondaryItemStyles: {
    right: '0px'
  },
  moveUp: {
    top: '28%'
  },
  subtitleStyles: {
    fontStyle: theme.typography.caption.fontStyle,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: theme.typography.caption.letterSpacing,
    color: theme.typography.caption.color,
    alignItems: 'center'
  },
  switchStyles: {
    '& .MuiSwitch-switchBase.Mui-disabled': {
      color: '#FAFAFA'
    }
  },
  htmlLinkStyles: {
    '& a': {
      color: 'inherit',
      fontWeight: 'bold'
    }
  }
}))

const Selector: React.FC<{
  value?: boolean
  checked?: boolean
  onChange?: any
  checkbox?: boolean
  title?: string
  subtitle?: string
  disable?: boolean
}> = ({
  value = false,
  checked = false,
  onChange,
  checkbox,
  title,
  subtitle,
  disable
}) => {
  const classes = useStyles()

  const isCheckbox = checkbox || false

  const isDisabled = disable || false

  return (
    <>
      <BoxWithPadding>
        <List disablePadding>
          <ListItem classes={{ root: classes.listItemStyles }}>
            {isCheckbox ? (
              <ListItemIcon classes={{ root: classes.checkboxStyles }}>
                <Checkbox
                  edge="start"
                  color="primary"
                  disabled={isDisabled}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': `checkbox-list-label-${title}`
                  }}
                />
              </ListItemIcon>
            ) : null}
            <ListItemText
              classes={{
                secondary: classes.subtitleStyles
              }}
              primary={
                <>
                  {title ? (
                    <Typography
                      variant="subtitle1"
                      className={clsx(
                        classes.titleOpacity,
                        classes.htmlLinkStyles
                      )}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  ) : null}
                </>
              }
              secondary={subtitle}
            />
            <ListItemSecondaryAction
              classes={{
                root: clsx(classes.secondaryItemStyles, {
                  [classes.moveUp]: subtitle
                })
              }}
            >
              {!isCheckbox && (
                <Switch
                  edge="end"
                  color="primary"
                  className={classes.switchStyles}
                  disabled={isDisabled}
                  onChange={onChange}
                  checked={checked}
                  value={value}
                  inputProps={{
                    'aria-labelledby': `switch-list-label-${title}`
                  }}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </BoxWithPadding>
    </>
  )
}

export default Selector
