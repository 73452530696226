import React, { Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '0px 16px 0px 16px'
  },
  primaryOpacity: {
    color: theme.palette.text.primary
  },
  secondaryOpacity: {
    color: theme.palette.text.secondary
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const CollapseList: React.FC<{
  expanded: boolean
  setExpanded: Dispatch<SetStateAction<boolean>>
  primary: string
  secondary?: string
}> = ({ expanded, setExpanded, primary, secondary }) => {
  const classes = useStyles()

  return (
    <List disablePadding>
      <ListItem onClick={() => setExpanded(!expanded)}>
        <ListItemText
          primary={
            <Typography className={classes.primaryOpacity} variant="subtitle1">
              {primary}
            </Typography>
          }
          secondary={
            <Typography className={classes.secondaryOpacity} variant="body2">
              {secondary}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

export default CollapseList
