import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import { ChromePicker } from 'react-color'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  },
  colorPicker: {
    display: 'flex',
    padding: '8px 0px'
  }
}))

const OnboardingSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [colorExpanded, setColorExpanded] = useState<boolean>(false)
  const [
    backgroundColorExpanded,
    setBackgroundColorExpanded
  ] = useState<boolean>(false)

  const [image, setImage] = useState<string | undefined>('')
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>('')
  const [title, setTitle] = useState<string | undefined>('')
  const [subtitle, setSubtitle] = useState<string | undefined>('')
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState<
    string | undefined
  >('')
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState<
    string | undefined
  >('')
  const [color, setColor] = useState<string>('')
  const [backgroundColor, setBackgroundColor] = useState<string>('')

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setImage(content.image)
          setBackgroundImage(content.backgroundImage)
          setTitle(content.title)
          setSubtitle(content.subtitle)
          setPrimaryButtonLabel(content.label)
          setSecondaryButtonLabel(content.secondaryLabel)
          setColor(content.color || '')
          setBackgroundColor(content.backgroundColor || '')
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'image') setImage(value)
    if (type === 'backgroundImage') setBackgroundImage(value)
    if (type === 'title') setTitle(value)
    if (type === 'subtitle') setSubtitle(value)
    if (type === 'label') setPrimaryButtonLabel(value)
    if (type === 'secondaryLabel') setSecondaryButtonLabel(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  const changeColor = (color: string, type: ContentKey) => {
    let value: string = color || ''
    if (value.charAt(0) !== '#') {
      value = `#${value}`
    }
    if (type === 'color') setColor(value)
    if (type === 'backgroundColor') setBackgroundColor(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  const getStyle = (colorType: string) => {
    return {
      alignSelf: 'flex-start',
      marginLeft: '16px',
      minHeight: '54px',
      minWidth: '54px',
      borderRadius: '50%',
      cursor: 'pointer',
      border: '1px solid black',
      backgroundColor: colorType
    }
  }

  return (
    <>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Color"
                  value={color}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeColor(e.target.value, 'color')
                  }
                  suffixIcon={colorExpanded ? 'ExpandLess' : 'ExpandMore'}
                  suffixOnClick={() => setColorExpanded(!colorExpanded)}
                />
              </div>
              <Collapse in={colorExpanded} timeout="auto" unmountOnExit>
                <ChromePicker
                  color={color}
                  onChange={(color) => changeColor(color.hex, 'color')}
                />
              </Collapse>
            </div>
            <div
              onClick={() => setColorExpanded(!colorExpanded)}
              style={getStyle(color)}
            />
          </div>
          <div className={classes.colorPicker}>
            <div>
              <div style={{ width: '225px' }}>
                <Input
                  label="Background color"
                  value={backgroundColor}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeColor(e.target.value, 'backgroundColor')
                  }
                  suffixIcon={
                    backgroundColorExpanded ? 'ExpandLess' : 'ExpandMore'
                  }
                  suffixOnClick={() =>
                    setBackgroundColorExpanded(!backgroundColorExpanded)
                  }
                />
              </div>
              <Collapse
                in={backgroundColorExpanded}
                timeout="auto"
                unmountOnExit
              >
                <ChromePicker
                  color={backgroundColor}
                  onChange={(color) =>
                    changeColor(color.hex, 'backgroundColor')
                  }
                />
              </Collapse>
            </div>
            <div
              onClick={() =>
                setBackgroundColorExpanded(!backgroundColorExpanded)
              }
              style={getStyle(backgroundColor)}
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Image"
              placeholder="Image"
              name="image"
              prefixIcon="Image"
              value={image}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'image')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Background image"
              placeholder="Background image"
              name="background image"
              prefixIcon="ImageOutlined"
              value={backgroundImage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'backgroundImage')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Title"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'title')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Subtitle"
              name="subtitle"
              value={subtitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'subtitle')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="First button label"
              name="First button"
              value={primaryButtonLabel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'label')
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Second button label"
              name="Second button"
              value={secondaryButtonLabel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'secondaryLabel')
              }
            />
          </div>
          <Input
            label="CTA direction"
            options={[
              { value: 'row', label: 'Row' },
              { value: 'row-reverse', label: 'Row reverse' },
              { value: 'column', label: 'Column' },
              { value: 'column-reverse', label: 'Column reverse' }
            ]}
            value={content.ctaDirection}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(
                changeValueContent(e.target.value, 'ctaDirection', modifyId)
              )
            }
          />
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default OnboardingSettings
