import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Collapse from '@material-ui/core/Collapse'
import Input from 'components/common/Input'
import CollapseList from 'components/common/CollapseList'
import { Button } from 'uniqore-components'
import { changeSetting } from 'store/actions/componentSettings'
import { changeOther } from 'store/actions/other'
import { AppState } from 'types/types'

const useStyles = makeStyles((theme) => ({
  collapseItems: {
    padding: '0px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const SettingsActions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { clientName, serviceName } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const { settings } = useSelector((state: AppState) => state.other)

  return (
    <div>
      <CollapseList
        expanded={settings.detailsExpand}
        setExpanded={() =>
          dispatch(
            changeOther(!settings.detailsExpand, 'settings', 'detailsExpand')
          )
        }
        primary="Base details"
        secondary={clientName}
      />
      <Collapse in={settings.detailsExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="Client name"
              error={settings.detailsClientHelperText !== ''}
              helperText={settings.detailsClientHelperText}
              value={clientName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(changeSetting(e.target.value, 'clientName'))
              }
            />
          </div>
          <div className={classes.inputPadding}>
            <Input
              label="Service name"
              error={settings.detailsServiceHelperText !== ''}
              helperText={settings.detailsServiceHelperText}
              value={serviceName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(changeSetting(e.target.value, 'serviceName'))
              }
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disablePadding
              color="secondary"
              label="SAVE"
              onClick={() => dispatch(changeOther(true, 'saveConcept'))}
            />
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default SettingsActions
