import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import Selector from 'components/common/Selector'
import StepList from 'components/common/StepList'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputPadding: {
    paddingBottom: '14px'
  }
}))

const HeadingSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [title, setTitle] = useState<string | undefined>('')
  const [body, setBody] = useState<string | undefined>('')

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setTitle(content.title)
          setBody(content.body)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'title') setTitle(value)
    if (type === 'body') setBody(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  return (
    <>
      <div className={classes.selectContainer}>
        <Input
          label="Type"
          options={[
            { value: 'Title', label: 'Title' },
            { value: 'Title + Body', label: 'Title + Body' }
          ]}
          value={content.selection}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeInput(e.target.value, 'selection')
          }
        />
      </div>
      <CollapseList
        expanded={contentExpand}
        setExpanded={setContentExpand}
        primary="Content"
      />
      <Collapse in={contentExpand} timeout="auto" unmountOnExit>
        <div className={classes.collapseItems}>
          <div className={classes.inputPadding}>
            <Input
              label="Title"
              name="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'title')
              }
            />
          </div>
          {content.selection === 'Title + Body' && (
            <div className={classes.inputPadding}>
              <Input
                label="Body"
                rows={5}
                value={body}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'body')
                }
              />
            </div>
          )}
          <Selector
            title="Align text"
            checked={content.textLeftAlign}
            onChange={() =>
              dispatch(
                changeValueContent(
                  !content.textLeftAlign,
                  'textLeftAlign',
                  modifyId
                )
              )
            }
          />
        </div>
      </Collapse>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default HeadingSettings
