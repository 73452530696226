import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import Paper from '@material-ui/core/Paper'
import StepList from 'components/common/StepList'
import TypeListSettings from 'components/ComponentSettings/TypeListSettings'
import { listItem, changeArrayOrder } from 'util/common'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  addContentTableValue,
  changeValueContent,
  editContentTableValue,
  removeContent,
  swapContentTable
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '24px 16px 24px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collapseItems: {
    padding: '8px 16px 16px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  sectionSeparator: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  noContent: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  }
}))

interface Props {
  view: string
  setView: Dispatch<SetStateAction<string>>
}

const DataDisplaySettings: React.FC<Props> = ({ view, setView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [selectType, setSelectType] = useState<string>('')
  const [itemId, setItemId] = useState<string>('')
  const [content, setContent] = useState<Content | undefined>(undefined)
  const [contentExpand, setContentExpand] = useState<boolean>(true)
  const [styleExpand, setStyleExpand] = useState<boolean>(false)
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    dispatch(changeValueContent(value, type, modifyId))
  }

  const setContentTableVisibility = (id: string, stateKey: 'actionItems') => {
    const tableItem = content.actionItems?.find((v) => v.id === id)
    if (tableItem) {
      const value = !tableItem.visible
      dispatch(editContentTableValue(value, stateKey, 'visible', modifyId, id))
    }
  }

  const findItemOrderNumber = (id: string) => {
    let index = -1
    if (content.actionItems) {
      index = content.actionItems?.findIndex((v) => v.id === id)
    }
    return index + 1
  }

  const editActionItem = (id: string, type: string) => {
    const findActionItem = content.actionItems?.find((c) => c.id === id)
    if (findActionItem) {
      const orderNr = findItemOrderNumber(id)
      if (type === 'list') {
        setView(`List ${orderNr}`)
      }
      setItemId(findActionItem.id)
      setSelectType(type)
    }
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  const handleOrderUp = (id: string) => {
    if (content.actionItems) {
      const itemIndex = content.actionItems.findIndex((v) => v.id === id)
      if (itemIndex > 0) {
        const changeItems = JSON.parse(JSON.stringify(content.actionItems))
        changeArrayOrder(changeItems, itemIndex, itemIndex - 1)
        dispatch(swapContentTable(changeItems, componentId, content.id))
      }
    }
  }

  const handleOrderDown = (id: string) => {
    if (content.actionItems) {
      const itemIndex = content.actionItems.findIndex((v) => v.id === id)
      if (itemIndex !== content.actionItems.length - 1) {
        const changeItems = JSON.parse(JSON.stringify(content.actionItems))
        changeArrayOrder(changeItems, itemIndex, itemIndex + 1)
        dispatch(swapContentTable(changeItems, componentId, content.id))
      }
    }
  }

  return (
    <>
      {view === '' && (
        <div>
          <div className={classes.selectContainer}>
            <Input
              label="Type"
              options={[{ value: 'List', label: 'List' }]}
              value={content.selection}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'selection')
              }
            />
          </div>
          <CollapseList
            expanded={contentExpand}
            setExpanded={setContentExpand}
            primary="Content"
          />
          <Collapse in={contentExpand} timeout="auto" unmountOnExit>
            <div className={classes.collapseItems}>
              {content.selection === 'List' ? (
                <>
                  <Paper elevation={2}>
                    {content.actionItems?.map((item) => (
                      <div key={item.id} className={classes.sectionSeparator}>
                        <StepList
                          title={item.label}
                          subtitle="List item"
                          actionIcon={
                            item.visible ? 'Visibility' : 'VisibilityOff'
                          }
                          highElement
                          onClick={() => editActionItem(item.id, 'list')}
                          onClickAction={() =>
                            setContentTableVisibility(item.id, 'actionItems')
                          }
                          showOrderIcons
                          onClickOrderUp={() => handleOrderUp(item.id)}
                          onClickOrderDown={() => handleOrderDown(item.id)}
                        />
                      </div>
                    ))}
                  </Paper>
                  <Paper elevation={2}>
                    <div style={{ backgroundColor: '#FAFAFA' }}>
                      <StepList
                        icon="Add"
                        iconColor="#1E88E5"
                        title="Add list item"
                        titleColor="#1E88E5"
                        highElement
                        onClick={() =>
                          dispatch(
                            addContentTableValue(
                              listItem,
                              'actionItems',
                              modifyId
                            )
                          )
                        }
                      />
                    </div>
                  </Paper>
                </>
              ) : null}
            </div>
          </Collapse>
          <CollapseList
            expanded={styleExpand}
            setExpanded={setStyleExpand}
            primary="Style"
          />
          <Collapse in={styleExpand} timeout="auto" unmountOnExit>
            <div className={classes.collapseItems}>
              <Typography variant="body2" className={classes.noContent}>
                No styles to show
              </Typography>
            </div>
          </Collapse>
          <CollapseList
            expanded={otherExpand}
            setExpanded={setOtherExpand}
            primary="Other"
          />
          <Collapse in={otherExpand} timeout="auto" unmountOnExit>
            <div>
              <StepList
                icon="Delete"
                iconColor="#E53935"
                title="Delete component"
                titleColor="#E53935"
                onClick={deleteComponent}
              />
            </div>
          </Collapse>
        </div>
      )}
      {view !== '' && selectType === 'list' && (
        <TypeListSettings content={content} itemId={itemId} setView={setView} />
      )}
    </>
  )
}

export default DataDisplaySettings
