import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Icons from 'components/common/Icons'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import ComponentValues from 'components/blocks/ComponentValues'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Button } from 'uniqore-components'
import { Content, Component, ComponentKey, HeaderAndFooter } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  editComponent,
  changeValueContent,
  deleteComponent,
  changeValueHeaderFooter,
  swapContent
} from 'store/actions/componentSettings'
import { changeArrayOrder } from 'util/common'
import { AppState } from 'types/types'

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    color: theme.palette.text.primary
  },
  edits: {
    padding: '0px 16px 16px 16px'
  },
  contentContainer: {
    padding: '16px 16px 24px 16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    padding: '24px 0px'
  },
  sectionSeparator: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  dialogTitle: {
    padding: '16px 16px 0px 24px',
    color: theme.palette.text.primary
  },
  dialogActions: {
    padding: '12px 24px'
  }
}))

const ComponentContents: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [component, setComponent] = useState<Component | undefined>(undefined)
  const [contents, setContents] = useState<Content[]>()
  const [editExpand, setEditExpand] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [componentToDelete, setComponentToDelete] = useState<Component>()
  const [view, setView] = useState<string>('')
  const { modifyName, modifyId, showValues } = useSelector(
    (state: AppState) => state.componentModify
  )
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )

  // find component and its contents
  useEffect(() => {
    let componentFound = false
    const findComponent = components.find((c) => c.id === modifyId)
    if (findComponent) {
      setComponent(findComponent)
      setContents(findComponent.contents)
      componentFound = true
    } else {
      components.forEach((component) => {
        component.contents.forEach((content) => {
          if (content.id === modifyId) {
            setComponent(component)
            componentFound = true
          }
        })
      })
    }
    if (!componentFound) {
      let findComponent = components.find((c) => c.header.id === modifyId)
      if (findComponent) {
        setComponent(findComponent)
      } else {
        findComponent = components.find((c) => c.footer.id === modifyId)
        setComponent(findComponent)
      }
    }
  }, [components, modifyId])

  // needed when clicking components on canvas, otherwise shows
  // blank screen if action area or data display item selected
  useEffect(() => {
    setView('')
  }, [modifyId])

  if (!component) {
    return null
  }

  const findContent = (id: string, name: string) => {
    if (contents) {
      return contents.find((v) => v.id === id && v.name === name)
    }
    return undefined
  }

  const handleClickHeaderAndFooter = (id: string, type: string) => {
    dispatch(componentToModify(true, true, id, type))
  }

  const handleClickContent = (id: string, name: string) => {
    if (findContent(id, name)) {
      dispatch(changeValueContent(true, 'visible', id))
      dispatch(componentToModify(true, true, id, name))
    }
  }

  const setVisibilityHeaderFooter = (
    headerOrFooter: HeaderAndFooter,
    type: ComponentKey
  ) => {
    dispatch(
      changeValueHeaderFooter(
        !headerOrFooter.visible,
        type,
        'visible',
        headerOrFooter.id
      )
    )
  }

  const handleClickAddComponent = () => {
    dispatch(componentToModify(true, true, component.id, 'Add component'))
  }

  const setVisibility = (id: string, name: string) => {
    const content = findContent(id, name)
    if (content) {
      const value = !content.visible
      dispatch(changeValueContent(value, 'visible', id))
    }
  }

  const handleGoBack = () => {
    if (modifyName === 'All screens') {
      dispatch(componentToModify(false, false, '', ''))
    } else if (view !== '') {
      setView('')
    } else {
      dispatch(componentToModify(true, false, component.id, 'All screens'))
    }
  }

  const handleOrderUp = (content: Content) => {
    if (contents) {
      const contentIndex = contents.findIndex((c) => c.id === content.id)
      if (content.name === 'Feed') {
        if (contentIndex > 1) {
          const changeContents = JSON.parse(JSON.stringify(contents))
          changeArrayOrder(changeContents, contentIndex, contentIndex - 1)
          dispatch(swapContent(changeContents, component.id))
        }
      } else if (contentIndex > 0) {
        const changeContents = JSON.parse(JSON.stringify(contents))
        changeArrayOrder(changeContents, contentIndex, contentIndex - 1)
        dispatch(swapContent(changeContents, component.id))
      }
    }
  }

  const handleOrderDown = (content: Content) => {
    if (contents) {
      const contentIndex = contents.findIndex((c) => c.id === content.id)
      if (contentIndex !== contents.length - 1) {
        const changeContents = JSON.parse(JSON.stringify(contents))
        changeArrayOrder(changeContents, contentIndex, contentIndex + 1)
        dispatch(swapContent(changeContents, component.id))
      }
    }
  }

  const deleteScreen = (id: string, fromDialog: boolean) => {
    if (fromDialog) {
      dispatch(deleteComponent(id))
      dispatch(componentToModify(false, false, '', ''))
      setComponentToDelete(undefined)
      setDialogOpen(false)
    } else {
      const component = components.find((c) => c.id === id)
      if (component) {
        if (
          (component.children && component.children.length > 0) ||
          (component.paths && component.paths.length > 0)
        ) {
          setComponentToDelete(component)
          setDialogOpen(true)
        } else {
          dispatch(deleteComponent(id))
          dispatch(componentToModify(false, false, '', ''))
          setComponentToDelete(undefined)
          setDialogOpen(false)
        }
      }
    }
  }

  return (
    <>
      <div className={classes.titleContainer}>
        <List>
          <ListItem>
            <ListItemIcon style={{ cursor: 'pointer' }} onClick={handleGoBack}>
              {React.createElement(Icons.ArrowBack)}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.titleText}>
                  {view ? view : modifyName}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </div>
      {showValues ? (
        <ComponentValues view={view} setView={setView} />
      ) : (
        <>
          <div>
            <StepList
              title={component.name || 'No name'}
              actionIcon="Edit"
              onClickAction={() => setEditExpand(!editExpand)}
            />
            <Collapse in={editExpand} timeout="auto" unmountOnExit>
              <div className={classes.edits}>
                <Input
                  label="Name"
                  value={component.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      editComponent(e.target.value, 'name', component.id)
                    )
                  }
                />
              </div>
            </Collapse>
          </div>
          <div className={classes.contentContainer}>
            <Paper elevation={2}>
              <StepList
                icon="WebAsset"
                title="Header"
                actionIcon={
                  component.header.visible ? 'Visibility' : 'VisibilityOff'
                }
                onClickAction={() =>
                  setVisibilityHeaderFooter(component.header, 'header')
                }
                onClick={() =>
                  handleClickHeaderAndFooter(component.header.id, 'Header')
                }
              />
            </Paper>
            <div className={classes.content}>
              <>
                <Paper elevation={2}>
                  {contents?.map((v, i) => (
                    <div key={i} className={classes.sectionSeparator}>
                      <StepList
                        icon={v.nameIcon}
                        title={v.name}
                        subtitle={v.selection}
                        actionIcon={
                          Object.prototype.hasOwnProperty.call(v, 'visible')
                            ? v.visible
                              ? 'Visibility'
                              : 'VisibilityOff'
                            : ''
                        }
                        highElement
                        onClick={() => handleClickContent(v.id, v.name)}
                        onClickAction={() => setVisibility(v.id, v.name)}
                        showOrderIcons={v.name !== 'Status'}
                        onClickOrderUp={() => handleOrderUp(v)}
                        onClickOrderDown={() => handleOrderDown(v)}
                      />
                    </div>
                  ))}
                </Paper>
                <Paper elevation={2}>
                  <div style={{ backgroundColor: '#FAFAFA' }}>
                    <StepList
                      icon="Add"
                      iconColor="#1E88E5"
                      title="Add component"
                      titleColor="#1E88E5"
                      highElement
                      onClick={handleClickAddComponent}
                    />
                  </div>
                </Paper>
              </>
            </div>
            <Paper elevation={2}>
              <StepList
                icon="CallToActionOutlined"
                title="Footer"
                actionIcon={
                  component.footer.visible ? 'Visibility' : 'VisibilityOff'
                }
                onClickAction={() =>
                  setVisibilityHeaderFooter(component.footer, 'footer')
                }
                onClick={() =>
                  handleClickHeaderAndFooter(component.footer.id, 'Footer')
                }
              />
            </Paper>
          </div>
          <div>
            <StepList
              icon="Delete"
              iconColor="#E53935"
              title="Delete screen"
              titleColor="#E53935"
              onClick={() => deleteScreen(component.id, false)}
            />
          </div>
        </>
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onBackdropClick={() => setDialogOpen(false)}
      >
        <div>
          <Typography variant="h5" className={classes.dialogTitle}>
            Are you sure?
          </Typography>
          <DialogContent>
            <Typography variant="subtitle1">
              If you delete this parent screen, all the linked child screens
              below will be removed too.
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
              label="CANCEL"
              variant="default"
              color="secondary"
              border
              onClick={() => setDialogOpen(false)}
              disablePadding
            />
            <Button
              label="DELETE SCREENS"
              variant="contained"
              color="secondary"
              onClick={() => deleteScreen(componentToDelete!.id, true)}
              disablePadding
            />
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default ComponentContents
