import produce from 'immer'
import { COMPONENT_TO_MODIFY } from 'store/actions/componentModify'
import { ComponentModifyActionTypes, ComponentModifyState } from 'types/types'

const initialState = {
  modifyingComponent: false,
  showValues: false,
  modifyId: '',
  modifyName: ''
}

const componentModify = (
  state = initialState,
  action: ComponentModifyActionTypes
): ComponentModifyState => {
  switch (action.type) {
    case COMPONENT_TO_MODIFY: {
      const { payload } = action
      return produce(state, (draft) => {
        draft.modifyingComponent = payload.modifyingComponent
        draft.showValues = payload.showValues
        draft.modifyId = payload.id
        draft.modifyName = payload.name
      })
    }
    default:
      return state
  }
}

export default componentModify
