/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/client'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { Slide } from '@material-ui/core'
import { Share, Search, Visibility, Clear } from '@material-ui/icons'
import SearchInput from 'components/common/SearchInput'
import { changeSetting } from 'store/actions/componentSettings'
import { changeSelected, handleShare } from 'store/actions/sidebar'
import { changeOther } from 'store/actions/other'
import { startPreview } from 'store/actions/preview'
import { Button } from 'uniqore-components'
import { ReactComponent as Logo } from 'assets/logo.svg'
import SpinnerLogoWhite from 'assets/spinner-logo-white.svg'
import SpinnerLogoDark from 'assets/spinner-logo-dark-60.svg'
import { AppState } from 'types/types'

const createEngagementQuery = gql`
  mutation createServiceEngagement(
    $serviceId: ID!
    $stage: String
    $metadata: [MetadataSerializerInput]
    $orgUsers: [String]
  ) {
    createServiceEngagement(
      serviceId: $serviceId
      data: { stage: $stage, metadata: $metadata, orgUsers: $orgUsers }
    ) {
      serviceEngagement {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const updateEngagementQuery = gql`
  mutation updateServiceEngagement(
    $id: ID!
    $stage: String
    $metadata: [MetadataSerializerInput]
  ) {
    updateServiceEngagement(
      id: $id
      data: { stage: $stage, metadata: $metadata }
    ) {
      serviceEngagement {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  appbarStyles: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarStyles: {
    height: '63px',
    paddingLeft: 0,
    paddingRight: 0
  },
  logoContainer: {
    width: '63px',
    borderRight: `1px solid ${theme.palette.divider}`
  },
  logoBackground: {
    backgroundColor: 'rgba(0, 44, 56, 0.87)',
    height: '63px',
    width: '62px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    position: 'relative',
    height: '63px',
    width: '100%'
  },
  titleText: {
    color: theme.palette.text.primary,
    fontSize: 16
  },
  titleSub: {
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  button: {
    marginRight: '16px'
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    right: '240px',
    top: '15px'
  },
  icons: {
    width: '64px'
  },
  searchContainer: {
    position: (props: { searchOpen: boolean }) =>
      props.searchOpen ? 'absolute' : 'relative',
    top: 0,
    left: 0,
    width: '100%',
    background: 'rgba(0, 44, 56, 0.87)',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    zIndex: 99
  },
  resultContainer: {
    display: (props: { searchOpen: boolean }) =>
      props.searchOpen ? 'none' : 'flex',
    flexGrow: 1,
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    zIndex: 9,
    paddingLeft: theme.spacing(2)
  }
}))

const Navbar: React.FC = () => {
  const dispatch = useDispatch()
  const [buttonLabel, setButtonLabel] = useState<string>('SAVE')
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string> | null>(null)
  const { saveConcept, servicesLoading } = useSelector(
    (state: AppState) => state.other
  )

  const classes = useStyles({ searchOpen: searchOpen })

  const componentSettings = useSelector(
    (state: AppState) => state.componentSettings
  )

  const endpointName =
    componentSettings.id === ''
      ? 'createServiceEngagement'
      : 'updateServiceEngagement'

  const [
    createUpdateServiceEngagement,
    { data: mutationData, loading: mutationLoading }
  ] = useMutation(
    componentSettings.id === '' ? createEngagementQuery : updateEngagementQuery,
    {
      onCompleted(mutationResult) {
        if (mutationResult && !mutationResult[endpointName].errors) {
          setSaveSuccessful(true)
          dispatch(
            changeSetting(
              mutationResult[endpointName].serviceEngagement.id,
              'id'
            )
          )
        } else {
          setErrors(mutationData[endpointName].errors)
        }
      }
    }
  )

  const getTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    })
  }

  useEffect(() => {
    if (saveSuccessful) {
      const time = getTime()
      setButtonLabel(`SAVED ${time}`)
      setSaveSuccessful(false)
    }
  }, [saveSuccessful])

  const handleSave = () => {
    if (
      componentSettings.clientName === '' ||
      componentSettings.serviceName === ''
    ) {
      dispatch(changeSelected('Settings', 'sidebarTop', true))
      dispatch(changeOther(true, 'settings', 'detailsExpand'))
      if (componentSettings.clientName === '') {
        dispatch(
          changeOther(
            'Add client name before saving',
            'settings',
            'detailsClientHelperText'
          )
        )
      } else {
        dispatch(changeOther('', 'settings', 'detailsClientHelperText'))
      }
      if (componentSettings.serviceName === '') {
        dispatch(
          changeOther(
            'Add service name before saving',
            'settings',
            'detailsServiceHelperText'
          )
        )
      } else {
        dispatch(changeOther('', 'settings', 'detailsServiceHelperText'))
      }
      return
    } else {
      dispatch(changeOther('', 'settings', 'detailsClientHelperText'))
      dispatch(changeOther('', 'settings', 'detailsServiceHelperText'))
    }

    const variables: {
      id?: string
      stage: string
      serviceId?: string
      metadata: Array<{ key: string; value: any; visible: string }>
      orgUsers?: Array<string | null>
    } = {
      stage: 'Saved',
      metadata: [
        {
          key: 'concept',
          value: componentSettings,
          visible: 'SHARE'
        },
        {
          key: 'service_name',
          value: componentSettings.serviceName,
          visible: 'SHARE'
        },
        {
          key: 'client_name',
          value: componentSettings.clientName,
          visible: 'SHARE'
        }
      ]
    }

    if (componentSettings.id !== '') {
      variables['id'] = componentSettings.id
    } else {
      variables['serviceId'] = process.env.REACT_APP_SERVICE_ID
      variables['orgUsers'] = [localStorage.getItem('userId')]
    }

    createUpdateServiceEngagement({
      variables: variables
    }).catch((err: any) => console.error(err))
  }

  useEffect(() => {
    if (saveConcept) {
      handleSave()
      dispatch(changeOther(false, 'saveConcept'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveConcept])

  return (
    <AppBar
      elevation={0}
      classes={{
        colorPrimary: classes.appbarStyles
      }}
      position="absolute"
    >
      <Toolbar className={classes.toolbarStyles}>
        <div className={classes.logoContainer}>
          <div className={classes.logoBackground}>
            {servicesLoading ? (
              <object style={{ width: '30px' }} data={SpinnerLogoWhite} />
            ) : (
              <Logo />
            )}
          </div>
        </div>
        <div className={classes.title}>
          <Slide direction="down" in={searchOpen}>
            <div className={classes.searchContainer}>
              <SearchInput
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
              <div className={classes.icons}>
                <IconButton
                  edge="end"
                  onClick={() => setSearchOpen(!searchOpen)}
                >
                  <Clear style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
          </Slide>

          <div className={classes.resultContainer}>
            {componentSettings.id ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" className={classes.titleText}>
                  {componentSettings.clientName
                    ? componentSettings.clientName
                    : ''}
                </Typography>
                <Typography variant="body2" className={classes.titleSub}>
                  {componentSettings.serviceName
                    ? componentSettings.serviceName
                    : ''}
                </Typography>
              </div>
            ) : (
              <div />
            )}
            <div style={{ display: 'flex' }}>
              <div className={classes.button}>
                {mutationLoading && (
                  <object
                    className={classes.spinnerOnButton}
                    data={SpinnerLogoDark}
                  />
                )}
                <Button
                  label={errors ? 'Not saved, try again' : buttonLabel}
                  icon={buttonLabel.substring(0, 5) === 'SAVED' ? 'Done' : ''}
                  onClick={handleSave}
                  color="secondary"
                  variant="contained"
                  shadow
                  disabled={mutationLoading}
                  disablePadding
                />
              </div>
              <div className={classes.icons}>
                <IconButton onClick={() => dispatch(handleShare())}>
                  <Share />
                </IconButton>
              </div>
              <div className={classes.icons}>
                <IconButton onClick={() => dispatch(startPreview())} edge="end">
                  <Visibility />
                </IconButton>
              </div>
              <div className={classes.icons}>
                <IconButton
                  edge="end"
                  onClick={() => {
                    setSearchOpen(!searchOpen)
                  }}
                >
                  <Search />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default Navbar
