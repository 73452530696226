import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Selector from 'components/common/Selector'
import Input from 'components/common/Input'
import StepList from 'components/common/StepList'
import {
  AppState,
  HeaderAndFooter,
  HeaderAndFooterKey,
  Component
} from 'types/types'
import { changeValueHeaderFooter } from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  content: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  inputStyles: {
    padding: '16px 16px 8px 16px'
  },
  selector: {
    padding: '8px 16px'
  },
  colorPickerStyles: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  separator: {
    paddingTop: '4px',
    paddingBottom: '4px'
  }
}))

const HeaderSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [component, setComponent] = useState<Component | undefined>(undefined)
  const [header, setHeader] = useState<HeaderAndFooter | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [text, setText] = useState<string | undefined>('')
  const [mainAction, setMainAction] = useState<string | undefined>('')

  useEffect(() => {
    let findComponent = components.find((c) => c.header.id === modifyId)
    if (findComponent) {
    } else {
      findComponent = components.find((c) => c.id === modifyId)
    }
    setComponent(findComponent)
    setHeader(findComponent?.header)
    setText(findComponent?.header.text)
    setMainAction(findComponent?.header.mainAction)
  }, [components, modifyId])

  if (!component || !header) {
    return null
  }

  const handleChangeInput = (value: string, type: HeaderAndFooterKey) => {
    if (type === 'text') setText(value)
    if (type === 'mainAction') setMainAction(value)
    dispatch(changeValueHeaderFooter(value, 'header', type, modifyId))
  }

  const hideComponent = (value: boolean) => {
    const newValue = !value
    dispatch(changeValueHeaderFooter(newValue, 'header', 'visible', modifyId))
  }

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.inputStyles}>
          <Input
            label="Text"
            value={text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'text')
            }
          />
        </div>
        <div className={classes.inputStyles}>
          <Input
            label="Main icon"
            prefixIcon="Image"
            value={mainAction}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'mainAction')
            }
          />
        </div>
        <div className={classes.inputStyles}>
          <Input
            label="Secondary icons"
            options={[
              { value: 'HelpOutline', label: 'Help' },
              { value: 'Search', label: 'Search' },
              { value: 'MoreVert', label: 'More' }
            ]}
            multiple
            valueMultiple={header.actions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'actions')
            }
          />
        </div>
        <div className={classes.selector}>
          <Selector
            title="Text align midde"
            checked={header.centerText}
            onChange={() =>
              dispatch(
                changeValueHeaderFooter(
                  !header.centerText,
                  'header',
                  'centerText',
                  modifyId
                )
              )
            }
          />
        </div>
        <div className={classes.separator}>
          <StepList
            title="Border"
            subtitle={header.border ? 'On' : 'Off'}
            actionIcon={header.border ? 'Visibility' : 'VisibilityOff'}
            onClickComponent={() =>
              dispatch(
                changeValueHeaderFooter(
                  !header.border,
                  'header',
                  'border',
                  modifyId
                )
              )
            }
          />
        </div>
      </div>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon={header.visible ? 'Visibility' : 'VisibilityOff'}
            title={header.visible ? 'Hide header' : 'Show header'}
            onClick={() => hideComponent(header.visible)}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default HeaderSettings
