import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  -webkit-tap-highlight-color: transparent;
`
const Wrapper: React.FC<{
  children?: ReactNode
  style?: any
  className?: any
}> = ({ children, style, className }) => {
  return (
    <Container style={style} className={className}>
      {children}
    </Container>
  )
}

export default Wrapper
