import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import StepList from 'components/common/StepList'
import Input from 'components/common/Input'
import Selector from 'components/common/Selector'
import { speedDialItem } from 'util/common'
import { RemoveCircleOutline } from '@material-ui/icons'
import {
  AppState,
  Component,
  HeaderAndFooter,
  HeaderAndFooterKey
} from 'types/types'
import {
  changeValueHeaderFooter,
  addHeaderFooterTableValue,
  editHeaderFooterTableValue,
  removeHeaderFooterTableValue
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  separator: {
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  inputGroup: {
    display: 'flex',
    padding: '16px 8px 16px 8px'
  },
  footerPlayInput: {
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  footerViewInput: {
    padding: '8px 16px 8px 16px'
  },
  footerTabSelector: {
    padding: '0px 16px 8px 16px',
    marginTop: '-12px'
  }
}))

const FooterSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [component, setComponent] = useState<Component | undefined>(undefined)
  const [footer, setFooter] = useState<HeaderAndFooter | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [backLabel, setBackLabel] = useState<string | undefined>('')
  const [backIcon, setBackIcon] = useState<string | undefined>('')
  const [nextLabel, setNextLabel] = useState<string | undefined>('')
  const [nextIcon, setNextIcon] = useState<string | undefined>('')
  const [firstTabLabel, setFirstTabLabel] = useState<string | undefined>('')
  const [firstTabIcon, setFirstTabIcon] = useState<string | undefined>('')
  const [secondTabLabel, setSecondTabLabel] = useState<string | undefined>('')
  const [secondTabIcon, setSecondTabIcon] = useState<string | undefined>('')
  const [thirdTabLabel, setThirdTabLabel] = useState<string | undefined>('')
  const [thirdTabIcon, setThirdTabIcon] = useState<string | undefined>('')
  const [fourthTabLabel, setFourthTabLabel] = useState<string | undefined>('')
  const [fourthTabIcon, setFourthTabIcon] = useState<string | undefined>('')
  const [fifthTabLabel, setFifthTabLabel] = useState<string | undefined>('')
  const [fifthTabIcon, setFifthTabIcon] = useState<string | undefined>('')

  useEffect(() => {
    let findComponent = components.find((c) => c.footer.id === modifyId)
    if (findComponent) {
    } else {
      findComponent = components.find((c) => c.id === modifyId)
    }
    setComponent(findComponent)
    setFooter(findComponent?.footer)
    setBackLabel(findComponent?.footer.backLabel)
    setBackIcon(findComponent?.footer.backIcon)
    setNextLabel(findComponent?.footer.nextLabel)
    setNextIcon(findComponent?.footer.nextIcon)
    setFirstTabLabel(findComponent?.footer.firstTabLabel)
    setFirstTabIcon(findComponent?.footer.firstTabIcon)
    setSecondTabLabel(findComponent?.footer.secondTabLabel)
    setSecondTabIcon(findComponent?.footer.secondTabIcon)
    setThirdTabLabel(findComponent?.footer.thirdTabLabel)
    setThirdTabIcon(findComponent?.footer.thirdTabIcon)
    setFourthTabLabel(findComponent?.footer.fourthTabLabel)
    setFourthTabIcon(findComponent?.footer.fourthTabIcon)
    setFifthTabLabel(findComponent?.footer.fifthTabLabel)
    setFifthTabIcon(findComponent?.footer.fifthTabIcon)
  }, [components, modifyId])

  if (!component || !footer) {
    return null
  }

  const handleChangeInput = (value: string, type: HeaderAndFooterKey) => {
    if (type === 'backLabel') setBackLabel(value)
    if (type === 'backIcon') setBackIcon(value)
    if (type === 'nextLabel') setNextLabel(value)
    if (type === 'nextIcon') setNextIcon(value)
    if (type === 'firstTabLabel') setFirstTabLabel(value)
    if (type === 'firstTabIcon') setFirstTabIcon(value)
    if (type === 'secondTabLabel') setSecondTabLabel(value)
    if (type === 'secondTabIcon') setSecondTabIcon(value)
    if (type === 'thirdTabLabel') setThirdTabLabel(value)
    if (type === 'thirdTabIcon') setThirdTabIcon(value)
    if (type === 'fourthTabLabel') setFourthTabLabel(value)
    if (type === 'fourthTabIcon') setFourthTabIcon(value)
    if (type === 'fifthTabLabel') setFifthTabLabel(value)
    if (type === 'fifthTabIcon') setFifthTabIcon(value)
    dispatch(changeValueHeaderFooter(value, 'footer', type, modifyId))
  }

  const handleTabSwitches = (thisType: HeaderAndFooterKey) => {
    const types = [
      'firstTabActive',
      'secondTabActive',
      'thirdTabActive',
      'fourthTabActive',
      'fifthTabActive'
    ]
    types.forEach((type) => {
      dispatch(
        changeValueHeaderFooter(
          type === thisType,
          'footer',
          type as HeaderAndFooterKey,
          modifyId
        )
      )
    })
  }

  const hideComponent = (value: boolean) => {
    const newValue = !value
    dispatch(changeValueHeaderFooter(newValue, 'footer', 'visible', modifyId))
  }

  return (
    <>
      <div className={classes.content}>
        <div className={classes.selectContainer}>
          <Input
            label="Type"
            options={[
              { value: 'SpeedDial', label: 'SpeedDial' },
              { value: 'Navigation', label: 'Navigation' },
              { value: 'Tabs', label: 'Tabs' }
            ]}
            value={footer.type}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(
                changeValueHeaderFooter(
                  e.target.value,
                  'footer',
                  'type',
                  modifyId
                )
              )
            }
          />
        </div>
        {footer.type === 'SpeedDial' && (
          <>
            <div className={classes.separator}>
              <StepList
                title="Add speed dial action"
                actionIcon="Add"
                onClickComponent={() =>
                  dispatch(
                    addHeaderFooterTableValue(
                      speedDialItem,
                      'footer',
                      'speedDialActions',
                      modifyId
                    )
                  )
                }
              />
            </div>
            {footer.speedDialActions?.map((item) => (
              <div key={item.id} className={classes.inputGroup}>
                <div className={classes.footerPlayInput}>
                  <Input
                    label="Label"
                    value={item.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        editHeaderFooterTableValue(
                          e.target.value,
                          'label',
                          'footer',
                          'speedDialActions',
                          modifyId,
                          item.id
                        )
                      )
                    }
                  />
                </div>
                <div className={classes.footerPlayInput}>
                  <Input
                    label="Icon"
                    value={item.icon}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        editHeaderFooterTableValue(
                          e.target.value,
                          'icon',
                          'footer',
                          'speedDialActions',
                          modifyId,
                          item.id
                        )
                      )
                    }
                  />
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      removeHeaderFooterTableValue(
                        'footer',
                        'speedDialActions',
                        modifyId,
                        item.id
                      )
                    )
                  }
                  style={{
                    cursor: 'pointer',
                    color: '#E53935',
                    alignSelf: 'center'
                  }}
                >
                  <RemoveCircleOutline />
                </div>
              </div>
            ))}
          </>
        )}
        {footer.type === 'Navigation' && (
          <div>
            <div className={classes.inputGroup}>
              <div className={classes.footerPlayInput}>
                <Input
                  label="Back text"
                  value={backLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'backLabel')
                  }
                />
              </div>
              <div className={classes.footerPlayInput}>
                <Input
                  label="Back icon"
                  value={backIcon}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'backIcon')
                  }
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.footerPlayInput}>
                <Input
                  label="Next text"
                  value={nextLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'nextLabel')
                  }
                />
              </div>
              <div className={classes.footerPlayInput}>
                <Input
                  label="Next icon"
                  value={nextIcon}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'nextIcon')
                  }
                />
              </div>
            </div>
          </div>
        )}
        {footer.type === 'Tabs' && (
          <div>
            <div className={classes.selectContainer}>
              <Input
                label="Amount"
                options={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' }
                ]}
                value={footer.tabAmount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, 'tabAmount')
                }
              />
            </div>
            <div className={classes.separator}>
              <div className={classes.footerViewInput}>
                <Input
                  label="Icon"
                  prefixIcon="Image"
                  value={firstTabIcon}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'firstTabIcon')
                  }
                />
              </div>
              <div className={classes.footerViewInput}>
                <Input
                  label="Text"
                  value={firstTabLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeInput(e.target.value, 'firstTabLabel')
                  }
                />
              </div>
              <div className={classes.footerTabSelector}>
                <Selector
                  title="Is active"
                  checked={footer.firstTabActive}
                  onChange={() => handleTabSwitches('firstTabActive')}
                />
              </div>
              {Number(footer.tabAmount) >= 2 && (
                <>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Icon"
                      prefixIcon="Image"
                      value={secondTabIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'secondTabIcon')
                      }
                    />
                  </div>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Text"
                      value={secondTabLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'secondTabLabel')
                      }
                    />
                  </div>
                  <div className={classes.footerTabSelector}>
                    <Selector
                      title="Is active"
                      checked={footer.secondTabActive}
                      onChange={() => handleTabSwitches('secondTabActive')}
                    />
                  </div>
                </>
              )}
              {Number(footer.tabAmount) >= 3 && (
                <>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Icon"
                      prefixIcon="Image"
                      value={thirdTabIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'thirdTabIcon')
                      }
                    />
                  </div>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Text"
                      value={thirdTabLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'thirdTabLabel')
                      }
                    />
                  </div>
                  <div className={classes.footerTabSelector}>
                    <Selector
                      title="Is active"
                      checked={footer.thirdTabActive}
                      onChange={() => handleTabSwitches('thirdTabActive')}
                    />
                  </div>
                </>
              )}
              {Number(footer.tabAmount) >= 4 && (
                <>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Icon"
                      prefixIcon="Image"
                      value={fourthTabIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'fourthTabIcon')
                      }
                    />
                  </div>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Text"
                      value={fourthTabLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'fourthTabLabel')
                      }
                    />
                  </div>
                  <div className={classes.footerTabSelector}>
                    <Selector
                      title="Is active"
                      checked={footer.fourthTabActive}
                      onChange={() => handleTabSwitches('fourthTabActive')}
                    />
                  </div>
                </>
              )}
              {Number(footer.tabAmount) >= 5 && (
                <>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Icon"
                      prefixIcon="Image"
                      value={fifthTabIcon}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'fifthTabIcon')
                      }
                    />
                  </div>
                  <div className={classes.footerViewInput}>
                    <Input
                      label="Text"
                      value={fifthTabLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeInput(e.target.value, 'fifthTabLabel')
                      }
                    />
                  </div>
                  <div className={classes.footerTabSelector}>
                    <Selector
                      title="Is active"
                      checked={footer.fifthTabActive}
                      onChange={() => handleTabSwitches('fifthTabActive')}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon={footer.visible ? 'Visibility' : 'VisibilityOff'}
            title={footer.visible ? 'Hide footer' : 'Show footer'}
            onClick={() => hideComponent(footer.visible)}
          />
        </div>
      </Collapse>
    </>
  )
}

export default FooterSettings
