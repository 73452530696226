import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import CollapseList from 'components/common/CollapseList'
import Input from 'components/common/Input'
import Selector from 'components/common/Selector'
import StepList from 'components/common/StepList'
import { AppState, Content, ContentKey } from 'types/types'
import { componentToModify } from 'store/actions/componentModify'
import {
  changeValueContent,
  removeContent
} from 'store/actions/componentSettings'

const useStyles = makeStyles((theme) => ({
  content: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  selectorStyles: {
    padding: '8px 16px'
  },
  inputStyles: {
    padding: '16px 16px 8px 16px'
  }
}))

const FeedSettings: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [componentId, setComponentId] = useState<string>('')
  const [otherExpand, setOtherExpand] = useState<boolean>(false)
  const [content, setContent] = useState<Content | undefined>(undefined)
  const { modifyId } = useSelector((state: AppState) => state.componentModify)
  const { components } = useSelector(
    (state: AppState) => state.componentSettings
  )
  const [feedTitleText, setFeedTitleText] = useState<string | undefined>('')
  const [feedSubtitle, setFeedSubtitle] = useState<string | undefined>('')
  const [feedText, setFeedText] = useState<string | undefined>('')

  useEffect(() => {
    components.forEach((component) => {
      component.contents.forEach((content) => {
        if (content.id === modifyId) {
          setComponentId(component.id)
          setContent(content)
          setFeedTitleText(content.feedTitleText)
          setFeedSubtitle(content.feedSubtitle)
          setFeedText(content.feedText)
        }
      })
    })
  }, [components, modifyId])

  if (!content) {
    return null
  }

  const handleChangeSelector = (
    value: boolean | undefined,
    stateKey: ContentKey
  ) => {
    dispatch(changeValueContent(!value, stateKey, modifyId))
  }

  const handleChangeInput = (value: string, type: ContentKey) => {
    if (type === 'feedTitleText') setFeedTitleText(value)
    if (type === 'feedSubtitle') setFeedSubtitle(value)
    if (type === 'feedText') setFeedText(value)
    dispatch(changeValueContent(value, type, modifyId))
  }

  const deleteComponent = () => {
    dispatch(removeContent(modifyId))
    dispatch(componentToModify(true, false, componentId, 'All screens'))
  }

  return (
    <>
      <div className={classes.content}>
        <div className={classes.selectorStyles}>
          <Selector
            title="Feed title"
            checked={content.feedTitle}
            onChange={() =>
              handleChangeSelector(content.feedTitle, 'feedTitle')
            }
          />
        </div>
        <Collapse in={content.feedTitle} timeout="auto" unmountOnExit>
          <div className={classes.inputStyles}>
            <Input
              label="Feed title"
              value={feedTitleText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInput(e.target.value, 'feedTitleText')
              }
            />
          </div>
        </Collapse>
        <div className={classes.selectorStyles}>
          <Selector
            title="User feed"
            checked={content.feedUser}
            onChange={() => handleChangeSelector(content.feedUser, 'feedUser')}
          />
        </div>
        <div className={classes.selectorStyles}>
          <Selector
            title="Feed date"
            checked={content.feedDate}
            onChange={() => handleChangeSelector(content.feedDate, 'feedDate')}
          />
        </div>
        <div className={classes.inputStyles}>
          <Input
            label="Additional"
            value={feedSubtitle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'feedSubtitle')
            }
          />
        </div>
        <div className={classes.inputStyles}>
          <Input
            label="Text"
            rows={5}
            value={feedText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e.target.value, 'feedText')
            }
          />
        </div>
      </div>
      <CollapseList
        expanded={otherExpand}
        setExpanded={setOtherExpand}
        primary="Other"
      />
      <Collapse in={otherExpand} timeout="auto" unmountOnExit>
        <div>
          <StepList
            icon="Delete"
            iconColor="#E53935"
            title="Delete component"
            titleColor="#E53935"
            onClick={deleteComponent}
          />
        </div>
      </Collapse>
    </>
  )
}

export default FeedSettings
