import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Icons from 'components/common/Icons'
import { changeSelected, handleShare, logout } from 'store/actions/sidebar'
import { startPreview } from 'store/actions/preview'
import { componentToModify } from 'store/actions/componentModify'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  // @ts-ignore
  iconColor: ({ color }) => ({
    color
  })
}))

const SidebarIcons: React.FC<{
  icon: string
  color: string
  name: string
  selected: boolean
  disabled: boolean
  position: 'top' | 'bottom'
}> = ({ icon, color, name, selected, disabled, position }) => {
  const dispatch = useDispatch()
  const classes = useStyles({ color })
  const history = useHistory()

  let iconType: string | null = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = null
  }

  const handleClick = (name: string, position: 'top' | 'bottom') => {
    dispatch(componentToModify(false, false, '', ''))
    if (name === 'Preview') {
      dispatch(startPreview())
    }
    if (name === 'Share') {
      dispatch(handleShare())
    }
    if (name === 'Logout') {
      dispatch(logout())
      history.push('/login')
    }

    dispatch(
      changeSelected(
        name,
        position === 'top' ? 'sidebarTop' : 'sidebarBottom',
        false
      )
    )
  }

  return (
    <div>
      <IconButton
        onClick={() => handleClick(name, position)}
        color={selected ? 'primary' : 'default'}
        classes={{ colorPrimary: classes.iconColor }}
        disabled={disabled}
      >
        {iconType !== null && React.createElement(Icons[iconType])}
      </IconButton>
    </div>
  )
}

export default SidebarIcons
