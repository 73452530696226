/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'
import Share from 'components/Share/Share'
import Navbar from 'components/Navigation/Navbar'
import Sidebar from 'components/Navigation/Sidebar'
import Canvas from 'components/Canvas/Canvas'
import Backdrop from '@material-ui/core/Backdrop'
import SpinnerLogoWhite from 'assets/spinner-logo-white-60.svg'
import { loadSettings } from 'store/actions/componentSettings'
import { AppState } from 'types/types'
import LoginDialog from './auth/LoginDialog'

// this function fixes older services so that studio won't crash when they are loaded
// also sets proper properties if they are missing
const updateQuery = (query: any) => {
  const updatedQuery = JSON.parse(JSON.stringify(query))
  const components = JSON.parse(JSON.stringify(updatedQuery.components))
  const fixed: any = []
  components.forEach((component: any, index: number) => {
    if (component.type === 'Custom') {
      component.type = 'Play'
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'order')) {
      component.order = String(index + 1)
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'positionX')) {
      component.positionX = 458 * index
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'positionY')) {
      component.positionY = 0
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'children')) {
      component.children = []
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'parentId')) {
      component.parentId = ''
    }
    if (!Object.prototype.hasOwnProperty.call(component, 'pathParentId')) {
      component.pathParentId = ''
    }
    if (Object.prototype.hasOwnProperty.call(component, 'svg')) {
      delete component.svg
    }
    fixed.push(component)
  })
  updatedQuery.components = fixed
  return updatedQuery
}

const getQuery = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        value
        key
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  spinner: {
    height: '300px'
  }
}))

const Main: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // @ts-ignore
  const { id } = useParams()
  const { share } = useSelector((state: AppState) => state.sidebar)
  const [getEngagement, { loading }] = useLazyQuery(getQuery, {
    onCompleted(queryData) {
      if (queryData.serviceEngagement !== null) {
        const updatedQuery = updateQuery(
          queryData.serviceEngagement.metadata.filter(
            (item: { key: string }) => item.key === 'concept'
          )[0].value
        )
        dispatch(loadSettings(updatedQuery, id))
      }
    }
  })

  const { sessionExpired } = useSelector((state: AppState) => state.other)

  useEffect(() => {
    if (id) {
      getEngagement({ variables: { id } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div>
      {share && <Share />}
      <Navbar />
      <Sidebar />
      <Canvas />
      <LoginDialog open={sessionExpired} />
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <object className={classes.spinner} data={SpinnerLogoWhite} />
        </Backdrop>
      )}
    </div>
  )
}

export default Main
