/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import PublicClient from 'api/public'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { Button } from 'uniqore-components'
import SpinnerLogoDark from 'assets/spinner-logo-dark.svg'
import TextField from '@material-ui/core/TextField'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { loginQuery } from '../Login'
import { sessionExpired } from 'store/actions/other'

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '16px 16px 0px 24px',
    color: theme.palette.text.primary
  },
  dialogActions: {
    padding: '12px 24px'
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  inputWrapper: {
    marginTop: 32
  }
}))

const LoginDialog: React.FC<{
  open: boolean
}> = ({ open }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleClick = () => {
    setShowPassword((prev) => !prev)
  }

  const [login, { loading: mutationLoading }] = useMutation(loginQuery, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.loginOrgUser.error) {
        setError(mutationResult.loginOrgUser.error)
      } else if (mutationResult.loginOrgUser.token) {
        localStorage.setItem('token', mutationResult.loginOrgUser.token)
        localStorage.setItem('userId', mutationResult.loginOrgUser.orgUserId)
        dispatch(sessionExpired(false))
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    login({
      variables: {
        email: email,
        password: password,
        auth: process.env.REACT_APP_ORG_AUTH_ID
      }
    })
    event.preventDefault()
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <div>
        <Typography variant="h5" className={classes.title}>
          Your session has expired. Please login again.
        </Typography>
        <DialogContent>
          <div className={classes.inputWrapper}>
            <TextField
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              placeholder="Email"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              placeholder="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!error}
              helperText={error}
            />
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            {mutationLoading && (
              <object
                className={classes.spinnerOnButton}
                data={SpinnerLogoDark}
              />
            )}
            <Button
              label="LOGIN"
              variant="contained"
              color="secondary"
              onClick={submit}
            />
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default LoginDialog
