import {
  SidebarActionTypes,
  ChangeSelectedStateKey,
  AppActions
} from 'types/types'
import { Dispatch } from 'redux'

export const CHANGE_SELECTED = 'CHANGE_SELECTED'
export const HANDLE_SHARE = 'HANDLE_SHARE'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
export const LOGOUT = 'LOGOUT'

export const changeSelected = (
  name: string,
  stateKey: ChangeSelectedStateKey,
  clickedComponent: boolean
) => (dispatch: Dispatch<AppActions>): SidebarActionTypes => {
  return dispatch({
    type: CHANGE_SELECTED,
    payload: { name, stateKey, clickedComponent }
  })
}

export const handleShare = () => (
  dispatch: Dispatch<AppActions>
): SidebarActionTypes => {
  return dispatch({
    type: HANDLE_SHARE
  })
}

export const closeSideBar = () => (
  dispatch: Dispatch<AppActions>
): SidebarActionTypes => {
  return dispatch({
    type: CLOSE_SIDEBAR
  })
}

export const logout = () => (
  dispatch: Dispatch<AppActions>
): SidebarActionTypes => {
  return dispatch({
    type: LOGOUT
  })
}
