/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'
import { ReactComponent as Rocket } from 'assets/rocket.svg'
import { useParams } from 'react-router-dom'
import ShareClient from 'api/share'
import Typography from '@material-ui/core/Typography'
import Input from 'components/common/Input'
import Preview from 'components/Canvas/Preview'
import { Button, Whitelabel } from 'uniqore-components'
import { onlyPreview } from 'store/actions/preview'
import { changeOther } from 'store/actions/other'
import { loadSettings } from 'store/actions/componentSettings'
import SpinnerLogoWhite from 'assets/spinner-logo-white-60.svg'
import { AppState } from 'types/types'

const getEngagementId = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        value
        key
      }
    }
  }
`

const useStyles = makeStyles(() => ({
  content: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#002C38',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    bottom: '116px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  button: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '100px'
  }
}))

const SharePasswordPage: React.FC = () => {
  // @ts-ignore
  const { code, id } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { password } = useSelector((state: AppState) => state.other)
  const [error, setError] = useState<string>('')
  const [typePassword, setTypePassword] = useState<boolean>(true)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [getEngagement, { loading }] = useLazyQuery(getEngagementId, {
    context: {
      headers: {
        Authorization:
          password !== ''
            ? `ShareLink ${code} ${password}`
            : `ShareLink ${code}`
      }
    },
    client: ShareClient,
    onCompleted(queryData) {
      if (queryData.serviceEngagement !== null) {
        dispatch(
          loadSettings(
            queryData.serviceEngagement.metadata.filter(
              (item: { key: string }) => item.key === 'concept'
            )[0].value,
            id
          )
        )
        setShowPreview(true)
      }
    },
    onError(queryError) {
      if (queryError) {
        setError('Incorrect password')
      }
    }
  })

  // checks if link has password
  useEffect(() => {
    if (code.charCodeAt(code.length - 1) % 2 === 0) {
      getEngagement({ variables: { id } }) // if password not set, go straight to preview
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // go to preview
  useEffect(() => {
    if (showPreview) dispatch(onlyPreview())
  }, [showPreview, dispatch])

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (password === '') {
      setError("Password can't be empty")
      return
    }
    getEngagement({ variables: { id } })
  }

  return (
    <>
      {!showPreview ? (
        <>
          <form onSubmit={submit} className={classes.content}>
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <div style={{ paddingBottom: '8px' }}>
                <Rocket />
              </div>
              <Typography
                style={{ color: '#FAFAFA', paddingBottom: '16px' }}
                variant="h6"
              >
                To infinity... and beyond!
              </Typography>
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: '5px'
                }}
              >
                <Input
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch(changeOther(e.target.value, 'password'))
                  }
                  placeholder="Enter password"
                  error={error !== ''}
                  helperText={error}
                  type={typePassword ? 'password' : 'text'}
                  suffixIcon={typePassword ? 'VisibilityOff' : 'Visibility'}
                  suffixOnClick={() => setTypePassword(!typePassword)}
                />
              </div>
              <div>
                {loading && (
                  <object
                    className={classes.spinnerOnButton}
                    data={SpinnerLogoWhite}
                  />
                )}
                <Button
                  className={classes.button}
                  label="LAUNCH"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  type="submit"
                />
              </div>
            </div>
          </form>
          <Whitelabel
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          />
        </>
      ) : (
        <Preview />
      )}
    </>
  )
}

export default SharePasswordPage
