import produce from 'immer'
import { SidebarState, SidebarActionTypes } from 'types/types'
import {
  CHANGE_SELECTED,
  HANDLE_SHARE,
  CLOSE_SIDEBAR,
  LOGOUT
} from 'store/actions/sidebar'

const initialState: SidebarState = {
  share: false,
  sidebarTop: [
    {
      icon: 'PhoneIphone',
      color: '#1E88E5',
      name: 'Screens',
      selected: true,
      disabled: false,
      position: 'top'
    },
    {
      icon: 'ColorLens',
      name: 'Branding',
      color: '#E53935',
      selected: false,
      disabled: false,
      position: 'top'
    },
    {
      icon: 'Settings',
      color: '#43A047',
      name: 'Settings',
      selected: false,
      disabled: false,
      position: 'top'
    }
  ],
  sidebarBottom: [
    // {
    //   icon: 'GetApp',
    //   color: '',
    //   name: 'GetApp',
    //   selected: false,
    //   disabled: true,
    //   position: 'bottom'
    // },
    // {
    //   icon: 'Share',
    //   color: '',
    //   name: 'Share',
    //   selected: false,
    //   disabled: false,
    //   position: 'bottom'
    // },
    // {
    //   icon: 'Visibility',
    //   color: '',
    //   name: 'Preview',
    //   selected: false,
    //   disabled: false,
    //   position: 'bottom'
    // }
    {
      icon: 'ExitToApp',
      color: '',
      name: 'Logout',
      selected: false,
      disabled: false,
      position: 'bottom'
    }
  ]
}

// needs refactoring, do this when more icons actions needed
const selected = (state = initialState, action: SidebarActionTypes) => {
  switch (action.type) {
    case CHANGE_SELECTED: {
      const { payload } = action
      return produce(state, (draft) => {
        if (payload.name === 'Preview') {
          draft.sidebarTop.map((v) => {
            v.selected = false
            return v
          })
          draft.sidebarBottom.map((v) => {
            v.selected = false
            return v
          })
        } else {
          draft[payload.stateKey].map((v) => {
            if (payload.clickedComponent) {
              if (v.name === payload.name) {
                v.selected = true
              } else {
                v.selected = false
              }
            } else {
              if (v.name === payload.name && v.selected !== true) {
                v.selected = true
              } else {
                v.selected = false
              }
            }
            return v
          })
        }
      })
    }

    case HANDLE_SHARE: {
      return produce(state, (draft) => {
        draft.share = !state.share
      })
    }

    case CLOSE_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarTop.map((v) => {
          v.selected = false
          return v
        })
        draft.sidebarBottom.map((v) => {
          v.selected = false
          return v
        })
      })
    }

    case LOGOUT: {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      return state
    }

    default:
      return state
  }
}

export default selected
